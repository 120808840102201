
import { styled } from '@mui/material/styles';

const ContentStyle = styled('div')(({ theme }) => ({
    paddingTop: '24px',
    paddingLeft: '24px',
    paddingRight: '24px',
}));




export default function NoForm(props) {

    return (
        <ContentStyle>
            <p>Formulario no  encontrado</p>
        </ContentStyle>
    );
}
