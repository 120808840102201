import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "../Loadable";

const routeFinancial = (props) => [
    {
        element: <Navigate to="/financiero/inicio" replace />,
    },
    { path: "inicio", element: <Home /> },
    { path: "mis-comercios", element: <MyBusinessesFinancial /> },
    { path: "mis-comercios/:business", element: <AllAffiliationByBusiness /> },
    { path: "comercios-por-revisar", element: <BusinessToReview /> },
    { path: "usuarios", element: <ListUser /> },
    { path: "perfil", element: <Profile /> },
    { path: "notificaciones/", element: <Notification /> },
    {
        path: "notificaciones/:affiliation/:id",
        element: <Notification key={Math.random()} />,
    },
];

const Log = Loadable(lazy(() => import("../../pages/advisor/Log")));
const Home = Loadable(lazy(() => import("../../pages/financial/Home")));
const MyBusinessesFinancial = Loadable(
    lazy(() => import("../../pages/financial/MyBusinessesFinancial"))
);
const BusinessToReview = Loadable(
    lazy(() => import("../../pages/financial/BusinessToReview"))
);
const ListUser = Loadable(lazy(() => import("../../pages/financial/ListUser")));
const AllAffiliationByBusiness = Loadable(
    lazy(() => import("../../pages/financial/AllAffiliationByBusiness"))
);
const Profile = Loadable(lazy(() => import("../../pages/advisor/Profile")));

const Notification = Loadable(
    lazy(() => import("../../pages/advisor/Notifications"))
);


export { Loadable };

export default routeFinancial;
