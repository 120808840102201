import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Box, CircularProgress } from '@mui/material';
import { CheckIfThereisRequestAndNotification } from '../../../actions/NotificationAction';
import useAuth from '../../../hooks/useAuth';

export const ActionSeeAllNotificationAdvisorAndSupervisor = ({ affiliationId, thereIsRequest }) => {

    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({});

    const userRol = user?.rol?.toLowerCase();
    let userRolLink = "";
    if (userRol === "advisor" || userRol === "supervisor") {
        userRolLink = "nuvei";
    }
    if (userRol === "moneyguard" || userRol === "moneyguardadministrator"  ) {
        userRolLink = "proteccion-dinero";
    }
    if (userRol === "technicianadministrator" || userRol === "technician") {
        userRolLink = "tecnico";
    }
    if (userRol === "financial") {
        userRolLink = "financiero";
    }

 

    useEffect(() => {

        setLoading(true);

        const objPagerRequest = {
            userId: user?.id,
        }


        CheckIfThereisRequestAndNotification(objPagerRequest).then(response => {
            setLoading(false);
            if (response?.status === 200) {
                setState(response?.data);
            }
        });


    }, [])





    return (

        <>

            {loading

                ?

                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', paddingBottom: '20px' }}>
                    <CircularProgress />
                </Box>


                :


                <>
                    {state?.notification &&
                        <Button fullWidth disableRipple component={RouterLink} to={`/${userRolLink}/notificaciones`}>
                            Ver todas las  notificaciones
                        </Button>
                    }

                    {state?.request &&
                        <Button fullWidth disableRipple component={RouterLink} to={`/nuvei/solicitudes`}>
                            Ver todas las solicitudes
                        </Button>
                    }


                </>



            }




        </>

    )
}

