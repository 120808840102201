//import { useRef, useState } from 'react';
//import homeFill from '@iconify/icons-eva/home-fill';
//import personFill from '@iconify/icons-eva/person-fill';
//import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import {  useNavigate } from 'react-router-dom';
// material
//import { alpha } from '@mui/material/styles';
import {  Button, Box } from '@mui/material';
// components
//import { MIconButton } from '../../../components/@material-extend';


// ----------------------------------------------------------------------
import useAuth from '../../../hooks/useAuth';
import { useSnackbar } from 'notistack';

import { setSession } from '../../../utils/jwt';

//const MENU_OPTIONS = [
//    { label: 'Home', icon: homeFill, linkTo: '/' },
//    { label: 'Profile', icon: personFill, linkTo: '#' },
//    { label: 'Settings', icon: settings2Fill, linkTo: '#' }
//];

// ----------------------------------------------------------------------

export default function AccountLogOut() {
    const { dispatch } = useAuth()
    const navigate = useNavigate();
  /*  const anchorRef = useRef(null);*/
   /* const [open, setOpen] = useState(false);*/
    //const isMountedRef = useIsMountedRef();
    const { enqueueSnackbar } = useSnackbar();

    //const handleOpen = () => {
    //    setOpen(true);
    //};
    //const handleClose = () => {
    //    setOpen(false);
    //};



    const handleLogout = async () => {
        try {

            setSession(null);
            dispatch({ type: 'LOGOUT' });

            navigate('/auth/login');
           
         
            //if (isMountedRef.current) {
            //    console.log(isMountedRef.current,"inside");
            //   /* handleClose();*/
            //}
        } catch (error) {
            console.error(error);
            enqueueSnackbar('Unable to logout', { variant: 'error' });
        }
    };

    return (
        <>
            <Box >
                <Button fullWidth variant="text" onClick={handleLogout} sx={{ color:'#26B77E'}}>
                    Salir
                </Button>
            </Box>
        </>
    );
}
