import { Fragment, useEffect } from "react";
import {  useNavigate,  useParams } from "react-router-dom";
import {  Typography, Button } from "@mui/material";
import useAuth from "../../../../hooks/useAuth";
//import useIsMountedRef from "../../../hooks/useIsMountedRef";
import { styled } from '@mui/material/styles';
import exito1 from '../../../../assets/images/icons/exito1.png';
import { StepsCollect } from '../../../../components/paymentez/collect/principal';
import { PATH_PAYMENTEZ } from "../../../../routes/paths";

const ContentStyle = styled('div')(({ theme }) => ({
    marginTop: "56px",
    textAlign: "center",
    "& img": {
        width: '291px',
        margin: "auto"
    },
    "&  .title": {
        marginTop: '41px'
    },
    "&  .btnContinue": {
        marginTop: '35px'
    }


}));


//const css = `
//    header {
//         width : 100% !important
//    }
//.sliderPaymentez{
//display:none;
//}
//`





//export default function EndFirstStep(props) {
export const EndFirstStep = () => {

    const navigate = useNavigate();
    
    let params = useParams();

    const { dispatch, collect } = useAuth();


    //const isMountedRef = useIsMountedRef();
   
  





    const handle = async () => {

        dispatch({
            type: 'VIEWENDFIRSTSTEPCOLLECT',
            payload: {
                show: false
            }
        });


        navigate(PATH_PAYMENTEZ.app.pageAllAffiliations);
       


    }


    useEffect(() => {
       
    
       
       

        if (collect?.showViewEndFistStep) {
            //setTimeout(() => {

            //    handle();


            //}, 4000);
          
        } else {

            navigate(PATH_PAYMENTEZ.app.pageAllAffiliations);
        }

       


    }, []);


   









    return (

        <Fragment>
            <div style={{ marginTop: "50px" }}> <StepsCollect /> </div>
            <ContentStyle>

                {/*    <style>{css}</style>*/}

                <img src={exito1} alt="img" />
                <Typography variant="h4" mt={2} className="title">
                    ¡Felicitaciones! Has terminado la primera etapa con éxito
                </Typography>

                <Typography variant="body1" mt={2}>
                    Tu asesor comercial revisará y aprobará tu documentación inicial. Si hay algo que corregir te lo indicaremos.
                    <br />
                    Luego de la aprobación inicial, toda tu documentación será revisada y analizada por nuestro departamento de Protección del dinero.
                    <br />
                    Te notificaremos cuando sea aprobado tu comercio para que sigas con la afiliación.
                </Typography>

            <Button variant="contained" className="btnContinue" size="large" onClick={handle}>Inicio</Button>

            </ContentStyle>
        </Fragment>

    );
}
