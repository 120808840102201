import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "../Loadable";
import routeBusinessGateway from "./routeBusinessGateway";
import routeBusinessCollect from "./routeBusinessCollect";
import StepsGuard from "../../guards/StepsGuard";
import StepsCollectGuard from "../../guards/StepsCollectGuard";

const routeBusiness = (props) => [
  { element: <Navigate to="/app/mis-afiliaciones" replace /> },
  { path: "mis-afiliaciones", element: <AllAffiliations /> },
  {
    path: "gateway",
    element: <StepsGuard />,
    children: routeBusinessGateway(props),
  },
  {
    path: "agregador",
    element: <StepsCollectGuard />,
    children: routeBusinessCollect(props),
  },
  {
    path: "documentacion-adicional-bancos",
    element: <AdditionalDocumentation />,
  },
  { path: "preguntas-frecuentes", element: <FrequentQuestions /> },
];

const AllAffiliations = Loadable(
  lazy(() => import("../../pages/AllAffiliations"))
);
const AdditionalDocumentation = Loadable(
  lazy(() => import("../../pages/AdditionalDocumentation"))
);
const FrequentQuestions = Loadable(
  lazy(() => import("../../pages/FrequentQuestions"))
);

export { Loadable };

export default routeBusiness;
