import axios from 'axios';
import Global from '../Global';

axios.defaults.baseURL = Global.base_API_URL;

axios.interceptors.request.use((config) => {
    const token = window.localStorage.getItem('accessToken');
    if (token) {
        config.headers.Authorization = "Bearer " + token;
        return config;
    } else {
        return config;
    }
}, error => {
    return Promise.reject(error);
});


const genericRequest = {
    get: (url) => axios.get(url),
    //post: (url, body, isOctetStream = false) => {
    //    const headers = isOctetStream ? { 'Content-Type': 'multipart/form-data' } : {};
    //    console.log("headers", headers);
    //    return axios.post(url, body, { headers });
    //},
    post: (url, body) => axios.post(url, body),
    put: (url, body) => axios.put(url, body),
    delete: (url) => axios.delete(url),
    patch: (url) => axios.patch(url)
};

export default genericRequest;