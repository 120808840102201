import { Fragment, useState, useEffect } from "react";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import {
    Button, Dialog, DialogContent, DialogContentText, DialogActions, DialogTitle,
    TextField, FormControlLabel, FormControl, FormLabel, RadioGroup, Radio, FormGroup, Checkbox,
     Grid
} from "@mui/material";

import { NotificationNewAffiliationSave} from '../../../actions/NotificationAction';

import { styled } from '@mui/material/styles';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from 'notistack';
import { MIconButton } from "../../@material-extend";
import useAuth from "../../../hooks/useAuth";
import { Icon } from '@iconify/react';
import closeFill from "@iconify/icons-eva/close-fill";
import establecimiento from '../../../assets/images/icons/establecimiento.png';

const ModalStyle = styled('div')(({ theme }) => ({



}));


const TypePaypmentList = [
    {
        label: "Pasarela de pagos",
        value: "1"
    },
    {
        label: "Link to Pay",
        value: "2"
    }
   
];

export const defaultValues = {
    TradeName: '',
    TypeAffiliation: "1",
    TypePayment: '',
    Comments: '',
};

export const FormSchema = Yup.object().shape({
    TradeName: Yup.string().test('', '', val => val?.trim() != "").required(),
    TypeAffiliation: Yup.string().test('', '', val => val?.trim() != "").required(),
    TypePayment: Yup.array().min(1, "").required(''),
    Comments: Yup.string().test('', '', val => val?.trim() != "").required(),
});


export default function ModaleGetNewAffiliation(props) {
    const params = useParams();
    const [alert, setAlert] = useState(true);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { businessData, dispatch } = useAuth();

    const [loading, setLoading] = useState(
        {
            saving: false,
        }
    );


    const {
      
        reset,
        control,
       
        handleSubmit,
      
        formState: {  isValid }
    } = useForm({
        mode: 'onChange',

        resolver: yupResolver(FormSchema),
        defaultValues,
        shouldFocusError: true

    });

    const onSubmit = async (data) => {

        setLoading(prev => ({
            ...prev,
            saving: true
        }));


        var usePaymentGateway = data.TypePayment.find(e => e === "1") !== undefined ? true : false;
        var useLinkToPay = data.TypePayment.find(e => e === "2") !== undefined ? true : false;
      

        const objForm = {

            UserId: businessData?.userId,
            AdvisorId: businessData?.assesorId,
            //AffiliationDataId: params.affiliation,
            TypeAffiliation: parseInt(data.TypeAffiliation),
            UsePaymentGateway: usePaymentGateway,
            TradeName: data?.TradeName,
            UseLinkToPay: useLinkToPay,
            Comment: data.Comments

        }
      
       

        NotificationNewAffiliationSave(objForm).then(response => {


            if (response?.status === 200) {

                enqueueSnackbar('Envio exitoso', {
                    variant: 'success',
                    action: (key) => (
                        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                            <Icon icon={closeFill} />
                        </MIconButton>
                    )
                });
                setLoading(prev => ({
                    ...prev,
                    saving: false
                }));

                props?.setActive(false);
                props?.handleCloseCustom(props?.type);


            } else {

                setLoading(prev => ({
                    ...prev,
                    saving: false
                }));

                enqueueSnackbar('Error, intente de nuevo', {
                    variant: 'error',
                    action: (key) => (
                        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                            <Icon icon={closeFill} />
                        </MIconButton>
                    )
                });

            }
        });


    }




    return (


        <Fragment>
            <ModalStyle>
                <Dialog open={alert} onClose={props?.clickOpenCloseModalSave} maxWidth="sm" >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div style={{ paddingTop: '20px', paddingLeft: '20px' }}>
                            <img src={establecimiento} alt="footerimg" height="50" width="50" />
                        </div>
                        <DialogTitle sx={{ paddingTop: "10px" }}>¿Necesitas una nueva afiliación?</DialogTitle>
                        <DialogContent sx={{ marginTop: "4px" }}>
                            <DialogContentText id="alert-dialog-description" sx={{ color: "#212B36" }}>
                                Envíanos la solicitud para contactarnos contigo y gestionarla lo más pronto posible.
                            </DialogContentText>

                            <Grid container>

                                <Grid xs={12} md={12} mt={2}>
                                    <Controller
                                        name="TradeName"
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <TextField {...field} label="Nombre comercial" error={Boolean(error)} helperText={error?.message} fullWidth  />
                                        )}
                                    />

                                </Grid>

                                <Grid xs={12} md={12} mt={2}>
                                    <Controller
                                        name="TypeAffiliation"
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <FormControl component="fieldset" >
                                                <FormLabel component="legend"><span style={{ color: 'black' }}>Tipo de afiliación:</span></FormLabel>
                                                <RadioGroup aria-label="TypeAffiliation" {...field} row>
                                                    <FormControlLabel value="1" control={<Radio />} label="Gateway" />
                                                    <FormControlLabel value="2" control={<Radio />} label="Agregador" />
                                                </RadioGroup>
                                            </FormControl>
                                        )}
                                    />

                                </Grid>
                                <Grid xs={12} md={12} mt={1}>
                                    <FormControl component="fieldset" variant="standard">
                                        <FormLabel component="legend"><span style={{ color: 'black' }}>Tipo de pago:</span></FormLabel>
                                        <FormGroup row>
                                            <Controller
                                                name="TypePayment"
                                                control={control}
                                                render={({ field }) => (
                                                    <>
                                                        {TypePaypmentList.map((payment) => (
                                                            <FormControlLabel
                                                                key={payment.value}
                                                                label={payment.label}
                                                                control={
                                                                    <Checkbox
                                                                        value={payment.value}
                                                                      
                                                                        //checked={field.value.some(
                                                                        //    (existingValue) => existingValue === payment.value
                                                                        //) }
                                                                        onChange={(event, checked) => {
                                                                            if (checked) {
                                                                                field.onChange([
                                                                                    ...field.value,
                                                                                    event.target.value
                                                                                ]);
                                                                            } else {
                                                                                field.onChange(
                                                                                    field.value.filter(
                                                                                        (value) => value !== event.target.value
                                                                                    )
                                                                                );
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        ))}
                                                    </>
                                                )}
                                            />
                                        </FormGroup>
                                    </FormControl>
                                        
                                    

                                </Grid>
                                <Grid xs={12} md={12}>
                                    <Controller
                                        name="Comments"
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <TextField
                                                {...field}
                                                id="outlined-multiline-flexible"
                                                label="Indicaciones"
                                                rows={4}
                                                multiline
                                                fullWidth
                                                sx={{ marginTop: "25px" }}
                                                helperText="Escribe tus recomendaciones aquí"
                                                error={Boolean(error)} helperText={error?.message}
                                            />
                                        )}
                                    />
                                </Grid>

                            </Grid>







                        </DialogContent>
                        <DialogActions sx={{ justifyContent: 'center', paddingTop: '1px !important' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Button onClick={() => { props?.handleCloseCustom(props?.type) }}  variant="outlined" fullWidth sx={{ color: "#212B36", border: "1px solid rgba(145,158,171,0.32)" }}>No enviar y cerrar esta ventana</Button>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <LoadingButton type="submit" variant="contained" fullWidth disabled={isValid ? false : true} loading={loading?.saving}>Enviar solicitud</LoadingButton>
                                </Grid>
                            </Grid>



                        </DialogActions>
                    </form>
                </Dialog>
            </ModalStyle>

        </Fragment>









    );
}
