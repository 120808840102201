import { Fragment, useEffect } from "react";
import {  useNavigate,  useParams } from "react-router-dom";
import {  Typography, Button } from "@mui/material";
import useAuth from "../../../hooks/useAuth";
//import useIsMountedRef from "../../../hooks/useIsMountedRef";
import { styled } from '@mui/material/styles';
import exito1 from '../../../assets/images/icons/exito1.png';
import { Steps } from '../../../components/paymentez/steps';
import { PATH_PAYMENTEZ } from "../../../routes/paths";

const ContentStyle = styled('div')(({ theme }) => ({
    marginTop: "56px",
    textAlign: "center",
    "& img": {
        width: '291px',
        margin: "auto"
    },
    "&  .title": {
        marginTop: '41px'
    },
    "&  .btnContinue": {
        marginTop: '35px'
    }


}));


//const css = `
//    header {
//         width : 100% !important
//    }
//.sliderPaymentez{
//display:none;
//}
//`





//export default function EndFirstStep(props) {
export const EndFirstStep = () => {

    const navigate = useNavigate();
    
    let params = useParams();

    const { dispatch, gateway } = useAuth();


    //const isMountedRef = useIsMountedRef();
   
  





    const handle = async () => {

        dispatch({
            type: 'VIEWENDFIRSTSTEP',
            payload: {
                show: false
            }
        });


        navigate(PATH_PAYMENTEZ.app.gateway.banksDocuments + params?.affiliation);
       


    }


    useEffect(() => {
       
    
       
       

        if (gateway?.showViewEndFistStep) {
            //setTimeout(() => {

            //    handle();


            //}, 4000);
          
        } else {

            navigate(PATH_PAYMENTEZ.app.pageAllAffiliations);
        }

       


    }, []);


   









    return (

        <Fragment>
            <div style={{ marginTop: "50px" }}> <Steps /> </div>
            <ContentStyle>

                {/*    <style>{css}</style>*/}

                <img src={exito1} />
                <Typography variant="h4" mt={2} className="title">
                    ¡Completaste la primera fase de llenado de documentos!
                </Typography>

                <Typography variant="body1" mt={2}>
                    ¡Felicitaciones! Ahora toca elegir los bancos a los que desees afiliarte. <br />
                  {/*  Serás dirigido a la siguiente página en 4 segundos…*/}
                </Typography>

            <Button variant="contained" className="btnContinue" size="large" onClick={handle}>Continuar</Button>

            </ContentStyle>
        </Fragment>

    );
}
