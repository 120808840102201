import { useState, useEffect } from "react";
//import { Link as RouterLink } from "react-router-dom";
import {
    Grid, Card, Table, OutlinedInput, InputAdornment, Box,
    TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography
} from "@mui/material";
import useAuth from "../../../hooks/useAuth";
import { GetLogApp } from '../../../actions/LogAction';
import { styled } from '@mui/material/styles';
import LoadingScreen from '../../../components/LoadingScreen';
import ControlTyping from '../../../components/tool/ControlTyping';
import searchFill from '@iconify/icons-eva/search-fill';
import { format } from 'date-fns';

import { Icon } from '@iconify/react';
//import closeFill from "@iconify/icons-eva/close-fill";
//import { MIconButton } from "../../@material-extend";
//import { useSnackbar } from 'notistack';
//import { LoadingButton } from '@mui/lab';
import Label from '../../Label';
import useIsMountedRef from "../../../hooks/useIsMountedRef";


const ContentStyle = styled('div')(({ theme }) => ({
    marginTop: '25px',
    button: {

        [theme.breakpoints.down('md')]: {
            textAlign: 'left',
            marginTop: '5px',
            marginBottom: '5px',
        },
    },
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: '65%',
    marginBottom: '25px',
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
    }),
    //'&.Mui-focused': { width: '75%', boxShadow: theme.customShadows.z8 },
    //'& fieldset': {
    //    borderWidth: `1px !important`,
    //    borderColor: `${theme.palette.grey[500_32]} !important`
    //}
}));







export default function LogData(props) {
    const { user } = useAuth();

    const isMountedRef = useIsMountedRef();

    //const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [loading, setLoading] = useState({
        searchOrDelete: true
    });

    const [textSearch, setTextSearch] = useState(undefined);
    const typingSearchText = ControlTyping(textSearch, 900);


    const [pagerRequest, setPagerRequest] = useState({
        filter: "",
        pageNumber: 0,
        pageSize: 25
    })

    const [pagerResponse, setPagerResponse] = useState({
        listRecords: [],
        totalRecords: 0,
        totalPages: 0
    });

    //const [selected, setSelected] = useState(
    //    {
    //        order: []
    //    }
    //);


    const setPage = (event, newPage) => {


        setPagerRequest((prev) => ({
            ...prev,
            pageNumber: parseInt(newPage)
        }))
    }

    const setCountRecords = (event) => {

        setPagerRequest((prev) => ({
            ...prev,
            pageSize: parseInt(event.target.value),
            pageNumber: 0
        }))
    }

    //const cleanUnmount = () => {

    //    setPagerRequest({
    //        listRecords: [],
    //        totalRecords: 0,
    //        totalPages: 0
    //    });
    //}
    
  


    const loadDataTable = async () => {

        setLoading(prev => ({
            ...prev,
            searchOrDelete: true
        }));
       
        let filtertext = "";
        let page = pagerRequest.pageNumber + 1;

        if (typingSearchText) {
            filtertext = typingSearchText;
        }


        const objPagerRequest = {
            AdvisorId: user?.id,
            Filter: pagerRequest?.filter,
            PageNumber: page,
            PageSize: pagerRequest.pageSize
        }

        if (isMountedRef.current) {

            const [GetLog] = await Promise.all([
                GetLogApp(objPagerRequest),
            ]);

            if (GetLog !== undefined && GetLog?.status === 200) {

                setPagerResponse(GetLog.data);

                setLoading(prev => ({
                    ...prev,
                    searchOrDelete: false
                }));

            }

        }

    }





    useEffect(() => {


        if (typingSearchText !== undefined) {

            setPagerRequest((prev) => ({
                ...prev,
                filter: typingSearchText,
                pageNumber: 0
            }))
        }

    }, [typingSearchText])


    useEffect(() => {

        loadDataTable();

      
    }, [pagerRequest])



    return (




        <ContentStyle>


            <Card style={{ padding: "25px" }}>


                <SearchStyle
                    value={textSearch}
                    onChange={e => setTextSearch(e.target.value)}
                    placeholder="Buscar por tipo, action, mensaje y fecha"
                    startAdornment={
                        <InputAdornment position="start">
                            <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                        </InputAdornment>
                    }
                />

                {/*<p style={{ marginBottom: '12px' }}><b>Estos son los ultimos 5 comercios que registraste</b></p>*/}

                <Grid container>
                    <Grid item xs={12} md={12} >
                        <TableContainer style={{ width: "100%" }} >
                            <Table style={{ width: "100%" }}>
                                <TableHead style={{ width: "100%" }}>
                                    <TableRow>
                                        <TableCell align="left">Fecha</TableCell>


                                        <TableCell align="left">Msg </TableCell>
                                        <TableCell align="left">Type</TableCell>
                                        <TableCell align="left">Action</TableCell>

                                        <TableCell align="left">Status</TableCell>
                                        <TableCell align="left">Severity</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(!loading.searchOrDelete) &&
                                        pagerResponse.listRecords.map((log, index) => {


                                            return (
                                                <TableRow key={log?.auditLogId} >

                                                    <TableCell align="left" >

                                                        {format(new Date(log?.date), "dd/MM/yyyy  hh:mm aaaaa'm'")}

                                                    </TableCell>

                                                    <TableCell align="left" > {log?.msg} </TableCell>
                                                    <TableCell align="left" >
                                                        {log?.action}
                                                    </TableCell>
                                                    <TableCell align="left" >{log?.type} </TableCell>
                                                   
                                                    <TableCell align="left" >
                                                        
                                                        {log?.status === 1 && <Label variant={'filled'} color={'success'} sx={{ width: '100%' }} > Success </Label> }
                                                        {log?.status === 2 && <Label variant={'filled'} color={'error'} sx={{ width: '100%' }} > Fail </Label>}
                                                        {log?.status === 3 && <Label variant={'filled'} color={'warning'} sx={{ width: '100%' }} > Error </Label>}

                                                    </TableCell>
                                                    <TableCell align="left" >
                                                        {log?.severity === 1 && <Label variant={'filled'} color={'error'} sx={{ width: '100%' }} > High </Label>}
                                                        {log?.severity === 2 && <Label variant={'filled'} color={'warning'} sx={{ width: '100%' }} > Medium </Label>}
                                                        {log?.severity === 3 && <Label variant={'filled'} color={'info'} sx={{ width: '100%' }} > Low </Label>}

                                                       
                                                    </TableCell>
                                                   

                                                </TableRow>
                                            )
                                        })
                                    }



                                </TableBody>


                                {(loading.searchOrDelete) &&
                                    <caption style={{ textAlign: "center", marginTop: "10px", marginBottom: "10px" }}>
                                        <Grid container mt={12} mb={12}>
                                            <Grid item xs={12} md={12} style={{ textAlign: 'center' }}>

                                                <LoadingScreen sx={{ backgroundColor: 'transparent' }} />
                                            </Grid>
                                        </Grid>
                                    </caption>

                                }

                                {!loading.searchOrDelete && pagerResponse.listRecords?.length === 0 &&
                                    <caption style={{ textAlign: "center", marginTop: "10px", marginBottom: "10px" }}>
                                        <Grid container mt={12} mb={12}>
                                            <Grid item xs={12} md={12} style={{ textAlign: 'center' }}>

                                                <Typography variant="h6">
                                                    No se encontraron logs
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </caption>

                                }



                            </Table>
                        </TableContainer>

                        <TablePagination
                            rowsPerPageOptions={[1, 5, 10,15, 25]}
                            component="div"
                            count={pagerResponse.totalRecords}
                            rowsPerPage={pagerRequest.pageSize}
                            page={pagerRequest.pageNumber}
                            onPageChange={setPage}
                            onRowsPerPageChange={setCountRecords}
                        />

                        
                    </Grid>
                </Grid>


            </Card >


        </ContentStyle>



    );
}
