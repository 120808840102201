// material
import { useTheme } from "@mui/material/styles";
import { GlobalStyles as GlobalThemeStyles } from "@mui/material";

// ----------------------------------------------------------------------

export default function GlobalStyles() {
  const theme = useTheme();

  return (
    <GlobalThemeStyles
      styles={{
        "*": {
          margin: 0,
          padding: 0,
          boxSizing: "border-box",
        },
        html: {
          width: "100%",
          height: "100%",
          WebkitOverflowScrolling: "touch",
        },
        body: {
          width: "100%",
          height: "100%",
        },
        "#root": {
          width: "100%",
          height: "100%",
        },
        input: {
          "&[type=number]": {
            MozAppearance: "textfield",
            "&::-webkit-outer-spin-button": {
              margin: 0,
              WebkitAppearance: "none",
            },
            "&::-webkit-inner-spin-button": {
              margin: 0,
              WebkitAppearance: "none",
            },
          },
        },

        textarea: {
          "&::-webkit-input-placeholder": {
            color: theme.palette.text.disabled,
          },
          "&::-moz-placeholder": {
            opacity: 1,
            color: theme.palette.text.disabled,
          },
          "&:-ms-input-placeholder": {
            color: theme.palette.text.disabled,
          },
          "&::placeholder": {
            color: theme.palette.text.disabled,
          },
        },

        img: { display: "block", maxWidth: "100%" },

        // Lazy Load Img
        ".blur-up": {
          WebkitFilter: "blur(5px)",
          filter: "blur(5px)",
          transition: "filter 400ms, -webkit-filter 400ms",
        },
        ".blur-up.lazyloaded ": {
          WebkitFilter: "blur(0)",
          filter: "blur(0)",
        },

        //Paymentez

        //".clrNuveiPrincipal": {
        //    backgroundColor:"#493DAA !important "
        //},
        ".textNuvei": {
          color: "#786CD3 !important ",
        },
        ".MuiStepLabel-iconContainer .Mui-completed ": {
          color: "#00AB55 !important",
        },
        //".css-6494io-MuiButtonBase-root-MuiListItemButton-root": {
        //    color: "#786CD3 !important",
        //    backgroundColor:"#F1F0F9 !important"
        //},
        //".MuiListItemButton-root:before": {
        //    backgroundColor: "#786CD3 !important"
        //},
        ".css-1i9jjgo": {
          backgroundColor: "#786CD3 !important",
        },

        ".NumberCustom": {
          width: "17px",
          minWidth: "17px",
          height: "18px",
          border: " 1px solid #637381",
          borderRadius: "50%",
          color: "#637381",
          fontSize: "12px",
          textAlign: "center",
          marginTop: "3px",
          cursor: "pointer",
        },
        ".HelpContent": {
          display: "grid",
          gridTemplateColumns: "24px auto",
          margin: "15px 0px",
        },
        ".InputCustom": {
          color: "#212B36",
        },

        ".CardHeaderCustom": {
          width: "100%",
          [theme.breakpoints.down("md")]: {
            width: "100%",
          },
          "& .MuiCardHeader-root": {
            padding: "8px 16px",
          },
          "& img": {
            width: "42px",
            height: "42px",
          },
          "& .CardItemTitle": {
            fontWeight: "bold",
            letterSpacing: "0",
            lineHeight: "19px",
          },
          "& .CardItemSubheader": {
            fontSize: "12px",
          },
          "& button": {
            color: "#212B36",
            padding: "0",
          },
          "& span": {
            marginTop: "7px",
          },

          "& a": {
            color: "#212B36",
            padding: "0",
          },
          "& a p": {
            marginBottom: "5px",
          },
        },
        ".CardItem1": {
          marginTop: "7px",
        },
        ".CardItem2": {
          marginBottom: "7px",
        },
        ".ContentAndTooltip": {
          display: "grid",
          gridTemplateColumns: "auto 15%",
          justifyItems: "center",
        },
        ".ContentAndTooltip2": {
          display: "grid",
          gridTemplateColumns: "auto 7%",
          justifyItems: "center",
        },
        ".ErrorCustomStyle": {
          color: "#FF4842",
          boxSizing: "border-box",
          border: "1px solid #FF4842",
          borderRadius: "14px",
          padding: "8px",
          "& .MuiFormLabel-root": {
            color: "#FF4842 !important",
          },
        },

        ".TextCustomError": {
          color: "#FF4842",
          fontSize: "0.75rem",
          lineHeight: "1.5",
          fontFamily: "Public Sans, sans-serif",
          fontWeight: "400",
          textAlign: "left",
          // marginTop: '3px',
          marginRight: "14px",
          marginBottom: "0",
          marginLeft: "14px",
          marginTop: "8px",
        },
        ".TextColorError": {
          color: "#FF4842",
        },
        ".scrollCustomReview": {
          overflowY: "scroll",
            height: "75vh",
        },
      }}
    />
  );
}
