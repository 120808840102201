import { Fragment, useState } from "react";
import {
    Grid,
    FormGroup,
    FormControlLabel,
    Checkbox

} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";
import { AffiliationLastStepEnd } from '../../../actions/AffiliationAction';
import useAuth from "../../../hooks/useAuth";

const ContentStyle = styled("div")(({ theme }) => ({
    display: "flex",
    margin: "auto",
    position: "fixed",
    backgroundColor: "#E3F6EE",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "72px",
    padding: "0 25px 0 25px",
    /*  zIndex: 9999,*/
    zIndex: 1200,
}));



export default function FooterFinal(props) {

    const { dispatch, businessData } = useAuth();
    const [state, setState] = useState(false);
    const [loading, setLoading] = useState({
        initial: false,
        save: false
    });

    const handleChangeCheckBox = (event) => {
        setState(event.target.checked);

    }

    const saveData = (e) => {

        setLoading(prev => ({
            ...prev,
            save: true
        }));

        const objForm = {
            UserId: businessData?.userId,
            AffiliationDataId: props?.affiliationId
        }



        AffiliationLastStepEnd(objForm).then(response => {

            if (response?.status === 200) {




                setLoading(prev => ({
                    ...prev,
                    save: false
                }));

                dispatch({
                    type: 'VIEWENDLASTSTEP',
                    payload: {
                        show: true
                    }
                });




            } else {

                //setLoading(prev => ({
                //    ...prev,
                //    save: true
                //}));


            }

        });






    }

    return (
        <Fragment>

            <ContentStyle>
                <Grid container>
                    <Grid
                        item
                        xs={props?.size1 ? props?.size1 : 6}
                        sx={{ margin: "auto" }}
                    >
                        {props?.showConditions === true &&
                            <FormGroup style={{ alignItems: "flex-end" }} >
                                <FormControlLabel control={<Checkbox checked={state} onChange={handleChangeCheckBox} className="btn--shockwave is-active" />} label={<span style={{ fontSize: '15px' }}><strong>{props?.conditionsText}</strong></span>} />
                            </FormGroup>



                        }
                    </Grid>

                    <Grid
                        item
                        xs={props?.size2 ? props?.size2 : 6}
                        sx={{ textAlign: "right", margin: "auto 0px" }}
                    >

                        {props?.textButton2 !== "" && (
                            <LoadingButton
                                color={props?.color ? props?.color : "primary"}
                                size="large"
                                variant="contained"
                                onClick={saveData}
                                loading={loading?.save}
                                disabled={state ? false : true} >
                                {props?.textButton2}
                            </LoadingButton>
                        )}
                    </Grid>
                </Grid>
            </ContentStyle>
        </Fragment>
    );
}
