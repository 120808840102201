import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// components
import { MHidden } from '../../components/@material-extend';

import NotificationsPopover from '../dashboard/NotificationsPopover';
import AccountLogOut from '../dashboard/paymentez/AccountLogOut';

// ----------------------------------------------------------------------
import useAuth from "../../hooks/useAuth";

//const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const APPBAR_MOBILE = 64;
//const APPBAR_DESKTOP = 92;
const APPBAR_Paymentez = 62;

const RootStyle = styled(AppBar)(({ theme, backgroundcolor}) => ({
    heigth:'60px',
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    position:'unset',
    backgroundColor: backgroundcolor,
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up('lg')]: {
        minHeight: APPBAR_Paymentez,
        padding: theme.spacing(0, 5)
    }
}));

// ----------------------------------------------------------------------

Navbar.propTypes = {
    onOpenSidebar: PropTypes.func
};

export default function Navbar({ onOpenSidebar, Notification,backgroundColor,showAffiliationName}) {
    const { isCollapse } = useCollapseDrawer();
    const { businessData } = useAuth();
   
    const color = backgroundColor ? backgroundColor  : "#454F5B"
  
    return (
        <RootStyle backgroundcolor={color}
            sx={{
                ...(isCollapse && {
                    width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` }
                })
            }}
        >
            <ToolbarStyle>
                <MHidden width="lgUp">
                    <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
                        <Icon icon={menu2Fill} />
                    </IconButton>
                </MHidden>
              
                {showAffiliationName && <span style={{ color: '#FFFFFF', fontSize: '20px', fontWeight: 'bold', letterSpacing: 0, lineHeight: '30px' }}>{businessData?.businessName} </span> }
                <Box sx={{ flexGrow: 1 }} />

                <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
                    {Notification && <NotificationsPopover />}
                    <AccountLogOut />
                </Stack>
            </ToolbarStyle>
        </RootStyle>
    );
}
