import { forwardRef } from "react";
import { Grid, Card, CardHeader, CardContent, Avatar, TextField, Typography, Autocomplete, InputLabel } from "@mui/material";
import {  Controller } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import LoadingScreen from '../../../../../components/LoadingScreen';
import establecimiento from '../../../../../assets/images/icons/establecimiento.png';
import { IMaskInput } from 'react-imask';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

const CardHeaderCustom = styled('div')(({ theme, width }) => ({
    width: width,
    [theme.breakpoints.down('md')]: {
        width: '100%',
    }
}));

const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {

    const { onChange, value, name, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="(00) 000-0000"
            definitions={{
                '#': /[0-9]/,
            }}
            inputRef={ref}
            name={name}
            value={value}
            onPaste={(event) => {
                const pastedValue = event.clipboardData.getData('Text');
                onChange({
                    target: {
                        name,
                        value: pastedValue,
                    },
                });
            }}
            onChange={(event) => {
                onChange({
                    target: {
                        name,
                        value: event.target.value,
                    },
                });
            }}
            overwrite
        />
    );
});


export const DataEstablishment = ({ loading, fieldsSuccess, errors, businessData, control, country, setCountry, Provincias, setValue }) => {



    return (


        <Card sx={{ position: 'static' }}>
            <CardHeaderCustom width="353px"><CardHeader title="Completa los datos del establecimiento" avatar={<Avatar alt="establecimiento" src={establecimiento} variant="square" sx={{ width: '72px', height: '72px' }} />} /></CardHeaderCustom>
            <CardContent>



                {loading.initial

                    ?

                    <Grid container mt={12} mb={12}>
                        <Grid item xs={12} md={12}>
                            <LoadingScreen />
                        </Grid>
                    </Grid>

                    :


                    <Grid container rowSpacing={2} columnSpacing={3}>

                        <Grid item md={6}>

                            <span>Tipo de Personería:</span> <b>{businessData?.typePerson === 2 ? "Jurídico" : "Natural"}</b>

                        </Grid>
                        <Grid item xs={12} md={6} >
                            <Controller
                                name="TradeName"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField {...field} label="Nombre Comercial del Establecimiento" error={Boolean(error)} helperText={error?.message} fullWidth inputProps={{ style: { textTransform: "uppercase" } }}
                                        disabled={fieldsSuccess?.some(e => e.fieldCode === "TradeName")} />
                                )}
                            />

                        </Grid>

                        <Grid item xs={12} md={6}>

                            <Controller
                                name="BusinessName"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField {...field} label="Razón Social" error={Boolean(error)} helperText={error?.message} fullWidth inputProps={{ style: { textTransform: "uppercase" } }} disabled />
                                )}
                            />



                        </Grid>

                        <Grid item xs={12} md={6}>

                            <Controller

                                name="Identification"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField {...field} label="RUC" error={Boolean(error)} helperText={error?.message} fullWidth inputProps={{ style: { textTransform: "uppercase" } }} disabled />
                                )}
                            />



                        </Grid>

                       

                        <Grid item xs={12} md={6} >
                            <Controller
                                name="PlaceOrCountryConstitution"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField {...field} label={
                                        <>
                                            {businessData?.typePerson === 1 && "Lugar de constitución"}
                                            {businessData?.typePerson === 2 && "País de constitución"}
                                        </>
                                    }
                                        error={Boolean(error)} helperText={error?.message}
                                        sx={{ display: "block", textAlign: "left" }}
                                        fullWidth
                                        disabled={fieldsSuccess?.some(e => e.fieldCode === "PlaceOrCountryConstitution")}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} >
                            <Controller
                                name="DateConstitution"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            {...field}
                                            label="Fecha de constitución"
                                            renderInput={(params) => <TextField fullWidth {...params} error={Boolean(error)} />}
                                            inputFormat="dd/MM/yyyy"
                                            disabled={fieldsSuccess?.some(e => e.fieldCode === "DateConstitution")}
                                        />
                                    </LocalizationProvider>
                                )}
                            />
                        </Grid>


                        <Grid item xs={12} md={6}>
                            <Controller
                                name="RucAge"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField {...field} label="Tiempo en el mercado" error={Boolean(error)} helperText={error?.message} fullWidth inputProps={{ style: { textTransform: "uppercase" } }}
                                        disabled={fieldsSuccess?.some(e => e.fieldCode === "RucAge")} />
                                )}
                            />
 
                        </Grid>


                        <Grid item xs={12} md={6}>
                            <Controller
                                name="ComercialActivity"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField {...field} label="Actividad del Negocio" error={Boolean(error)} helperText={error?.message} fullWidth inputProps={{ style: { textTransform: "uppercase" } }}
                                        disabled={fieldsSuccess?.some(e => e.fieldCode === "ComercialActivity")} />
                                )}
                            />

                            <InputLabel sx={{ fontSize: '12px', paddingTop: '7px', whiteSpace: 'unset' }} >Colocar actividad que registra en RUC y de acuerdo con lo que vas a vender.</InputLabel>
                        </Grid>



                        <Grid item xs={12} md={6}>



                            <Controller
                                name="State"
                                control={control}
                                render={({ field: { onChange } }) => (
                                    <Autocomplete
                                        value={{ value: country?.selected?.province, label: country?.selected?.province }}
                                        options={country?.general?.province || ''}
                                        getOptionLabel={(option) => option?.value || ''}
                                        isOptionEqualToValue={(option, value) => option?.value === value?.value}
                                        disableClearable
                                        disabled={fieldsSuccess?.some(e => e.fieldCode === "State")}
                                        onChange={(e, options) => {

                                            if (options?.value !== undefined) {

                                                onChange(options?.value);
                                              
                                                let dataState = Provincias.find(e => e.value === options.value);
                                                setValue("Region", dataState?.region || '');
                                                let cantones = dataState?.cantones;
                                                setCountry(prev => ({
                                                    ...prev,
                                                    general: {
                                                        ...prev.general,
                                                        city: [{ value: '', label: '' }, ...cantones]
                                                    },
                                                    selected: {
                                                        ...prev.selected,
                                                        province: options?.value,
                                                        city: ""
                                                    }

                                                }));


                                            } else {
                                                onChange("");

                                                setCountry(prev => ({
                                                    ...prev,
                                                    general: {
                                                        ...prev.general,
                                                        city: ""
                                                    },
                                                    selected: {
                                                        ...prev.selected,
                                                        province: "",
                                                        city: ""
                                                    }

                                                }));
                                            }
                                        }}

                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Provincia"
                                                error={Boolean(errors?.state)}
                                                helperText={errors?.state?.message}

                                            />
                                        )}
                                    />
                                )}
                            />

                        </Grid>

                        <Grid item xs={12} md={6}>

                            <Controller
                                name="City"
                                control={control}
                                render={({ field: { onChange } }) => (
                                    <Autocomplete
                                        value={country?.selected?.city ? { value: country?.selected?.city, label: country?.selected?.city } : { value: '', label: '' }}
                                        options={country?.general?.city || []}
                                        getOptionLabel={(option) => option?.value || ''}
                                        isOptionEqualToValue={(option, value) => option?.value === value?.value || ''}
                                        disableClearable
                                        disabled={fieldsSuccess?.some(e => e.fieldCode === "City")}
                                        onChange={(e, options) => {

                                            if (options?.value !== undefined) {
                                                onChange(options?.value);

                                                setCountry(prev => ({
                                                    ...prev,
                                                    selected: {
                                                        ...prev.selected,
                                                        city: options.value
                                                    }

                                                }));


                                            } else {
                                                onChange("");

                                                setCountry(prev => ({
                                                    ...prev,
                                                    selected: {
                                                        ...prev.selected,
                                                        city: ""
                                                    }

                                                }));
                                            }
                                        }}

                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Ciudad/Canton"
                                                error={Boolean(errors?.state)}
                                                helperText={errors?.state?.message}

                                            />
                                        )}
                                    />
                                )}
                            />

                        </Grid>

                        <Grid item xs={12} md={6}>


                            <Controller
                                name="Sector"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField {...field} label="Sector" error={Boolean(error)} helperText={error?.message} fullWidth inputProps={{ style: { textTransform: "uppercase" } }}
                                        disabled={fieldsSuccess?.some(e => e.fieldCode === "Sector")} />
                                )}
                            />



                        </Grid>

                        <Grid item xs={12} md={6}>

                            <Controller
                                name="Neighborhood"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField {...field} label="Barrio/Parroquia" error={Boolean(error)} helperText={error?.message} fullWidth inputProps={{ style: { textTransform: "uppercase" } }}
                                        disabled={fieldsSuccess?.some(e => e.fieldCode === "Neighborhood")} />
                                )}
                            />



                        </Grid>

                        <Grid item xs={12} md={6}>

                            <Controller
                                name="AffiliationPhone"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField {...field} label="Teléfono, incluir código de provincia" error={Boolean(error)} helperText={error?.message} fullWidth

                                        InputProps={{

                                            inputComponent: TextMaskCustom,
                                        }}

                                        disabled={fieldsSuccess?.some(e => e.fieldCode === "AffiliationPhone")} />
                                )}
                            />



                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Controller
                                name="AffiliationCellPhone"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField {...field} label="Celular" error={Boolean(error)} helperText={error?.message} fullWidth 
                                        type="number"

                                        inputProps={{
                                            maxLength: 10,
                                            
                                        }}
                                        disabled={fieldsSuccess?.some(e => e.fieldCode === "AffiliationCellPhone")} />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Controller
                                name="EmailAffiliation"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField {...field} label="Email" error={Boolean(error)} helperText={error?.message} fullWidth inputProps={{ style: { textTransform: "lowercase" } }}
                                        disabled={fieldsSuccess?.some(e => e.fieldCode === "EmailAffiliation")} />
                                )}
                            />
                        </Grid>



                        <Grid item xs={12} md={12} mt={2}>
                            <Typography variant="h6" mt={1} >
                                Dirección del Establecimiento
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Controller
                                name="AddressMainStreet"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField {...field} label="Calle Principal" error={Boolean(error)} helperText={error?.message} fullWidth inputProps={{ style: { textTransform: "uppercase" } }}
                                        disabled={fieldsSuccess?.some(e => e.fieldCode === "AddressMainStreet")} />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Controller
                                name="AddressNumber"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField {...field} label="Numero" error={Boolean(error)} helperText={error?.message} fullWidth
                                        disabled={fieldsSuccess?.some(e => e.fieldCode === "AddressNumber")} />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Controller
                                name="AddressSecondStreet"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField {...field} label="Calle Secundaria" error={Boolean(error)} helperText={error?.message} fullWidth inputProps={{ style: { textTransform: "uppercase" } }}
                                        disabled={fieldsSuccess?.some(e => e.fieldCode === "AddressSecondStreet")} />
                                )}
                            />
                        </Grid>



                        <Grid item xs={12} md={8}>
                            <Controller
                                name="AddressReference"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField {...field} label="Referencia" error={Boolean(error)} helperText={error?.message} fullWidth inputProps={{ style: { textTransform: "uppercase" } }}
                                        disabled={fieldsSuccess?.some(e => e.fieldCode === "AddressReference")} />
                                )}
                            />
                        </Grid>


                        <Grid item xs={12} md={4}>
                            <Controller
                                name="PostalCode"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField {...field} label="Código postal" error={Boolean(error)} helperText={error?.message} fullWidth inputProps={{ style: { textTransform: "uppercase" } }}
                                        disabled={fieldsSuccess?.some(e => e.fieldCode === "PostalCode")} />
                                )}
                            />

                        </Grid>


                    </Grid>

                }

            </CardContent>
        </Card>

    );
}
