import { useEffect, useState } from 'react';
import * as signalR from '@microsoft/signalr';
import { GetExampleNotificationsByAffiliation, GetExampleNotificationsAdvisor, MarkAllReadNotificationOrRequestAdvisor, MarkAllReadNotificationOrRequestAffiliate } from '../actions/NotificationAction';
import useAuth from './useAuth';
import Global from '../Global';

const tokenProvider = async () => {

    return window.localStorage.getItem('accessToken');
};


export const useNotifications = () => {


    const { businessData, user } = useAuth();
    const rolUsuario = user?.rol?.toLowerCase();


    const [loading, setLoading] = useState(false);
    const [loadingAllMarket, setLoadingAllMarket] = useState(false);
    const [affiliationId, setAffiliationId] = useState(null);
    const [notifications, setNotifications] = useState([]);
    const [countNotifications, setCountNotifications] = useState([]);


    const onClickLoadNotifications = () => {

        if (countNotifications < 1) return true;

        setLoading(true);


        const objPagerRequest = {
            userId: user?.id,
            Filter: '',
            PageNumber: 1,
            PageSize: 4
        }

        if (rolUsuario === "affiliate") {

            GetExampleNotificationsByAffiliation(objPagerRequest).then(response => {
                setLoading(false);
                if (response?.status === 200) {
                    let datareturn = response?.data?.listRecords;
                    setAffiliationId(datareturn[0]?.affiliationDataId);
                    setNotifications(datareturn);
                }
            });
        }
       
        if (rolUsuario === "advisor" || rolUsuario === "supervisor" || rolUsuario === "moneyguard" || rolUsuario === "moneyguardadministrator" || rolUsuario === "technicianadministrator" || rolUsuario === "technician" || rolUsuario === "financial") {

            GetExampleNotificationsAdvisor(objPagerRequest).then(response => {
                setLoading(false);
                if (response?.status === 200) {
                    let datareturn = response?.data?.listRecords;
                    setNotifications(datareturn);
                }
            });

        }


    }

    const onClickMarkAllAsReadAffiliate = ({ Affiliations = [], Notification = false, loadDataTable = null }) => {

        setLoadingAllMarket(true);


        const objPagerRequest = {
            Affiliations,
            UserId: businessData?.userId,
            Notification,
        }

        MarkAllReadNotificationOrRequestAffiliate(objPagerRequest).then(response => {
            setLoadingAllMarket(false);
            if (response?.status === 200) {
                setNotifications(prevState => prevState.map((notification) => {
                    return { ...notification, read: true };
                }));
                if (loadDataTable !== null) { loadDataTable(); }
            }
        });


    }

    const onClickMarkAllAsReadAdvisor = ({ Notification = false, Request = false }) => {

        setLoadingAllMarket(true);


        const objPagerRequest = {
            AdvisorId: user?.id,
            Notification,
            Request,
        }



        MarkAllReadNotificationOrRequestAdvisor(objPagerRequest).then(response => {
            setLoadingAllMarket(false);
            if (response?.status === 200) {
                setNotifications(prevState => prevState.map((notification) => {
                    return { ...notification, read: true };
                }));
            }
        });



    }



    useEffect(() => {

        const tokenjwt = encodeURIComponent(window.localStorage.getItem('accessToken'));
        const handleBeforeUnload = function (event) {
            // Envía un mensaje de despedida al servidor antes de cerrar la conexión
            //connection.invoke("OnClientDisconnecting")
            //    .then(() => {
            //        // La conexión se cerrará después de enviar el mensaje de despedida
            //    })
            //    .catch((error) => {
            //        console.error("Error al enviar el mensaje de despedida al servidor:", error);
            //    });
            connection.stop();

        };
        window.addEventListener("beforeunload", handleBeforeUnload);


        const connection = new signalR.HubConnectionBuilder()
            .withUrl(`${Global?.base_URL_HUB?.NOTIFICATION}`, {
                accessTokenFactory: async () => {
                    return tokenjwt;
                }
            })
            .withAutomaticReconnect()
            .configureLogging(signalR.LogLevel.None)
            .build()

        connection.start()
            .then(() => {
                const userId = user?.id;
                connection.invoke("SendNotification", userId)
                    .catch(err => console.error(err));
            })
            .catch(err => console.error(err));
        connection.on("UpdateNotificationCount", newNotificationCount => {
            setCountNotifications(newNotificationCount);
        });


        // Retornar la función de limpieza de efectos
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);

            connection.off("UpdateNotificationCount");
            connection.stop();
        };
    }, []);


    return {
        notifications,
        countNotifications,
        affiliationId,
        loading,
        loadingAllMarket,
        onClickLoadNotifications,
        onClickMarkAllAsReadAdvisor,
        onClickMarkAllAsReadAffiliate,
        rol: rolUsuario,
    };
};