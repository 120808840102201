import {  useState,useEffect } from 'react';
import {Button, Box} from '@mui/material';
import ModaleGetNewAffiliation from '../../../components/paymentez/modal/ModaleGetNewAffiliation';
import useAuth from "src/hooks/useAuth";
import { CheckIfEstablishmentNotifiedNewAffiliation, } from "../../../actions/NotificationAction";

export default function NotificationNewAffiliation() {

    const { businessData } = useAuth();

    const [openModalCustom, setOpenModalCustom] = useState({
        newAffiliation: false,
    });


    

    const handleClick = (e) => {
        e.preventDefault();
        setOpenModalCustom(prev => ({
            ...prev,
            newAffiliation: true
        }));
    };


    const handleCloseCustom = (type) => {

        setOpenModalCustom(prev => ({
            ...prev,
            [type]: false
        }));

    };



    const [active, setActive] = useState(false);


    const loadingInitialData = async () => {
      

        const searchGateway = businessData?.affiliationData.find(e => e.statusAffiliate < 7 && e.typeAffiliation === 1);
        const searchCollect = businessData?.affiliationData.find(e => e.statusAffiliateCollect < 7 && e.typeAffiliation === 2);

        if (searchGateway === undefined && searchCollect === undefined) {

            //buscar si ya existe una nueva afiliacion notificada
            var bussinessobj = {
                UserId: businessData?.userId
            }

            CheckIfEstablishmentNotifiedNewAffiliation(bussinessobj).then(response => {

                if (response.status === 200) {
                } else {
                    setActive(true);

                }
            });

        }

    }

    useEffect(() => {

        loadingInitialData();

    }, []);


    return (
        <>
            <Box >
                <Button fullWidth  onClick={handleClick} variant="contained" size="small" disabled={active ? false : true}>
                   Solicita nueva afiliación 
                </Button>
            </Box>

            {openModalCustom?.newAffiliation &&

                <ModaleGetNewAffiliation handleCloseCustom={handleCloseCustom} type="newAffiliation" setActive={setActive}/>
            }
            
        </>
    );
}
