import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import axios from "axios";

// utils

import { isValidToken, setSession } from "../utils/jwt";

// ----------------------------------------------------------------------
import Global from "../Global";
import { GetCurrentUser } from "../actions/UserAction";

const instancia = axios.create();
instancia.CancelToken = axios.CancelToken;
instancia.isCancel = axios.isCancel;

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
    businessData: null,
    gateway: {
        showViewEndFistStep: false,
        showViewEndLastStep: false
    },
    collect: {
        showViewEndFistStep: false,
        showViewEndLastStep: false
    },
    welcomeAffiliation: {
        type:'',
        status: false,
        affiliationDataId: '',
    },
 

};

const handlers = {
    INITIALIZE: (state, action) => {
        const { isAuthenticated, user, welcomeAffiliation, businessData } =
            action.payload;
        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user,
            businessData,
            welcomeAffiliation,
        };
    },
    INITIALIZEUSERNUVEI: (state, action) => {
        const { isAuthenticated, user } =
            action.payload;
        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user
        };
    },

     
    //LOGIN: (state, action) => {
    //    const { user} = action.payload;

    //    return {
    //        ...state,
    //        isAuthenticated: true,
    //        user
    //    };
    //},
    LOGOUT: (state) => ({
        ...state,
        isAuthenticated: false,
        user: null,
    }),

    ESTABLISHMENTDATA: (state, action) => {
        const { establishmentData, affiliationDataId } = action.payload;



        return {
            ...state,
            businessData: {
                ...state.businessData,
                affiliationData: state.businessData.affiliationData.map((affiliation) =>
                    affiliation.affiliationDataId === affiliationDataId
                        ? {
                            ...affiliation, stepStatus: affiliation?.stepStatus.map((step) => step.step === 1
                                ? { ...step, status: establishmentData } : step)
                        }
                        : affiliation
                ),
            },
        };


    },

    ENABLINGDOCUMENTS: (state, action) => {
    

        const { enablingDocuments, affiliationDataId } = action.payload;

        return {
            ...state,
            businessData: {
                ...state.businessData,
                affiliationData: state.businessData.affiliationData.map((affiliation) =>
                    affiliation.affiliationDataId === affiliationDataId
                        ? {
                            ...affiliation, stepStatus: affiliation?.stepStatus.map((step) => step.step === 2
                                ? { ...step, status: enablingDocuments } : step)
                        }
                        : affiliation
                ),
            },
        };
    },

    PAYMENTEZDOCUMENTS: (state, action) => {
        const { stepPaymentez, affiliationDataId } = action.payload;

        return {
            ...state,
            businessData: {
                ...state.businessData,
                affiliationData: state.businessData.affiliationData.map((affiliation) =>
                    affiliation.affiliationDataId === affiliationDataId
                        ? {
                            ...affiliation, stepStatus: affiliation?.stepStatus.map((step) => step.step === 3
                                ? { ...step, status: stepPaymentez } : step)
                        }
                        : affiliation
                ),
            },
        };
    },
    BANKDOCUMENTS: (state, action) => {

        const { stepbanks, affiliationDataId } = action.payload;

        return {
            ...state,
            businessData: {
                ...state.businessData,
                affiliationData: state.businessData.affiliationData.map((affiliation) =>
                    affiliation.affiliationDataId === affiliationDataId
                        ? {...affiliation, stepStatus: affiliation?.stepStatus.map((step) => step.step === 4
                            ? { ...step, status : stepbanks } : step)
                        }
                        : affiliation
                ),
            },
        };


    },
    SELECTEDPAYTYPE: (state, action) => {
        const { affiliationDataId, usePaymentGateway, useLinkToPay} = action.payload;

        return {
            ...state,
            businessData: {
                ...state.businessData,
                affiliationData: state.businessData.affiliationData.map((affiliation) =>
                    affiliation.affiliationDataId === affiliationDataId
                        ? { ...affiliation, usePaymentGateway, useLinkToPay}
                        : affiliation
                ),
            },
            welcomeAffiliation: {
                type: '',
                status: false,
                affiliationdataid: ''
            }
           
        };
    },
    VIEWENDFIRSTSTEP: (state, action) => {
        const { show } = action.payload;

        return {
            ...state,
            gateway: {
                ...state.gateway,
                showViewEndFistStep: show,
            } 
           
        };
    },
    VIEWENDLASTSTEP: (state, action) => {
        const { show } = action.payload;

        return {
            ...state,
            gateway: {
                ...state.gateway,
                showViewEndLastStep: show,
            } 
        };
    },
    UPDATETRADENAMEANDSTATUSCIVIL: (state, action) => {

        const { tradeName, legalRepresentativeCivilStatus, affiliationDataId } = action.payload;


        return {
            ...state,
            businessData: {
                ...state.businessData,
                legalRepresentativeCivilStatus: legalRepresentativeCivilStatus,
                affiliationData: state.businessData.affiliationData.map((affiliation) =>
                    affiliation.affiliationDataId === affiliationDataId
                        ? { ...affiliation, tradeName: tradeName }
                        : affiliation
                ),
            },
        };
    },
    UPDATEPAYMENT: (state, action) => {
        const { paytype, affiliationDataId } = action.payload;

        return {
            ...state,
            businessData: {
                ...state.businessData,
                affiliationData: state.businessData.affiliationData.map((todo) =>
                    todo.affiliationDataId === affiliationDataId
                        ? { ...todo, [paytype]: true }
                        : todo
                ),
            },
        };
    },
    ENDAFFILIATION : (state, action) => {
        const { affiliationDataId } = action.payload;

        return {
            ...state,
            businessData: {
                ...state.businessData,
                affiliationData: state.businessData.affiliationData.map((todo) =>
                    todo.affiliationDataId === affiliationDataId
                        ? { ...todo, statusAffiliate: 7 }
                        : todo
                ),
            },
        };
    },
    UPDATESTATUSAFFILIATION: (state, action) => {
        const { affiliationDataId, statusAffiliate} = action.payload;

        return {
            ...state,
            businessData: {
                ...state.businessData,
                affiliationData: state.businessData.affiliationData.map((todo) =>
                    todo.affiliationDataId === affiliationDataId
                        ? { ...todo, statusAffiliate }
                        : todo
                ),
            },
        };
    },

    ESTABLISHMENTDATACOLLECT: (state, action) => {
        const { establishmentData, affiliationDataId } = action.payload;



        return {
            ...state,
            businessData: {
                ...state.businessData,
                affiliationData: state.businessData.affiliationData.map((affiliation) =>
                    affiliation.affiliationDataId === affiliationDataId
                        ? {
                            ...affiliation, stepStatusCollect: affiliation?.stepStatusCollect?.map((step) => step.step === 1
                                ? { ...step, status: establishmentData } : step)
                        }
                        : affiliation
                ),
            },
        };


    },
    ENABLINGDOCUMENTSCOLLECT: (state, action) => {

        const { enablingDocuments, affiliationDataId, show } = action.payload;

        return {
            ...state,
            businessData: {
                ...state.businessData,
                affiliationData: state.businessData.affiliationData.map((affiliation) =>
                    affiliation.affiliationDataId === affiliationDataId
                        ? {
                            ...affiliation, statusAffiliateCollect: 1, stepStatusCollect: affiliation?.stepStatusCollect?.map((step) => step.step === 2
                                ? { ...step, status: enablingDocuments } : step)
                        }
                        : affiliation
                ),
            },
            collect: {
                ...state.collect,
                showViewEndFistStep: show,
            } 
        };

    },
    VIEWENDFIRSTSTEPCOLLECT: (state, action) => {
        const { show } = action.payload;

        return {
            ...state,
            collect: {
                ...state.collect,
                showViewEndFistStep: show,
            } 
        };
    },
    NUVEIDOCUMENTSCOLLECTCOLOR: (state, action) => {


        const { status,nuveiDocuments, affiliationDataId } = action.payload;

        return {
            ...state,
            businessData: {
                ...state.businessData,
                affiliationData: state.businessData.affiliationData.map((affiliation) =>
                    affiliation.affiliationDataId === affiliationDataId
                        ? {
                            ...affiliation, statusAffiliateCollect: status, stepStatusCollect: affiliation?.stepStatusCollect?.map((step) => step.step === 3
                                ? { ...step, status: nuveiDocuments } : step)
                        }
                        : affiliation
                ),
            },
            //collect: {
            //    ...state.collect,
            //    showViewEndLastStep: show,
            //}
        };

    },

    COMPLETEALLSTEPS: (state, action) => {


        const { show } = action.payload;

        return {
            ...state,
            //businessData: {
            //    ...state.businessData,
            //    affiliationData: state.businessData.affiliationData.map((affiliation) =>
            //        affiliation.affiliationDataId === affiliationDataId
            //            ? {
            //                ...affiliation, statusAffiliateCollect: 6, stepStatusCollect: affiliation?.stepStatusCollect?.map((step) => step.step === 3
            //                    ? { ...step, status: nuveiDocuments } : step)
            //            }
            //            : affiliation
            //    ),
            //},
            collect: {
                ...state.collect,
                showViewEndLastStep: show,
            }
        };

    },
    VIEWENDLASTSTEPCOLLECT: (state, action) => {


        const { show } = action.payload;

        return {
            ...state,
            //businessData: {
            //    ...state.businessData,
            //    affiliationData: state.businessData.affiliationData.map((affiliation) =>
            //        affiliation.affiliationDataId === affiliationDataId
            //            ? {
            //                ...affiliation, statusAffiliateCollect: 6, stepStatusCollect: affiliation?.stepStatusCollect?.map((step) => step.step === 3
            //                    ? { ...step, status: nuveiDocuments } : step)
            //            }
            //            : affiliation
            //    ),
            //},
            collect: {
                ...state.collect,
                showViewEndLastStep: show,
            }
        };

    },
    UPDATESTATUSAFFILIATIONCOLLECT: (state, action) => {
        const { affiliationDataId, statusAffiliateCollect } = action.payload;

        return {
            ...state,
            businessData: {
                ...state.businessData,
                affiliationData: state.businessData.affiliationData.map((todo) =>
                    todo.affiliationDataId === affiliationDataId
                        ? { ...todo, statusAffiliateCollect }
                        : todo
                ),
            },
        };
    },
    ENDAFFILIATIONCOLLECT: (state, action) => {
        const { affiliationDataId } = action.payload;

        return {
            ...state,
            businessData: {
                ...state.businessData,
                affiliationData: state.businessData.affiliationData.map((todo) =>
                    todo.affiliationDataId === affiliationDataId
                        ? { ...todo, statusAffiliateCollect: 11 }
                        : todo
                ),
            },
        };
    },


    
    
};

const reducer = (state, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
    ...initialState,
    method: "jwt",

    //login: () => Promise.resolve(),
    //logout: () => Promise.resolve(),
    //register: () => Promise.resolve()
});

AuthProvider.propTypes = {
    children: PropTypes.node,
};

function AuthProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        const initialize = async () => {
            const accessToken = window.localStorage.getItem("accessToken");
            
            if (accessToken && isValidToken(accessToken)) {

               

                setSession(accessToken);

                GetCurrentUser().then((response) => {

                    

                    if (response?.status === 200) {
                        const { data } = response;

                        setSession(data?.token);

                        const { businessData, ...OnlyUserData } = data;

                        const rol = data?.rol?.toUpperCase();


                        if ((rol ===  "AFFILIATE")) {

                            AffiliateData(data, OnlyUserData);

                        }
                       
                        if ((rol !== "AFFILIATE")) {
                            NuveiUser(data, OnlyUserData, 'INITIALIZEUSERNUVEI');

                        }
                        
                        //if (rol === "ADVISOR" || rol === "SUPERVISOR" || rol === "ADMINISTRATOR") {

                        //    NuveiUser(data, OnlyUserData,'INITIALIZEUSERNUVEI');

                        //}

                        //if ((rol === "MONEYGUARD" || rol === "MONEYGUARDADMINISTRATOR")) {
                        //    NuveiUser(data, OnlyUserData, 'INITIALIZEUSERNUVEI');
                        //}

                     
                    } else {
                        CleanAndInitial();
                    }
                }).catch(function (e) {
                    //console.log(e);
                    CleanAndInitial();

                   
                });



            } else {
                CleanAndInitial();
            }
        };

        initialize();
    }, []);



    const CleanAndInitial = () => {
        window.localStorage.removeItem("accessToken");

        dispatch({
            type: "INITIALIZE",
            payload: {
                ...initialState
            },
        });
    }


    const AffiliateData = async (data, OnlyUserData) => {

        if (data?.businessData) {

            let  activeChoosePayType = false;
            
            const getAffiliation = data?.businessData?.affiliationData?.find(e => e.usePaymentGateway === false && e.useLinkToPay === false);

            if (getAffiliation !== undefined) { activeChoosePayType = true; }

            dispatch({
                type: 'INITIALIZE',
                payload: {
                    isAuthenticated: true,
                    user: OnlyUserData,
                    businessData: data?.businessData,
                    welcomeAffiliation: {
                        type: getAffiliation?.typeAffiliation,
                        affiliationDataId: getAffiliation?.affiliationDataId,
                        status: activeChoosePayType,
                    }

                }
            });


         

        }

    }


    const NuveiUser = async (data, OnlyUserData, typeDispatch) => {

        dispatch({
            type: typeDispatch,
            //type: 'INITIALIZEADVISOR',
            payload: {
                isAuthenticated: true,
                user: OnlyUserData,

            }
        });

    }







    const login = async (emailcode) => {
        const response = await instancia.post(
            Global.base_API_URL + "/account/logincode",
            emailcode
        );

        /*const { data } = response;*/

        return response;
        //setSession(data.token);
        //dispatch({
        //    type: 'LOGIN',
        //    payload: {
        //        user: data
        //    }
        //});
    };

    const register = async (email, password, firstName, lastName) => {
        const response = await axios.post("/api/account/register", {
            email,
            password,
            firstName,
            lastName,
        });
        const { accessToken, user } = response.data;

        window.localStorage.setItem("accessToken", accessToken);
        dispatch({
            type: "REGISTER",
            payload: {
                user,
            },
        });
    };

    const logout = async () => {
        setSession(null);
        dispatch({ type: "LOGOUT" });
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                dispatch,
                method: "jwt",
                /* login,*/
                /*logout,*/
                /*register,*/
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export { AuthContext, AuthProvider };
