import { Fragment, useState, useEffect } from "react";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import useAuth from "../../../../hooks/useAuth";
import { GetAffiliationDataCollect, GetBusinessCollectData, SaveAffiliationData } from '../../../../actions/AffiliationAction';
import { styled } from '@mui/material/styles';
import { Footer } from "../../footer";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Provincias from "../../../../utils/provincias.json";
import { useSnackbar } from 'notistack';
import { PATH_PAYMENTEZ } from '../../../../routes/paths';
import useIsMountedRef from "../../../../hooks/useIsMountedRef";
import { useFormStatusFields } from '../../hooks';
import { DataEstablishment, LegalRepresentativeOrOwner } from '../form/enablingDocuments'
import { MIconButton } from '../../../../components/@material-extend';
import { Icon } from '@iconify/react';
import closeFill from "@iconify/icons-eva/close-fill";
import { useUtils } from "../../../../hooks/useUtils";


const ContentStyle = styled('div')(({ theme }) => ({
    marginTop: '30px'
}));


export const defaultValues = {
    TradeName: '',
    BusinessName: '',
    Identification: '',
    PlaceOrCountryConstitution: '',
    DateConstitution: '',
    RucAge: '',
    PostalCode:'',
    ComercialActivity: '',
    Region:'',
    State: '',
    City: '',
    Sector: '',
    Neighborhood: '',
    AffiliationPhone: '',
    AffiliationCellPhone: '',
    EmailAffiliation: '',
    AddressMainStreet: '',
    AddressNumber: '',
    AddressSecondStreet: '',
    AddressReference: '',
    LegalRepresentativeName: '',
    LegalRepresentativeLastName: '',
    LegalRepresentativeIdentification: '',
    LegalRepresentativePosition: '',
    LegalRepresentativeIsPEP:'',
    LegalRepresentativeCivilStatus: 0,
    LegalRepresentativeNameSpouse: '',
    LegalRepresentativeSpouseLastName: '',
    LegalRepresentativeSpouseIdentification: '',

    LegalRepresentativeIsACompany: '',
    LegalRepresentativeCompanyBusinessName: '',
    LegalRepresentativeBirthday: '',
    LegalRepresentativeNationality: '',
    LegalRepresentativeSpouseBirthday: '',
    LegalRepresentativeSpouseNationality: '',



};

export const FormSchema = Yup.object().shape({
    TradeName: Yup.string().test('', '', val => val?.trim() !== "").required(),
    BusinessName: Yup.string().test('', '', val => val?.trim() !== "").required(),
    Identification: Yup.number().typeError(''),
    PlaceOrCountryConstitution: Yup.string().test('', '', val => val?.trim() !== "").required(),
    DateConstitution: Yup.date().typeError('').required(''),
    RucAge: Yup.string().test('', '', val => val?.trim() !== "").required(),
    PostalCode: Yup.string().test('', '', val => val?.trim() !== "").required(),
    ComercialActivity: Yup.string().test('', '', val => val?.trim() !== "").required(),
    State: Yup.string().test('', '', val => val?.trim() !== "").required(),
    City: Yup.string().test('', '', val => val?.trim() !== "").required(),
    Sector: Yup.string().test('', '', val => val?.trim() !== "").required(),
    Neighborhood: Yup.string().test('', '', val => val?.trim() !== "").required(),
    AffiliationPhone: Yup.string().test('', '', val => val?.trim() !== "").required(),
    AffiliationCellPhone: Yup.string().test('', '', val => val?.trim() !== "").required(),
    EmailAffiliation: Yup.string().test('', '', val => val?.trim() !== "").required(),
    AddressMainStreet: Yup.string().test('', '', val => val?.trim() !== "").required(),
    //AddressNumber: Yup.string().test('', '', val => val?.trim() !== "").required(),
    AddressSecondStreet: Yup.string().test('', '', val => val?.trim() !== "").required(),
    AddressReference: Yup.string().test('', '', val => val?.trim() !== "").required(),
    LegalRepresentativeName: Yup.string().test('', '', val => val?.trim() !== "").required(),
    LegalRepresentativeLastName: Yup.string().test('', '', val => val?.trim() !== "").required(),
    LegalRepresentativeIdentification: Yup.string().test('', '', val => val?.trim() !== "").required(),
    LegalRepresentativePosition: Yup.string().test('', '', val => val?.trim() !== "").required(),


    //LegalRepresentativeIsACompany: Yup.string().test('', '', val => val?.trim() !== ""),
    LegalRepresentativeCompanyBusinessName: Yup.string()
        .when("LegalRepresentativeIsACompany", {
            is: true,
            then: Yup.string().test('', '', val => val?.trim() !== "").required(),
        }),
    LegalRepresentativeBirthday: Yup.date().typeError('').required(''),
    LegalRepresentativeNationality: Yup.string().test('', '', val => val?.trim() !== "").required(),
 
    LegalRepresentativeIsPEP: Yup.string().test('', '', val => val?.trim() !== "").required(),
    LegalRepresentativeCivilStatus: Yup.number().required(''),
    LegalRepresentativeSpouseName: Yup.string()
        .when("LegalRepresentativeCivilStatus", {
            is: (LegalRepresentativeCivilStatus) => {

                if (LegalRepresentativeCivilStatus === 2 || LegalRepresentativeCivilStatus === 5) {

                    return true
                }
                return false;
            },
            then: Yup.string().test('', '', val => val?.trim() !== "").required(),
        }),
    LegalRepresentativeSpouseLastName: Yup.string()
        .when("LegalRepresentativeCivilStatus", {
            is: (LegalRepresentativeCivilStatus) => {
                if (LegalRepresentativeCivilStatus === 2 || LegalRepresentativeCivilStatus === 5) {
                    return true
                }
                return false;
            },
            then: Yup.string().test('', '', val => val?.trim() !== "").required(),
        }),
    LegalRepresentativeSpouseIdentification: Yup.string()
        .when("LegalRepresentativeCivilStatus", {
            is: (LegalRepresentativeCivilStatus) => {
                if (LegalRepresentativeCivilStatus === 2 || LegalRepresentativeCivilStatus === 5) {

                    return true
                }
                return false;
            },
            then: Yup.string().test('', '', val => val?.trim() !== "").required(),
        }),

    LegalRepresentativeSpouseBirthday: Yup.string()
        .when("LegalRepresentativeCivilStatus", {
            is: (LegalRepresentativeCivilStatus) => {
                if (LegalRepresentativeCivilStatus === 2 || LegalRepresentativeCivilStatus === 5) {

                    return true
                }
                return false;
            },
            then: Yup.string().test('', '', val => val?.trim() !== "").required(),
        }),
    LegalRepresentativeSpouseNationality: Yup.string()
        .when("LegalRepresentativeCivilStatus", {
            is: (LegalRepresentativeCivilStatus) => {
                if (LegalRepresentativeCivilStatus === 2 || LegalRepresentativeCivilStatus === 5) {

                    return true
                }
                return false;
            },
            then: Yup.string().test('', '', val => val?.trim() !== "").required(),
        }),
 

});


export const GeneralDataEstablishment = ({ IsResume = false }) => {

    const isMountedRef = useIsMountedRef();
    const { businessData, dispatch } = useAuth();
    const { ChangeFieldBoolToString, ChangeFieldStringToBool } = useUtils();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const navigate = useNavigate();
    const params = useParams();

    const [status, setStatus] = useState(0);
    const [statusDataAffiliate, setStatusDataAffiliate] = useState(0);
    const [openModalSave, setOpenModalSave] = useState(false);
    const handleOpenCloseModalSave = () => {
        setOpenModalSave(!openModalSave);
    };




    const [country, setCountry] = useState({
        general: {
            province: [],
            city: []
        },
        selected: {
            province: '',
            city: ''
        }

    });



    const [loading, setLoading] = useState({
        initial: true,
        onlySave: false,
        savingAndContinue: false,
        correctionByAffiliate: false
    });

    const {
        watch,
        reset,
        control,
        clearErrors,
        setValue,
        handleSubmit,

        setError,

        formState: { errors, isValid }
    } = useForm({
        mode: 'onChange',

        resolver: yupResolver(FormSchema),
        defaultValues
    });


    const { setFieldsError, fieldsSuccess, setFieldsSuccess, cleanDataFormByValidation } = useFormStatusFields({ status:statusDataAffiliate, setError, clearErrors, setValue });

    const SaveData = async (savindType) => {

        setLoading(prev => ({
            ...prev,
            [savindType]: true
        }));


        let checkstatus = 0;
        if ((statusDataAffiliate === 0 || statusDataAffiliate === 1) && (status === 0)) {
            checkstatus = isValid ? 1 : 0;
        }
        if (statusDataAffiliate === 4) {
            checkstatus = isValid ? 1 : 4;
        }


        const objEstablishment = {
            BusinessUserId: businessData.userId,
            AffiliationDataId: params.affiliation,
            StatusAffiliationData: checkstatus,
            TradeName: watch("TradeName")?.toUpperCase(),
            PlaceOrCountryConstitution: watch("PlaceOrCountryConstitution")?.toUpperCase(),
            DateConstitution: watch("DateConstitution") || null,
            RucAge: watch("RucAge")?.toUpperCase(),
            ComercialActivity: watch("ComercialActivity")?.toUpperCase(),
            Region: watch("Region")?.toUpperCase(),
            State: watch("State")?.toUpperCase(),
            City: watch("City")?.toUpperCase(),
            Sector: watch("Sector")?.toUpperCase(),
            Neighborhood: watch("Neighborhood")?.toUpperCase(),
            AffiliationPhone: watch("AffiliationPhone")?.toUpperCase(),
            AffiliationCellPhone: watch("AffiliationCellPhone")?.toUpperCase(),
            EmailAffiliation: watch("EmailAffiliation"),
            AddressMainStreet: watch("AddressMainStreet")?.toUpperCase(),
            AddressNumber: watch("AddressNumber")?.toUpperCase(),
            AddressSecondStreet: watch("AddressSecondStreet")?.toUpperCase(),
            AddressReference: watch("AddressReference")?.toUpperCase(),
            PostalCode: watch("PostalCode")?.toUpperCase(),
            LegalRepresentativeName: watch("LegalRepresentativeName")?.toUpperCase(),
            LegalRepresentativeLastName: watch("LegalRepresentativeLastName")?.toUpperCase(),
            LegalRepresentativeIdentification: watch("LegalRepresentativeIdentification")?.toUpperCase(),
            LegalRepresentativePosition: watch("LegalRepresentativePosition")?.toUpperCase(),
            LegalRepresentativeIsACompany: watch("LegalRepresentativeIsACompany") || null,
            LegalRepresentativeCompanyBusinessName: watch("LegalRepresentativeCompanyBusinessName")?.toUpperCase(),
            LegalRepresentativeIsPEP: ChangeFieldStringToBool(watch("LegalRepresentativeIsPEP")),
            LegalRepresentativeCivilStatus: watch("LegalRepresentativeCivilStatus") === "" ? 0 : parseInt(watch("LegalRepresentativeCivilStatus")),
            LegalRepresentativeSpouseName: watch("LegalRepresentativeSpouseName")?.toUpperCase(),
            LegalRepresentativeSpouseLastName: watch("LegalRepresentativeSpouseLastName")?.toUpperCase(),
            LegalRepresentativeSpouseIdentification: watch("LegalRepresentativeSpouseIdentification")?.toUpperCase(),
            LegalRepresentativeBirthday: watch("LegalRepresentativeBirthday") || null,
            LegalRepresentativeNationality: watch("LegalRepresentativeNationality")?.toUpperCase(),
            LegalRepresentativeSpouseBirthday: watch("LegalRepresentativeSpouseBirthday") || null,
            LegalRepresentativeSpouseNationality: watch("LegalRepresentativeSpouseNationality")?.toUpperCase(),
        }


      

        SaveAffiliationData(objEstablishment).then(response => {

            if (response?.status === 200) {


                dispatch({
                    type: 'UPDATETRADENAMEANDSTATUSCIVIL',
                    payload: {
                        tradeName: watch("TradeName"),
                        legalRepresentativeCivilStatus: watch("LegalRepresentativeCivilStatus") === "" ? 0 : parseInt(watch("LegalRepresentativeCivilStatus")),
                        affiliationDataId: params.affiliation
                    }
                });

                setLoading(prev => ({
                    ...prev,
                    [savindType]: false
                }));

                if (savindType === "onlySave") { setOpenModalSave(true); }

                if (savindType === "savingAndContinue") {


                    dispatch({
                        type: 'ESTABLISHMENTDATACOLLECT',
                        payload: {
                            establishmentData: 1,
                            affiliationDataId: params.affiliation
                        }
                    });
                    navigate(PATH_PAYMENTEZ.app.collect.enablingDocuments + params.affiliation);
                }

                if (savindType === "correctionByAffiliate") {
                    GoToPanel();
                }

            } else {

                enqueueSnackbar('Error, intente de nuevo', {
                    variant: 'error',
                    action: (key) => (
                        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                            <Icon icon={closeFill} />
                        </MIconButton>
                    )
                });

                setLoading(prev => ({
                    ...prev,
                    [savindType]: false
                }));

            }
        });


    }


    const SaveAndLater = async () => {

        SaveData("onlySave");

    }


    const onSubmit = async (data) => {

        if (statusDataAffiliate === 4) {
            SaveData("correctionByAffiliate");

        }

        if ((status === 0 || status === 2) && statusDataAffiliate !== 4) {
            SaveData("savingAndContinue");
        }


    };



    const loadingInitialData = async () => {

        setLoading(prev => ({
            ...prev,
            initial: true
        }));


        const onlyProvincies = Provincias.map(({ cantones, ...keepAttrs }) => keepAttrs);


        setCountry(prev => ({
            ...prev,
            general: {
                ...prev.general,
                province: onlyProvincies
            }

        }));


        let objBussiness = {
            UserId: businessData.userId,
            GetsValidationFieldsByAdvisor: true
        }

        let objAffiliation = {
            UserId: businessData.userId,
            AffiliationDataId: params.affiliation,
            GetsValidationFieldsByAdvisor: true
        }


        const [businessDataInfo, affiliationDataInfo] = await Promise.all([
            GetBusinessCollectData(objBussiness),
            GetAffiliationDataCollect(objAffiliation),
        ]);

        if (isMountedRef.current) {


            if (businessDataInfo !== undefined && businessDataInfo.status === 200 && affiliationDataInfo !== undefined && businessDataInfo.status === 200) {


                reset({

                    TradeName: affiliationDataInfo?.data?.tradeName || '',
                    BusinessName: businessDataInfo?.data?.businessName || '',
                    Identification: businessDataInfo?.data?.identification || '',
                    PlaceOrCountryConstitution: affiliationDataInfo?.data?.placeOrCountryConstitution || '',
                    DateConstitution: affiliationDataInfo?.data?.dateConstitution || '',
                    RucAge: affiliationDataInfo?.data?.rucAge || '',
                    PostalCode: affiliationDataInfo?.data?.postalCode || '',
                    ComercialActivity: affiliationDataInfo?.data?.comercialActivity || '',
                    Region: affiliationDataInfo?.data?.region || '',
                    State: affiliationDataInfo?.data?.state || '',
                    City: affiliationDataInfo?.data?.city || '',
                    Sector: affiliationDataInfo?.data?.sector || '',
                    Neighborhood: affiliationDataInfo?.data?.neighborhood || '',
                    AffiliationPhone: affiliationDataInfo?.data?.affiliationPhone || '',
                    AffiliationCellPhone: affiliationDataInfo?.data?.affiliationCellPhone || '',
                    EmailAffiliation: affiliationDataInfo?.data?.emailAffiliation || '',
                    AddressMainStreet: affiliationDataInfo?.data?.addressMainStreet || '',
                    AddressNumber: affiliationDataInfo?.data?.addressNumber || '',
                    AddressSecondStreet: affiliationDataInfo?.data?.addressSecondStreet || '',
                    AddressReference: affiliationDataInfo?.data?.addressReference || '',
                    LegalRepresentativeName: businessDataInfo?.data?.legalRepresentativeName || '',
                    LegalRepresentativeLastName: businessDataInfo?.data?.legalRepresentativeLastName || '',
                    LegalRepresentativeIdentification: businessDataInfo?.data?.legalRepresentativeIdentification || '',
                    LegalRepresentativeIsACompany: businessDataInfo?.data?.legalRepresentativeIsACompany || '',
                    LegalRepresentativeCompanyBusinessName: businessDataInfo?.data?.legalRepresentativeCompanyBusinessName || '',
                    LegalRepresentativeIsPEP: ChangeFieldBoolToString(businessDataInfo?.data?.legalRepresentativeIsPEP),
                    LegalRepresentativePosition: businessDataInfo?.data?.legalRepresentativePosition || '',
                    LegalRepresentativeCivilStatus: businessDataInfo?.data?.legalRepresentativeCivilStatus || '',
                    LegalRepresentativeBirthday: businessDataInfo?.data?.legalRepresentativeBirthday || '',
                    LegalRepresentativeNationality: businessDataInfo?.data?.legalRepresentativeNationality || '',
                    LegalRepresentativeSpouseBirthday: businessDataInfo?.data?.legalRepresentativeSpouseBirthday || '',
                    LegalRepresentativeSpouseNationality: businessDataInfo?.data?.legalRepresentativeSpouseNationality || '',
                    LegalRepresentativeSpouseName: businessDataInfo?.data?.legalRepresentativeSpouseName || '',
                    LegalRepresentativeSpouseLastName: businessDataInfo?.data?.legalRepresentativeSpouseLastName || '',
                    LegalRepresentativeSpouseIdentification: businessDataInfo?.data?.legalRepresentativeSpouseIdentification || '',
                });

              
                setStatusDataAffiliate(affiliationDataInfo?.data?.statusAffiliationData);
                setStatus(affiliationDataInfo?.data?.statusAffiliateCollect);

                
                if (affiliationDataInfo?.data?.state !== "" && affiliationDataInfo?.data?.state !== null) {
                    let cantones = Provincias.find(e => e.value === affiliationDataInfo?.data?.state)?.cantones;
                    setCountry(prev => ({
                        ...prev,
                        general: {
                            ...prev.general,
                            city: [{ value: '', label: '' }, ...cantones]
                        },
                        selected: {
                            ...prev.selected,
                            province: affiliationDataInfo?.data?.state
                        }
                    }));

                }


                if (affiliationDataInfo?.data?.city !== "") {

                    setCountry(prev => ({
                        ...prev,
                        selected: {
                            ...prev.selected,
                            city: affiliationDataInfo?.data?.city
                        }

                    }));

                }
                

                let joinFielsError = [...businessDataInfo?.data?.collectBusinessFormFieldsError, ...affiliationDataInfo?.data?.collectAffiliationGeneralFormFieldsError];
                let joinFielsSuccess = [...businessDataInfo?.data?.collectBusinessFormFieldsSuccess, ...affiliationDataInfo?.data?.collectAffiliationGeneralFormFieldsSuccess];


                setFieldsSuccess(joinFielsSuccess);
                setFieldsError(joinFielsError);





                setLoading(prev => ({
                    ...prev,
                    initial: false
                }));





            } else {

                setLoading(prev => ({
                    ...prev,
                    initial: false
                }));
            }


        }

    }





    useEffect(() => {

        loadingInitialData();

    }, []);



    const GoToPanel = async () => {
        navigate(PATH_PAYMENTEZ.app.collect.monitoringStateDocument + params.affiliation);
    }





    return (

        <Fragment>
            {!IsResume &&
                <Typography mt={4}>
                    Sabemos lo tedioso que es llenar formularios.
                    Por eso te pedimos que ingreses estos datos por una sola vez y así los formularios de afiliación que piden esta información, ya vendrán llenos.
                </Typography>
            
            }
          

            <ContentStyle>


                <form onSubmit={handleSubmit(onSubmit)}>


                    <Grid container spacing={5}>

                        <Grid item sm={12}>

                            <DataEstablishment
                                loading={loading}
                                fieldsSuccess={fieldsSuccess}
                                errors={errors}
                                businessData={businessData}
                                control={control}
                                country={country}
                                setCountry={setCountry}
                                Provincias={Provincias}
                                setValue={setValue}
                            />

                        </Grid>


                        <Grid item sm={12}>
                            <LegalRepresentativeOrOwner
                                loading={loading}
                                fieldsSuccess={fieldsSuccess}
                                errors={errors}
                                businessData={businessData}
                                control={control}
                                cleanDataFormByValidation={cleanDataFormByValidation}
                                watch={watch}
                                setValue={setValue}
                            />
                        </Grid>



                        {!loading.initial &&

                            <>
                            {((statusDataAffiliate === 0 || statusDataAffiliate === 1 || statusDataAffiliate === 2) && (status === 0 || status === 2) && !IsResume) &&
                                    <Footer
                                        size1="7"
                                        size2="5"
                                        isOpenModalSave={openModalSave}
                                        clickOpenCloseModalSave={handleOpenCloseModalSave}
                                        onClickButton1={SaveAndLater}
                                        isLoadingButton1={loading?.onlySave}
                                        textDetail="Terminado este paso, sube los documentos habilitantes solicitados para la afiliación"
                                        textButton1="Guardar y seguir después"
                                        textButton2="Continuar"
                                        isSubmitButton2={true}
                                        isLoadingButton2={loading?.savingAndContinue}
                                        disabledButton2={(isValid && Object.keys(errors).length === 0) ? false : true}
                                    />

                                }


                            {(statusDataAffiliate === 4 && !IsResume) &&
                                    <Footer
                                        color="warning"
                                        onClickButton1={GoToPanel}
                                        textButton1="Regresar al panel"
                                        textButton2="Actualizar documentación y regresar"
                                        isSubmitButton2={true}
                                        isLoadingButton2={loading?.correctionByAffiliate}
                                        disabledButton2={(isValid && Object.keys(errors).length === 0) ? false : true}
                                    />
                                }

                            </>

                        }


                    </Grid>

                </form>

            </ContentStyle>
        </Fragment>

    );
}
