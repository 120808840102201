import { Tooltip } from '@mui/material';
import { MIconButton } from '../../../components/@material-extend';
import useAuth from '../../../hooks/useAuth';
import DoneAllIcon from '@mui/icons-material/DoneAll';
export const MarkAllasReadByRol = ({ rol, onClickMarkAllAsReadAdvisor, onClickMarkAllAsReadAffiliate, loadingAllMarket }) => {


    const { businessData } = useAuth();
   
   
    const Affiliations = businessData?.affiliationData?.map((e) => {
        return   e?.affiliationDataId 
    });


    return (


        <Tooltip title="Marcar todos como leídos">
            <MIconButton color="primary" onClick={() => {

                if (rol === "affiliate") {

                    onClickMarkAllAsReadAffiliate({ Affiliations, Notification: true })
                }
                if (rol === "advisor" || rol === "supervisor"  || rol === "moneyguard" || rol === "moneyguardadministrator" || rol === "technicianadministrator" || rol === "technician") {
                    onClickMarkAllAsReadAdvisor({ Notification: true, Request: true })
                }

            }

            }>

                {loadingAllMarket && "la"}
                <DoneAllIcon style={{ width:20,height:20}}  />
               
               
            </MIconButton>
        </Tooltip>


    )
}

