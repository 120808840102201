// routes
import Router from "./routes";
// theme
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
import "./assets/css/style.css";
// components
//import Settings from './components/settings';
import RtlLayout from "./components/RtlLayout";
import ScrollToTop from "./components/ScrollToTop";
import LoadingScreen, { ProgressBarStyle } from "./components/LoadingScreen";
import ThemePrimaryColor from "./components/ThemePrimaryColor";
import NotistackProvider from "./components/NotistackProvider";

// ----------------------------------------------------------------------

import useAuth from "./hooks/useAuth";

export default function App() {
  const { isInitialized } = useAuth();

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <RtlLayout>
          <NotistackProvider>
            <GlobalStyles />
            <ProgressBarStyle />
            {/*<Settings />*/}
            <ScrollToTop />
            {isInitialized ? <Router /> : <LoadingScreen />}
          </NotistackProvider>
        </RtlLayout>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
