import { useState, useEffect } from "react";


export const useModalVinculacionJuridica = () => {

    const [openModalCustom, setOpenModalCustom] = useState({
        OwnerShareholderAdministratorsAttorney: false,
        NaturalPersonPayrollFinalBeneficiary: false,
        DeclaredEconomicActivity: false,
        FinancialSystemAccount: false
    });


    const [editObject, setEditObject] = useState({
        OwnerShareholderAdministratorsAttorney: {},
        NaturalPersonPayrollFinalBeneficiary: {},
        DeclaredEconomicActivity: {},
        FinancialSystemAccount: {}
    });


    const handleModalCustom = (e, type) => {
        setOpenModalCustom(prev => ({
            ...prev,
            [type]: true
        }));
    };



    const handleEditModalCustom = (e, type, object) => {
        setOpenModalCustom(prev => ({
            ...prev,
            [type]: true
        }));

        setEditObject(prev => ({
            ...prev,
            [type]: object
        }));

    }


    const handleCloseCustom = (type) => {
        setOpenModalCustom(prev => ({
            ...prev,
            [type]: false
        }));

        setEditObject(prev => ({
            ...prev,
            [type]: {}
        }));

    };





    return {

        openModalCustom,
        setOpenModalCustom,
        editObject,
        setEditObject,
        handleModalCustom,
        handleEditModalCustom,
        handleCloseCustom

    };

};