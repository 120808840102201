import { Fragment } from "react";
// material
import {
    Button,
    Link,
    Grid,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    CircularProgress,
    Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// ----------------------------------------------------------------------
import { styled } from "@mui/material/styles";
import esperando from "../../../assets/images/icons/esperando.png";

const ContentStyle = styled("div")(({ theme }) => ({
    display: "flex",
    margin: "auto",
    position: "fixed",
    backgroundColor: "#E2E7EE",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "72px",
    padding: "0 25px 0 25px",
    /*  zIndex: 9999,*/
    zIndex: 1200,
}));

const ModalStyle = styled("div")(({ theme }) => ({
    //display: 'flex',
    //margin: 'auto',
    //position: 'fixed',
    //backgroundColor: "#E3F6EE",
    //bottom: 0,
    //left: 0,
    //width: "100%",
    //height: "72px",
    //padding: "0 35px 0 35px",
    //zIndex: 9999,
}));

export default function Footer(props) {
    const { RefSubmit, isOpenModalSave, clickOpenCloseModalSave, size1, textDetail, size2, textButton1, isLoadingButton1,
        color, isSubmitButton2, disabledButton2, textButton2, onClickButton1, isLoadingButton2, onClickButton2
    } = props;

 

    return (
        <Fragment>
            <ModalStyle>
                <Dialog
                    open={isOpenModalSave ? isOpenModalSave  : false}
                    onClose={clickOpenCloseModalSave ? clickOpenCloseModalSave  : null}
                    maxWidth="xs"
                    sx={{ textAlign: "center" }}
                >
                    <div style={{ paddingTop: "20px" }}>
                        <img
                            src={esperando}
                            alt="footerimg"
                            height="132"
                            width="180"
                            style={{ margin: "auto" }}
                        />
                    </div>
                    <DialogTitle sx={{ padding: "10px" }}>
                        Tus datos se guardaron con éxito.
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText
                            id="alert-dialog-description"
                            sx={{ color: "#212B36" }}
                        >
                            Puedes retomar el proceso de afiliación cuando tengas más tiempo.
                            No olvides continuar lo más pronto posible.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions
                        sx={{ justifyContent: "center", paddingTop: "1px !important" }}
                    >
                        <Button onClick={clickOpenCloseModalSave} variant="outlined">
                            Cerrar esta ventana
                        </Button>
                    </DialogActions>
                </Dialog>
            </ModalStyle>
            <ContentStyle>
                <Grid container>
                    <Grid
                        item
                        xs={size1 ? size1 : 6}
                        sx={{ margin: "auto" }}
                    >
                        {textDetail !== "" && <span>{props.textDetail}</span>}
                    </Grid>

                    <Grid
                        item
                        xs={size2 ? size2 : 6}
                        sx={{ textAlign: "right", margin: "auto 0px" }}
                    >
                        {textButton1 !== "" && isLoadingButton1 ? (
                            <CircularProgress
                                sx={{ verticalAlign: "middle", marginRight: "6%" }}
                            />
                        ) : (
                            <Link
                                component="button"
                                onClick={onClickButton1}
                                underline="none"
                                disabled={isLoadingButton2 ? true : false}
                                    sx={{ marginRight: 2, cursor: "pointer", color:"#E40046" }}
                            >
                                <Typography variant="body1">{props.textButton1}</Typography>{" "}
                            </Link>
                        )}

                        {textButton2 !== "" && (
                            <LoadingButton
                                //ref={RefSubmit}
                                color={color ? color : "primary"}
                                size="large"
                                type={isSubmitButton2 ? "submit" : "button"}
                                variant="contained"
                                onClick={onClickButton2}
                                loading={isLoadingButton2}
                                disabled={
                                    disabledButton2 || isLoadingButton1 ? true : false
                                }
                            >
                                {textButton2}
                            </LoadingButton>
                        )}
                    </Grid>
                </Grid>
            </ContentStyle>
        </Fragment>
    );
}
