import { Fragment, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import useAuth from "../../../../hooks/useAuth";
import { AffiliationCollectFirstStepEnd } from '../../../../actions/AffiliationAction';
import { GetEnablingDocumentsCollect } from "../../../../actions/DocumentsAction";
import { styled } from "@mui/material/styles";
import { Footer } from "../../footer";
import LoadingScreen from '../../../../components/LoadingScreen';
import { PATH_PAYMENTEZ } from "../../../../routes/paths";
import useIsMountedRef from "../../../../hooks/useIsMountedRef";
import { UploadFile } from '../form/enablingDocuments'


const ContentStyle = styled("div")(({ theme }) => ({
    marginTop: "30px",
}));


export const UploadGeneralEstablishment = (props) => {

    const isMountedRef = useIsMountedRef();
    const params = useParams();
    const navigate = useNavigate();
    const { dispatch, businessData } = useAuth();
    const [listPdfData, setListPdfData] = useState([]);
    const [continueStep, setContinueStep] = useState(false);
    const [disabledLoadButton, setDisabledLoadButton] = useState(false);
    const [statusDataAffiliate, setStatusDataAffiliate] = useState(0);
    const [loading, setLoading] = useState({
        initial: false,
        continue: false,
        correctionByAffiliate: false
    });


    const GoToPanel = async () => {
        navigate(PATH_PAYMENTEZ.app.collect.monitoringStateDocument + params.affiliation);
    }


    const HandleContinueStep = async () => {

        setLoading(prev => ({
            ...prev,
            continue: true
        }));

        const objAffiliation = {
            UserId: businessData.userId,
            AffiliationDataId: params.affiliation,
        }

        AffiliationCollectFirstStepEnd(objAffiliation).then(response => {

            setLoading(prev => ({
                ...prev,
                continue: false
            }));


            if (response?.status === 200) {

                dispatch({
                    type: "ENABLINGDOCUMENTSCOLLECT",
                    payload: {
                        affiliationDataId: params.affiliation,
                        enablingDocuments: 1,
                        show: true
                    }
                });

            } 
        });


    };



    const loadingInitialData = async () => {

        setLoading(prev => ({
            ...prev,
            initial: true
        }));

        let searchAffiliation = businessData?.affiliationData?.find(e => e.affiliationDataId === params.affiliation);

        let objAffiliation = {
            BusinessUserId: businessData.userId,
            AffiliationDataId: params.affiliation,
        }


        setStatusDataAffiliate(searchAffiliation.statusAffiliateCollect);

        GetEnablingDocumentsCollect(objAffiliation).then(response => {

            if (response?.status === 200) {

                if (isMountedRef.current) {

                    setListPdfData(response?.data);

                    setLoading(prev => ({
                        ...prev,
                        initial: false
                    }));

                }


            } else {

                setLoading(prev => ({
                    ...prev,
                    initial: false
                }));

            }

        });

    }


    useEffect(() => {
        const AllFilesWereUploaded = listPdfData.filter(e=>e.isRequired === true).every((itemlist) => {
            return itemlist?.documentName !== undefined && itemlist?.documentName !== "" && itemlist?.documentName !== null;
        });
        setContinueStep(AllFilesWereUploaded);
    }, [listPdfData]);



    useEffect(() => {

        loadingInitialData();

    }, []);


    return (
        <Fragment>
            <Typography mt={4} variant="h3">
                Documentos habilitantes para iniciar la afiliación en NUVEI
            </Typography>

            <Typography mt={1}>
                Sube los documentos solicitados en PDF. Puedes escanearlos o tomar una
                foto con el celular y convertierlos s a PDF. Peso máximo 1 MB.
            </Typography>

            <ContentStyle>


                {loading.initial

                    ?

                    <Grid container mt={12} mb={12}>
                        <Grid item xs={12} md={12} >
                            <LoadingScreen sx={{ backgroundColor: 'transparent' }} />
                        </Grid>
                    </Grid>

                    :
                    <>
                        

                        <Grid container spacing={5} mt={-3}>


                            {listPdfData?.map((item, index) => {

                                return (
                                    <UploadFile key={item?.enablingDocumentId + item?.typeNameDocument}
                                        item={item}
                                        listPdfData={listPdfData}
                                        setListPdfData={setListPdfData}
                                        statusDataAffiliate={statusDataAffiliate}
                                        disabledLoadButton={disabledLoadButton}
                                        setDisabledLoadButton={setDisabledLoadButton}
                                        businessData={businessData}
                                    />
                                );
                            })}



                            {/*(listPdfData?.find(e => e.file?.status === 0 || e.file?.status === 1 || e.file?.status === 2) !== undefined ? true : false)*/}
                            {(statusDataAffiliate === 0 || statusDataAffiliate === 2) &&
                                <Footer
                                    size1="8"
                                    size2="4"
                                    isOpenModalSave={false}
                                    textDetail="Sube los documentos habilitantes necesarios para tu afiliación"
                                    isSubmitButton2={false}
                                    onClickButton2={HandleContinueStep}
                                    textButton2="Finalizar primera etapa"
                                    isLoadingButton2={loading.continue}
                                    disabledButton2={!continueStep}
                                />
                            }




                            {(((listPdfData?.some(e => e.file?.status === 4) && (statusDataAffiliate === 4))) || (statusDataAffiliate === 4)) &&
                                <Footer

                                    onClickButton1={GoToPanel}
                                    textButton1="Regresar al panel"
                                    textButton2=""

                                />
                            }



                        </Grid>

                    </>

                }


            </ContentStyle>
        </Fragment>
    );
}
