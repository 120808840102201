import { lazy } from 'react';
import useAuth from '../hooks/useAuth';
import Loadable from '../routes/Loadable';
import DashboardLayout from '../layouts/dashboard';
import DashboardBlank from '../layouts/utils/DashboardBlank';


//WelcomeGuard.propTypes = {
//    children: PropTypes.node
//};

export const LayoutChoosingGuard = () => {

    const { welcomeAffiliation, gateway,collect } = useAuth();
    
    if (welcomeAffiliation?.status) {
        return (<PaymentezPayType /> )
    }
    if ((gateway?.showViewEndFistStep || gateway?.showViewEndLastStep || collect?.showViewEndFistStep || collect?.showViewEndLastStep)) {
        return (<DashboardBlank />)
    }
    return <DashboardLayout />;

}


const PaymentezPayType = Loadable(lazy(() => import('../pages/PayType')));
