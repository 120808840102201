
import { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid, Card, CardHeader, Button, Typography, CardContent, Avatar, Alert, Collapse } from "@mui/material";
import { styled } from '@mui/material/styles';
import JsxParser from 'react-jsx-parser';
import documentos from "../../../../../assets/images/icons/documentos.png";
import CircularProgress from '@mui/material/CircularProgress';
import attach from "../../../../../assets/images/icons/attach.png";
import trash from "../../../../../assets/images/icons/trash.png";
import { SaveFileEnablingDocumentCollect, GetDocumentToUploadCollect } from "../../../../../actions/DocumentsAction";
import { LoadingButton } from "@mui/lab";




const CardContentStyle = {
    paddingTop: "15px",
    paddingLeft: "86px",
};


const CardStyleError = {
    minHeight: ["100%", "194px"],
    boxSizing: "border-box",
    border: "2px solid #FF4842",
    borderRadius: "14px",
};

const CardStyle = {
    minHeight: ["100%", "194px"],
};


const NameFileStyle = styled("div")(({ theme }) => ({
    color: "#26B77E",
    fontSize: "16px",
    marginBottom: "15px",
    display: "flex",
    "& span": {
        paddingLeft: "4px",
        paddingRight: "21px",
        marginTop: "-1px",
        maxWidth: "90%",
        wordWrap: "break-word",
    },
    "& img": {
        height: "20px",
        width: "20px",
    },
    "& .icondelete": {
        cursor: "pointer",
    },
}));


const fileToBase64 = (file, cb) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
        cb(null, reader.result)
    }
    reader.onerror = function (error) {
        cb(error, null)
    }
}


export const UploadFile = ({ item, statusDataAffiliate, listPdfData, setListPdfData, setDisabledLoadButton, disabledLoadButton, businessData }) => {


    const params = useParams();
    const [alert, setAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState({
        saving: false,
        downloadFile:false
    });

    const deleteFile = (e, item) => {

        const dataUpdate = listPdfData?.map((itemlist) => {
            if (itemlist?.typeDocument === item?.typeDocument) {
                return {
                    ...itemlist,
                    documentName: "",
                    documentExtension: "",
                    content: ""
                };
            } else {
                return itemlist;
            }
        });

        setListPdfData(dataUpdate);
    };



    const DownLoadFileToUpload = (documentItem) => {

        setLoading(prev => ({
            ...prev,
            downloadFile: true
        }));


        

        let objAffiliation = {
            UserId: businessData.userId,
            AffiliationDataId: params.affiliation,
            TypeDownloaded: documentItem?.typeDownloadableDocument
        }

     
        GetDocumentToUploadCollect(objAffiliation).then(response => {

            setLoading(prev => ({
                ...prev,
                downloadFile: false
            }));


            if (response.status === 200) {


                var link = document.createElement("a");
                document.body.appendChild(link);
                link.setAttribute("type", "hidden");

                /*if (fileType === "pdf") {*/
                let nameFile = "";
                if (documentItem?.typeDownloadableDocument === 1) {
                    nameFile = "CARTA-AUTORIZACION-REVISIÓN-BURO-CREDITICIO.pdf";
                }
                if (documentItem?.typeDownloadableDocument === 2) {
                    nameFile = "CARTA-AUTORIZACION-REVISIÓN-BURO-CREDITICIO-CONYUGE.pdf";
                }
                link.href = "data:text/plain;base64," + response.data;
                link.download = nameFile;
                link.click();
                document.body.removeChild(link);
              /*  }*/

            }

        });


    }

    const handleChange = (e, item) => {
        setDisabledLoadButton(true);
        setAlert(false);

        setLoading(prev => ({
            ...prev,
            saving: true
        }));

        if (e.target.files[0] !== undefined && item !== undefined) {

            if (e.target.files[0].size > 1000010) {
                setLoading(prev => ({
                    ...prev,
                    saving: false
                }));
                setDisabledLoadButton(false);
                e.target.value = null;
                setAlert(true);
                setMessage("El archivo seleccionado pesa mas de 1 MB");
                return true;
            }

            var dataName = listPdfData?.map((itemfile) => {
                if (itemfile?.documentName === e.target.files[0].name) {
                    return true;
                }
            });


            if (dataName?.some(e => e === true)) {
                setLoading(prev => ({
                    ...prev,
                    saving: false
                }));
                setDisabledLoadButton(false);
                e.target.value = null;
                setAlert(true);
                setMessage("El archivo tiene un nombre igual a los ya subidos.");
                return true;
            }



            fileToBase64(e.target.files[0], (err, result) => {

                if (result) {


                    let objFile = {
                        affiliationDataId: params.affiliation,
                        documentName: e.target.files[0].name,
                        //documentName: item?.title,
                        documentExtension: e.target.files[0].name.split('.').pop(),
                        content: result?.split(',')[1],
                        typeDocument: item?.typeDocument,
                        typePerson: item?.typePerson,
                        status: 1
                    }

                   

                    if (item?.enablingDocumentId !== undefined && item?.enablingDocumentId !== "") {
                        objFile = { ...objFile, enablingDocumentId: item?.enablingDocumentId }
                    }
                   

                    SaveFileEnablingDocumentCollect(objFile).then(response => {

                        setLoading(prev => ({
                            ...prev,
                            saving: false
                        }));


                        setDisabledLoadButton(false);
                        if (response?.status === 200) {

                            const dataUpdate = listPdfData?.map((itemlist) => {
                                if (itemlist?.typeDocument === item?.typeDocument) {
                                    return { ...itemlist, ...objFile, enablingDocumentId: response?.data?.enablingDocumentId, status: 1  };
                                } else {
                                    return itemlist;
                                }
                            });

                            setListPdfData(dataUpdate);

                        } else {
                            e.target.value = null;

                        }

                    });

                }
            })

        }
    };





    return (


        <Grid item xs={12} md={6} key={item?.title}>
            <Card sx={item?.status === 4 ? CardStyleError : CardStyle}>
                <CardHeader
                    title={
                        <Fragment>
                            <Typography
                                style={{ fontSize: "23px", fontWeight: "800" }}
                                variant="h6">
                                {item?.title}
                            </Typography>{" "}
                            <Typography>   <JsxParser jsx={item?.subtitle} /> </Typography>
                        </Fragment>
                    }
                    avatar={
                        <Avatar
                            alt="documentos"
                            src={documentos}
                            variant="square"
                            sx={{ width: "48px", height: "48px" }}
                        />
                    }
                />
                <CardContent sx={CardContentStyle}>
                    
                    {loading?.saving ?
                        <CircularProgress />
                        :

                        <>
                            {(item?.documentName !== undefined && item?.documentName !== "" && item?.documentName !== null) &&
                                <NameFileStyle>
                                    <img src={attach} alt="imagen-attach"  />

                                    <span className="textNuvei"> {item?.documentName} </span>
                                    {((statusDataAffiliate === 0 || statusDataAffiliate === 1 || statusDataAffiliate === 2) || item?.status === 4) &&
                                        <img 
                                        className="icondelete"
                                        src={trash}
                                        onClick={(e) => deleteFile(e, item)}
                                        alt="imagen-delete"
                                        />

                                    }
                                </NameFileStyle>
                            }
                       
                            {item?.typeDownloadableDocument > 0 &&
                                <LoadingButton variant="outlined" sx={{ marginBottom: '18px' }} onClick={() => DownLoadFileToUpload(item)} loading={loading?.downloadFile}> Descargar documento </LoadingButton>
                            }
                         
                            <Button variant="outlined" fullWidth component="label" disabled={((item?.documentName !== null && item?.documentName !== "") || disabledLoadButton) ? true : false}>
                                Cargar documento
                                <input

                                    type="file"
                                    hidden
                                    accept="application/pdf"
                                    onChange={(e) => { handleChange(e, item); }}

                                />
                            </Button>

                           


                            {alert && 
                                <Collapse in={alert} sx={{ marginTop: '18px' }}>
                                    <Alert severity={"error"} onClose={() => { setAlert(false); }}>

                                        {message}

                                    </Alert>
                                </Collapse>
                            }
                           



                        </>
                    }

                </CardContent>
            </Card>
        </Grid>





    );
}
