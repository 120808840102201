import HttpClient from '../services/HttpClient';
import axios from 'axios';

const instancia = axios.create();
instancia.CancelToken = axios.CancelToken;
instancia.isCancel = axios.isCancel;




export const GetIpAddress = (dispatch) => {

    return new Promise((resolve, eject) => {
        HttpClient.get("/account/getipaddress").then(response => {
            resolve(response);
        }).catch(error => {
            resolve(error.response);
        });
    });
}




export const registerUserAction = user => {
    return new Promise((resolve, eject) => {
        instancia.post("/account/register", user).then(response => {
            resolve(response);
        }).catch(error => {
           
            resolve(error.response);
        });
    })
}



export const login = async (email) =>   {

    return new Promise((resolve, eject) => {
        instancia.post("/account/login", email).then(response => {
           
            resolve(response);
        }).catch(error => {
            resolve(error.response);
        });
    })
}


export const logincode = async (emailCode) => {
    return new Promise((resolve, eject) => {

        instancia.post("/account/logincode", emailCode).then(response => {
           
            resolve(response);
        }).catch(error => {
            resolve(error.response);
        });
    })
}




export const GetCurrentUser = (dispatch) => {

    return new Promise((resolve, eject) => {
        HttpClient.get("/account").then(response => {
            resolve(response);
        }).catch(error => {
            //console.log("error",error);
            resolve(error.response);
        });
    });
}



export const ChangeStatusUser = (obj) => {

    return new Promise((resolve, eject) => {
        HttpClient.post("/user/ChangeStatusUser", obj).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}


export const GetListAllLoginCode = (obj) => {

    return new Promise((resolve, eject) => {
        HttpClient.post("/account/GetListAllLoginCode", obj).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}





