import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { PATH_PAYMENTEZ} from '../routes/paths';
import Global from '../Global';

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default function GuestGuard({ children }) {
  const { isAuthenticated,user } = useAuth();

    if (isAuthenticated) {

        const rol = user?.rol?.toUpperCase();

        if (rol === Global.allRoles.find(e => e === "AFFILIATE")) {
            return <Navigate to={PATH_PAYMENTEZ.root} />;
        }
    
        if (rol === Global.allRoles.find(e => e === "ADVISOR")) {
            return <Navigate to={PATH_PAYMENTEZ.rootAdvisor}  />;
        }
        
        if (rol === Global.allRoles.find(e => e === "SUPERVISOR")) {
            return <Navigate to={PATH_PAYMENTEZ.rootAdvisor} />;
        }

        if (rol === Global.allRoles.find(e => e === "ADMINISTRATOR")) {
            return <Navigate to={PATH_PAYMENTEZ.rootAdvisor} />;
        }

        if (rol === Global.allRoles.find(e => e === "MONEYGUARDADMINISTRATOR") || rol === Global.allRoles.find(e => e === "MONEYGUARD") ) {
            return <Navigate to={PATH_PAYMENTEZ.rootMoneyGuard} />;
        }

        if (rol === Global.allRoles.find(e => e === "FINANCIAL")) {
            return <Navigate to={PATH_PAYMENTEZ.rootFinancial} />;
        }

        if (rol === Global.allRoles.find(e => e === "TECHNICIAN") || rol === Global.allRoles.find(e => e === "TECHNICIANADMINISTRATOR") ) {
            return <Navigate to={PATH_PAYMENTEZ.rootTechnician} />;
        }
       

      
  }

  return <>{children}</>;
}
