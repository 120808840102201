import { v4 as uuidv4 } from 'uuid';
// utils
import fakeRequest from '../utils/fakeRequest';
import { verify, sign } from '../utils/jwt';
//
import mock from './mock';

// ----------------------------------------------------------------------

const JWT_SECRET = 'minimal-secret-key';
const JWT_EXPIRES_IN = '5 days';

const users = [
  {
    id: '8864c717-587d-472a-929a-8e5f298024da-0',
    displayName: 'Jaydon Frankie',
    email: 'demo@minimals.cc',
    password: 'demo1234',
    photoURL: '/static/mock-images/avatars/avatar_default.jpg',
    phoneNumber: '+40 777666555',
    country: 'United States',
    address: '90210 Broadway Blvd',
    state: 'California',
    city: 'San Francisco',
    zipCode: '94116',
    about: 'Praesent turpis. Phasellus viverra nulla ut metus varius laoreet. Phasellus tempus.',
    role: 'admin',
    isPublic: true
    },
    {
        id: '8864c717-587d-472a-929a-8e5f29802587-0',
        displayName: 'Luis Carriel',
        email: 'lcarriel@imaget.com',
        password: 'demo1234',
        photoURL: '/static/mock-images/avatars/avatar_default.jpg',
        phoneNumber: '+40 777666555',
        country: 'United States',
        address: '90210 Broadway Blvd',
        state: 'California',
        city: 'San Francisco',
        zipCode: '94116',
        about: 'Praesent turpis. Phasellus viverra nulla ut metus varius laoreet. Phasellus tempus.',
        role: 'admin',
        isPublic: true
    }
];



const usersCodeLogin = [
    { email: 'demo@minimals.cc',code:'123456' },
    { email: 'lcarriel@imaget.com', code: '123456' },
];


// ----------------------------------------------------------------------

mock.onPost('/api/account/checkemail').reply(async (config) => {

    try {
        await fakeRequest(1000);

        const { email } = JSON.parse(config.data);
       

        const user = users.find((_user) => _user.email === email);

        if (!user) {
            return [400, { message: 'There is no user corresponding to the email address.' }];
        }

        return [200, { "response": 1 }];

    } catch (error) {
        
        return [500, { message: 'Internal server error' }];
    }
});



// ----------------------------------------------------------------------

mock.onPost('/api/account/login').reply(async (config) => {

  try {
    await fakeRequest(1000);

      const { email, code } = JSON.parse(config.data);

     
      const getuser = users.find((_user) => _user.email === email);
      const user = usersCodeLogin.find((_user) => _user.email === email);
     

    if (!user) {
      return [400, { message: 'There is no user corresponding to the email address.' }];
      }


      if (user.code !== code) {
          return [400, { message: 'Code doesnt match. Try again' }];
      }

      const accessToken = sign({ userId: getuser.id }, JWT_SECRET, {
      expiresIn: JWT_EXPIRES_IN
    });

      return [200, { accessToken, user:getuser }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});

// ----------------------------------------------------------------------


mock.onPost('/api/account/resendcode').reply(async (config) => {

    try {
        await fakeRequest(1000);
      
        const { email } = JSON.parse(config.data);
  

        const user = users.find((_user) => _user.email === email);
       

        if (!user) {
            return [400, { message: 'There is no user corresponding to the email address.' }];
        }


      
   

        return [200, { "response": 1 }];
    } catch (error) {
        console.error(error);
        return [500, { message: 'Internal server error' }];
    }
});


// ----------------------------------------------------------------------

mock.onPost('/api/account/register').reply(async (config) => {
  try {
    await fakeRequest(1000);

    const { email, password, firstName, lastName } = JSON.parse(config.data);
    let user = users.find((_user) => _user.email === email);

    if (user) {
      return [400, { message: 'There already exists an account with the given email address.' }];
    }

    user = {
      id: uuidv4(),
      displayName: `${firstName} ${lastName}`,
      email,
      password,
      photoURL: null,
      phoneNumber: null,
      country: null,
      address: null,
      state: null,
      city: null,
      zipCode: null,
      about: null,
      role: 'user',
      isPublic: true
    };

    const accessToken = sign({ userId: user.id }, JWT_SECRET, {
      expiresIn: JWT_EXPIRES_IN
    });

    return [200, { accessToken, user }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});

// ----------------------------------------------------------------------

mock.onGet('/api/account/my-account').reply((config) => {
  try {
    const { Authorization } = config.headers;

    if (!Authorization) {
      return [401, { message: 'Authorization token missing' }];
    }

    const accessToken = Authorization.split(' ')[1];
    const data = verify(accessToken, JWT_SECRET);
    const userId = typeof data === 'object' ? data?.userId : '';
    const user = users.find((_user) => _user.id === userId);

    if (!user) {
      return [401, { message: 'Invalid authorization token' }];
    }

    return [200, { user }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});
