import PropTypes from "prop-types";
import { Fragment } from "react";
import { Link as RouterLink} from "react-router-dom";
import { alpha, styled } from "@mui/material/styles";
import {
    Box,
    Link,
    Stack,
    Avatar,
    Drawer,
    Tooltip,
    Typography,
    CardActionArea,
} from "@mui/material";
import useCollapseDrawer from "../../hooks/useCollapseDrawer";
import Logo from "../../components/Logo";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
import { MHidden } from "../../components/@material-extend";
import { useSideBarNuvei } from "../../hooks/useSideBarNuvei";
import SvgIconStyle from "../../components/SvgIconStyle";
import Global from '../../Global';


const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const RootStyle = styled("div")(({ theme }) => ({
    [theme.breakpoints.up("lg")]: {
        flexShrink: 0,
        transition: theme.transitions.create("width", {
            duration: theme.transitions.duration.complex,
        }),
    },
}));

const AccountStyle = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2, 2.5),
    borderRadius: theme.shape.borderRadiusSm,
    backgroundColor: "#EDECF7",

    /*backgroundColor: theme.palette.grey[500_12]*/
}));


IconCollapse.propTypes = {
    onToggleCollapse: PropTypes.func,
    collapseClick: PropTypes.bool,
};

function IconCollapse({ onToggleCollapse, collapseClick }) {
    return (
        <Tooltip title="Mini Menu">
            <CardActionArea
                onClick={onToggleCollapse}
                sx={{
                    width: 18,
                    height: 18,
                    display: "flex",
                    cursor: "pointer",
                    borderRadius: "50%",
                    alignItems: "center",
                    color: "text.primary",
                    justifyContent: "center",
                    border: "solid 1px currentColor",
                    ...(collapseClick && {
                        borderWidth: 2,
                    }),
                }}
            >
                <Box
                    sx={{
                        width: 8,
                        height: 8,
                        borderRadius: "50%",
                        bgcolor: "currentColor",
                        transition: (theme) => theme.transitions.create("all"),
                        ...(collapseClick && {
                            width: 0,
                            height: 0,
                        }),
                    }}
                />
            </CardActionArea>
        </Tooltip>
    );
}

DashboardSidebar.propTypes = {
    isOpenSidebar: PropTypes.bool,
    onCloseSidebar: PropTypes.func,
};




export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
   

    const { sidebarCustomPaymentez, businessData, userRol } = useSideBarNuvei({ isOpenSidebar, onCloseSidebar });

    const {
        isCollapse,
        collapseClick,
        collapseHover,
        onToggleCollapse,
        onHoverEnter,
        onHoverLeave,
    } = useCollapseDrawer();

    

    const renderContent = (
        <Scrollbar
            sx={{
                height: "100%",
                "& .simplebar-content": {
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                },
            }}
        >
                <Stack
                    spacing={3}
                    sx={{
                        px: 2.5,
                        pt: 3,
                        pb: 2,
                        ...(isCollapse && {
                            alignItems: "center",
                        }),
                    }}
                >
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Box component={RouterLink} to="/" sx={{ display: "inline-flex" }}>
                            <Logo />
                        </Box>

                        <MHidden width="lgDown">
                            {!isCollapse && (
                                <IconCollapse
                                    onToggleCollapse={onToggleCollapse}
                                    collapseClick={collapseClick}
                                />
                            )}
                        </MHidden>
                    </Stack>


                {(userRol === Global.allRoles.find(e => e === "AFFILIATE")) &&
                    <Fragment>
                        {isCollapse ? (
                            <Avatar
                                alt="My Avatar"
                                src="/static/mock-images/avatars/avatar_default.jpg"
                                sx={{ mx: "auto", mb: 2 }}
                            />
                        ) : (
                            <Link
                                underline="none"
                                component={RouterLink}
                                to="#"
                                sx={{ marginTop: "40px !important" }}
                            >
                                <AccountStyle>
                                    <Avatar
                                        alt="My Avatar"
                                        src={(businessData?.assesorImage !== undefined && businessData?.assesorImage !== "" && businessData?.assesorImage !== null) ? "data:image/png;base64,"+ businessData?.assesorImage : "/static/mock-images/avatars/avatar_default.jpg"}
                                    />
                                    <Box sx={{ ml: 2 }}>
                                        <Typography
                                            variant="body2"
                                            sx={{ color: "text.primary", marginBottom: "-3px" }}
                                        >
                                            Mi asesor
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            sx={{ color: "text.primary", marginBottom: "-3px" }}
                                            mb={0}
                                        >
                                            {businessData?.assessorAssigned}{" "}
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: "text.secondary", fontSize: '13px', }}>
                                            {businessData?.assesorEmail}

                                        </Typography>

                                    </Box>
                                </AccountStyle>
                            </Link>
                        )}

                    </Fragment>
                }
                 
                </Stack>


            {sidebarCustomPaymentez.length > 0 && (
                <NavSection navConfig={sidebarCustomPaymentez} isShow={!isCollapse} />
            )}
        </Scrollbar>
    );

    return (
        <RootStyle
            className="sliderPaymentez"
            sx={{
                width: {
                    lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH,
                },
                ...(collapseClick && {
                    position: "absolute",
                }),
            }}
        >

            
            <MHidden width="lgUp">
                <Drawer
                    open={isOpenSidebar}
                    onClose={onCloseSidebar}
                    PaperProps={{
                        sx: { width: DRAWER_WIDTH },
                    }}
                >
                    {renderContent}
                </Drawer>
            </MHidden>

            <MHidden width="lgDown">
                <Drawer
                    open
                    variant="persistent"
                    onMouseEnter={onHoverEnter}
                    onMouseLeave={onHoverLeave}
                    PaperProps={{
                        sx: {
                            width: DRAWER_WIDTH,
                            bgcolor: "background.default",
                            ...(isCollapse && {
                                width: COLLAPSE_WIDTH,
                            }),
                            ...(collapseHover && {
                                borderRight: 0,
                                backdropFilter: "blur(6px)",
                                WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
                                boxShadow: (theme) => theme.customShadows.z20,
                                bgcolor: (theme) =>
                                    alpha(theme.palette.background.default, 0.88),
                            }),
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            </MHidden>
        </RootStyle>
    );
}
