import { Navigate, useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import { PATH_PAYMENTEZ } from "../routes/paths";
import { Outlet } from 'react-router-dom';

StepsAggregatorGuard.propTypes = {
    children: PropTypes.node
};

const Step1IsDisabledCollect = (status) => {

    if (status === 1 || status > 3) {

        return false;
    }
    return true
}

const Step2IsDisabledCollect = (step2, step3) => {

    if (step2 >= 5 && step3?.statusAffiliationCollectMoneyGuard === 5) {

        return false;
    }
    return true;
}

const Step3IsDisabledCollect = (status) => {

    if (status === 1 || status > 3) {

        return false;
    }
    return true;
}




export default function StepsAggregatorGuard() {
    const { businessData } = useAuth();
    const params = useParams();
    const { pathname } = useLocation();


    const getAffiliationInfo = businessData?.affiliationData.find(e => e.affiliationDataId === params.affiliation && e.typeAffiliation === 2);
   
    if (getAffiliationInfo !== undefined) {

    
        var statusAffiliation = getAffiliationInfo.statusAffiliateCollect;

     
        var statusAffiliationMoneyGuard = getAffiliationInfo;

        var step1Status = getAffiliationInfo.stepStatusCollect?.find(e => e.step === 1)?.status;
        var step2Status = getAffiliationInfo.stepStatusCollect?.find(e => e.step === 2)?.status;
       
     
        let urlEstablishmentData = PATH_PAYMENTEZ.app.collect.establishmentData + params.affiliation;
        let urlEnablingDocuments = PATH_PAYMENTEZ.app.collect.enablingDocuments + params.affiliation;
        let urlPmzDocuments = PATH_PAYMENTEZ.app.collect.paymentezDocuments + params.affiliation;
        let urlPanel = PATH_PAYMENTEZ.app.collect.monitoringPanel + params.affiliation;
        let urlStateDocument = PATH_PAYMENTEZ.app.collect.monitoringStateDocument + params.affiliation;
        let urlResume = PATH_PAYMENTEZ.app.collect.resume + params.affiliation;
        const PzmPaths = [
            urlEstablishmentData,
            urlEnablingDocuments,
            urlPmzDocuments,
            //urlPanel,
            urlStateDocument,
        ];

        const isMatchingPath = PzmPaths.some(path => path === pathname);
        if (isMatchingPath && statusAffiliation === 11) {
            return <Navigate to={PATH_PAYMENTEZ.app.pageAllAffiliations} />;
        }

      
        if (pathname === urlEnablingDocuments && Step1IsDisabledCollect(step1Status) && statusAffiliation < 11) {
            return <Navigate to={PATH_PAYMENTEZ.app.pageAllAffiliations} />;
        }
     
        if (pathname === urlPmzDocuments && (Step1IsDisabledCollect(step1Status) && Step2IsDisabledCollect(step2Status, statusAffiliationMoneyGuard)) && statusAffiliation < 11) {
            return <Navigate to={PATH_PAYMENTEZ.app.pageAllAffiliations} />;
        }

        if (pathname === urlResume && statusAffiliation < 11) {
            return <Navigate to={PATH_PAYMENTEZ.app.pageAllAffiliations} />;
        }

    } else {
        return <Navigate to={PATH_PAYMENTEZ.app.pageAllAffiliations} />;

    }

    return <Outlet />;
}
