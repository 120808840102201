import HttpClient from '../services/HttpClient';
import axios from 'axios';

const instancia = axios.create();
instancia.CancelToken = axios.CancelToken;
instancia.isCancel = axios.isCancel;




export const GetLogApp = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/log/GetLogApp", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}








