import {  useState, useEffect } from "react";
import {  useParams} from "react-router-dom";
import { Box, Grid, Card, Accordion, AccordionSummary, AccordionDetails, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import useAuth from "../../../hooks/useAuth";
import { styled } from '@mui/material/styles';
import LoadingScreen from '../../../components/LoadingScreen';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import arrowDown from '@iconify/icons-eva/arrow-downward-fill';
import { Icon } from '@iconify/react';
import Label from '../../Label';

const ContentStyle = styled('div')(({ theme }) => ({
    
}));


export default function ContentStatusStep(props) {

    const { businessData, } = useAuth();
    const params = useParams();
   
    
    const [loading, setLoading] = useState({
        initial: false,
    });
   
  
  
    const [data, setData] = useState({
        initial: [],
        listSendFile: []
    });


    const loadingInitialData = () => {
        setLoading(prev => ({
            ...prev,
            initial: true,
        }));

        setData(prev => ({
            ...prev,
            initial: [
                {
                    company: "Paymentez",
                    prefix: "paymentez",
                    status: "Por completar",
                    documents: [
                        {
                            id: '1', name: "Acuerdo de servicios tecnologicos", file: '',
                        }
                    ]
                },
                {
                    company: "Banco del Pacífico",
                    prefix: "pacifico",
                    status: "Por completar",
                    documents: [
                        { id: '2', name: "Anexo 1", file: '' },
                        { id: '3', name: "Anexo 2", file: '' },
                        { id: '4', name: "Carta de adquirencia", file: '' },
                        { id: '5', name: "Inspeccion Fisica", file: '' },
                        { id: '6', name: "Vinculacion", file: '' }
                    ]
                }
            ],
        }));


        setLoading(prev => ({
            ...prev,
            initial: false,
        }));


    }





    useEffect(() => {
      

    }, []);


    return (

        <ContentStyle>
            

            {loading?.initial
                ?
                <Grid container mt={12} mb={12}>
                    <Grid item xs={12} md={12}>
                        <LoadingScreen />
                    </Grid>
                </Grid>

                :
                <Grid container mt={3}>

                 

                     
                       
                                <Grid item xs={12} md={12}  >
                                    <Card>
                                        <Accordion  disabled={false}>
                                            <AccordionSummary expandIcon={<Icon icon={arrowIosDownwardFill} width={40} height={40} />} sx={{ padding: "12px 20px" }}>
                                                <Grid container sx={{ padding: "0px" }}>
                                                    <Grid item xs={12} md={8}>
                                                        {/*<CardHeader title={`Documentación ${item.company}`} avatar={*/}
                                                        {/*    item.prefix === "paymentez"*/}
                                                        {/*        ?*/}
                                                        {/*        <img src={require(`../../../../assets/images/icons/logo-paymentez.png`).default} style={{ height: '16px', marginRight: "31px" }} />*/}
                                                        {/*        :*/}
                                                        {/*        <img src={require(`../../../../assets/images/banks/logo-banco-${item?.prefix}.png`).default} style={{ height: '41px', marginRight: "15px" }} />*/}
                                                        {/*} sx={{ padding: "0px" }} />*/}

                                                    </Grid>
                                                    <Grid item xs={12} md={4} sx={{ textAlign: "right", paddingRight: '25px' }}>
                                                        <Label
                                                            variant={'filled'}
                                                            color={'warning'}>
                                                            Por completar
                                                        </Label>
                                                    </Grid>
                                                </Grid>

                                            </AccordionSummary>
                                            <AccordionDetails>

                                                <TableContainer >
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>
                                                                    <span>Documentos por firmar</span>
                                                                    <Icon icon={arrowDown} width={17} height={17} style={{ verticalAlign: 'inherit', marginLeft: "4px" }} />
                                                                </TableCell>
                                                                <TableCell>Archivo subido</TableCell>
                                                                <TableCell>Fecha</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>


                                                            {/*{item?.documents.map((document) => {*/}
                                                            {/*    return (*/}

                                                            {/*        <TableRow>*/}
                                                            {/*            <TableCell>*/}
                                                            {/*                <div>*/}
                                                            {/*                    <img src={documenticon} width="20" heigth="20" style={{ float: 'left' }} />*/}
                                                            {/*                    <span style={{ paddingLeft: "5px" }}><b>{document.name}</b></span>*/}
                                                            {/*                </div>*/}
                                                            {/*                {document.file?.name === "" &&*/}
                                                            {/*                    <div style={{ marginTop: "15px" }}>*/}
                                                            {/*                        <Button variant="outlined">*/}
                                                            {/*                            Descarga para firmar*/}
                                                            {/*                        </Button>*/}
                                                            {/*                    </div>*/}
                                                            {/*                }*/}
                                                            {/*            </TableCell>*/}

                                                            {/*            <TableCell>*/}


                                                            {/*                {document?.file !== undefined && document?.file?.name !== ""*/}

                                                            {/*                    ?*/}
                                                            {/*                    <NameFileStyle>*/}
                                                            {/*                        <img src={documenticon} />*/}

                                                            {/*                        <span> {document?.file?.name} </span>*/}
                                                            {/*                        <img className="icondelete" src={trash} onClick={(e) => deleteFile("test")} />*/}
                                                            {/*                    </NameFileStyle>*/}

                                                            {/*                    :*/}
                                                            {/*                    <Button variant="contained" component="label" >*/}
                                                            {/*                        Sube archivo firmado*/}
                                                            {/*                        <input type="file" hidden accept="application/pdf" onChange={(e) => GetFile(e, item.company, document.name)} />*/}
                                                            {/*                    </Button>*/}

                                                            {/*                }*/}


                                                            {/*            </TableCell>*/}
                                                            {/*            <TableCell>Subido el 2021/10/21</TableCell>*/}
                                                            {/*        </TableRow>*/}

                                                            {/*    )*/}
                                                            {/*})*/}

                                                            {/*}*/}


                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Card>


                                </Grid>
                            
                  

                      
                
                </Grid>

            }

        </ContentStyle>


   
   

    );
}
