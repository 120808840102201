import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import useAuth from './useAuth';
import { PATH_PAYMENTEZ } from "../routes/paths";
import SvgIconStyle from "../components/SvgIconStyle";



const getIcon = (name) => (
    <SvgIconStyle
        src={`/static/icons/navbar/${name}.svg`}
        sx={{ width: "100%", height: "100%" }}
    />
);


const ICONS = {
    user: getIcon("ic_user"),
    ecommerce: getIcon("ic_ecommerce"),
    analytics: getIcon("ic_analytics"),
    dashboard: getIcon("ic_dashboard"),
    booking: getIcon("ic_booking"),
    question: getIcon("ic_question"),
    register: getIcon("ic_register"),
    asesores: getIcon("ic_asesores"),
    codes: getIcon("ic_codigos"),
    business: getIcon("ic_comercios"),
    createUser: getIcon("ic_crear_usuarios"),
    log: getIcon("ic_log"),
    profile: getIcon("ic_perfil"),
    review: getIcon("ic_aprobar"),
    request: getIcon("ic_solicitudes"),
    assign: getIcon("ic_assign"),
};


const Step1IsDisabled = (status) => {

    if (status === 1 || status > 3) {

        return false;
    }
    return true
}

const Step2IsDisabled = (status) => {

    if (status === 1 || status > 3) {

        return false;
    }
    return true;
}

const Step3IsDisabled = (status) => {

    if (status === 1 || status > 3) {

        return false;
    }
    return true;
}

const validateStep3=(data={}) =>{

    const { isReferralMedianet,activeBankDatafastInReferralMedianet,step1Status,step2Status,step3Status } = data;

    if(!isReferralMedianet){

       return (Step2IsDisabled(step2Status) || Step1IsDisabled(step1Status) || Step3IsDisabled(step3Status))

    }

    if(isReferralMedianet && activeBankDatafastInReferralMedianet){

        return (Step2IsDisabled(step2Status) || Step1IsDisabled(step1Status) || Step3IsDisabled(step3Status))
 
     }


    return true;
}


const SectionGateway = (props) => [
    {
        title: "Panel de seguimiento",
        path:
            PATH_PAYMENTEZ.app.gateway.monitoringPanel +
            props.affiliationDataId,
        icon: ICONS.dashboard,
        isDisabled: false,
    },
    {
        title: "Datos del establecimiento",
        path:
            PATH_PAYMENTEZ.app.gateway.establishmentData +
            props.affiliationDataId,
        icon: ICONS.dashboard,
        isDisabled: false
    },
    {
        title: "Documentos habilitantes",
        path:
            PATH_PAYMENTEZ.app.gateway.enablingDocuments +
            props.affiliationDataId,
        icon: ICONS.dashboard,
        isDisabled: Step1IsDisabled(props?.step1Status),
    },
    {
        title: "Documentación Nuvei",
        path:
            PATH_PAYMENTEZ.app.gateway.paymentezDocuments +
            props.affiliationDataId,
        icon: ICONS.dashboard,
        isDisabled: (Step1IsDisabled(props?.step1Status) || Step2IsDisabled(props?.step2Status)),
    },
    {
        title: "Documentación Bancos",
        path:
            PATH_PAYMENTEZ.app.gateway.banksDocuments +
            props.affiliationDataId,
        icon: ICONS.dashboard,
        isDisabled: validateStep3(props)
       
    },
    {
        title: "Notificaciones",
        path:
            PATH_PAYMENTEZ.app.gateway.notification +
            props.affiliationDataId,
        icon: ICONS.dashboard,
    },
];



const Step1IsDisabledCollect = (status) => {

    if (status === 1 || status > 3) {

        return false;
    }
    return true
}

const Step2IsDisabledCollect = (step2, step3) => {

    if (step2 >= 5 && step3?.statusAffiliationCollectMoneyGuard === 5) {

        return false;
    }
    return true;
}

const Step3IsDisabledCollect = (status) => {

    if (status === 1 || status > 3) {

        return false;
    }
    return true;
}



const SectionCollect = (props) => [
    {
        title: "Panel de seguimiento",
        path:
            PATH_PAYMENTEZ.app.collect.monitoringPanel +
            props.affiliationDataId,
        icon: ICONS.dashboard,
        isDisabled: false,
    },
    {
        title: "Datos del establecimiento",
        path:
            PATH_PAYMENTEZ.app.collect.establishmentData +
            props.affiliationDataId,
        icon: ICONS.dashboard,
        isDisabled: false
    },
    {
        title: "Documentos habilitantes",
        path:
            PATH_PAYMENTEZ.app.collect.enablingDocuments +
            props.affiliationDataId,
        icon: ICONS.dashboard,
        isDisabled: Step1IsDisabledCollect(props?.step1Status),
    },
    {
        title: "Documentación Nuvei",
        path:
            PATH_PAYMENTEZ.app.collect.paymentezDocuments +
            props.affiliationDataId,
        icon: ICONS.dashboard,
        isDisabled: Step2IsDisabledCollect(props?.step2Status, props?.step3Status),
    },
    {
        title: "Notificaciones",
        path:
            PATH_PAYMENTEZ.app.collect.notification +
            props.affiliationDataId,
        icon: ICONS.dashboard,
    },
];


export const useSideBarNuvei = ({ isOpenSidebar, onCloseSidebar }) => {

    const { pathname } = useLocation();
    const { businessData, user } = useAuth();
    const userRol = user?.rol?.toUpperCase();

    const [sidebarCustomPaymentez, setSidebarCustomPaymentez] = useState([]);




    const sidebarAffiliate = () => {

        if (businessData?.affiliationData?.length > 0) {


            var elementData = [
                {
                    subheader: "Mis Afiliaciones",
                    items: [
                        {
                            title: "Todas mis afiliaciones",
                            path: "/app/mis-afiliaciones",
                            icon: ICONS.dashboard,
                        },
                    ],
                },
                {
                    subheader: "Ayuda",
                    items: [


                        {
                            title: "Preguntas frecuentes",
                            path: PATH_PAYMENTEZ.app.frequentQuestion,
                            icon: ICONS.request,
                        }
                    ],
                },
            ];

            let ThereIsGatewayAffiliation = businessData?.affiliationData.some(e => e.typeAffiliation === 1);
            if (ThereIsGatewayAffiliation) {
                elementData[1].items.unshift({
                    title: "Documentación adicional que piden los bancos",
                    path: PATH_PAYMENTEZ.app.adicionalDocumentation,
                    icon: ICONS.request,
                });
            }


            for (var i = 0; i < businessData?.affiliationData?.length; i++) {

                let afiliationInfo = businessData?.affiliationData[i];
                let typeAffiliation = afiliationInfo?.typeAffiliation;

                let statusAffiliate = afiliationInfo?.statusAffiliate; //Gateway
                let statusAffiliateCollect = afiliationInfo?.statusAffiliateCollect; //Collect

                if (statusAffiliate === 7 || statusAffiliateCollect === 11) {
                    let type = typeAffiliation === 1 ? "gateway" : "collect";
                    let pathtext = "/" + type + "/" + pathname?.split("/")[2] + "/" + businessData?.affiliationData[i].affiliationDataId;

                    elementData[0].items.push({
                        title: businessData?.affiliationData[i]?.tradeName,
                        path: pathtext,
                        icon: ICONS.business,
                        children: [
                            {
                                title: "Resumen",
                                path:
                                    (typeAffiliation === 1 ? PATH_PAYMENTEZ.app.gateway.resume : PATH_PAYMENTEZ.app.collect.resume) +
                                    businessData?.affiliationData[i].affiliationDataId,
                                icon: ICONS.dashboard,
                            },
                            {
                                title: "Notificaciones",
                                path:
                                    (typeAffiliation === 1 ? PATH_PAYMENTEZ.app.gateway.notification : PATH_PAYMENTEZ.app.collect.notification) +
                                    businessData?.affiliationData[i].affiliationDataId,
                                icon: ICONS.dashboard,
                            },
                        ]
                    });

                }

                if (typeAffiliation === 1 && statusAffiliate < 7) {

                    let step1Status = afiliationInfo?.stepStatus?.find(e => e.step === 1)?.status;
                    let step2Status = afiliationInfo?.stepStatus?.find(e => e.step === 2)?.status;
                    let step3Status = afiliationInfo?.stepStatus?.find(e => e.step === 3)?.status;

                    let pathtext = "/app/gateway/" + pathname?.split("/")[2] + "/" + afiliationInfo?.affiliationDataId;
                    let tradeName = afiliationInfo?.tradeName?.length <= 34 ? afiliationInfo?.tradeName : afiliationInfo?.tradeName?.substr(0, 34) + "...";


                    elementData[0]?.items.push({
                        title: tradeName
                            ? tradeName
                            : businessData?.businessName,
                        path: pathtext,
                        icon: ICONS.business,
                        children: SectionGateway(
                            {
                                affiliationDataId: afiliationInfo?.affiliationDataId,
                                isReferralMedianet: afiliationInfo?.isReferralMedianet,
                                activeBankDatafastInReferralMedianet: afiliationInfo?.activeBankDatafastInReferralMedianet,
                                step1Status, step2Status, step3Status
                            }
                        )
                    });
                }


                if (typeAffiliation === 2 && statusAffiliateCollect < 11) {

                    let step1Status = afiliationInfo?.stepStatusCollect?.find(e => e.step === 1)?.status;
                    let step2Status = afiliationInfo?.stepStatusCollect?.find(e => e.step === 2)?.status;
                    let step3Status = afiliationInfo?.stepStatusCollect?.find(e => e.step === 3);




                    let pathtext = "/app/agregador/" + pathname?.split("/")[2] + "/" + afiliationInfo?.affiliationDataId;
                    let tradeName = afiliationInfo?.tradeName?.length <= 34 ? afiliationInfo?.tradeName : afiliationInfo?.tradeName?.substr(0, 34) + "...";


                    elementData[0]?.items.push({
                        title: tradeName
                            ? tradeName
                            : businessData?.businessName,
                        path: pathtext,
                        icon: ICONS.business,
                        children: SectionCollect({ affiliationDataId: afiliationInfo?.affiliationDataId, step1Status, step2Status, step3Status })
                    });
                }



            }

            setSidebarCustomPaymentez(elementData);
        }

    }



    const sidebarAdvisor = () => {

        var elementData = [
            {
                subheader: "AFILIACIONES",
                items: [
                    {
                        title: "Inicio",
                        path: PATH_PAYMENTEZ.advisor.home,
                        icon: ICONS.analytics,
                    },
                    {
                        title: "Registra un comercio",
                        path: PATH_PAYMENTEZ.advisor.registerCommerce,
                        icon: ICONS.register,
                    },
                    {
                        title: "Mis comercios",
                        path: PATH_PAYMENTEZ.advisor.myCommerces,
                        icon: ICONS.business,
                    },
                    {
                        title: "Documentación por aprobar",
                        path: PATH_PAYMENTEZ.advisor.approveDocumentation,
                        icon: ICONS.review,
                    },
                    {
                        title: "Asignar analista de riesgo",
                        path: PATH_PAYMENTEZ.advisor.assignMoneyGuard,
                        icon: ICONS.assign,
                    },
                    {
                        title: "Notificaciones",
                        path: PATH_PAYMENTEZ.advisor.notification,
                        icon: ICONS.dashboard,
                    }
                ]
            },
            {
                subheader: "SOLICITUDES DEL COMERCIO",
                items: [
                    {
                        title: "Ver solicitudes",
                        path: PATH_PAYMENTEZ.advisor.viewRequests,
                        icon: ICONS.request,
                    }
                ]
            },
            {
                subheader: "Administración",
                items: [
                    {
                        title: "Perfil",
                        path: PATH_PAYMENTEZ.advisor.profile,
                        icon: ICONS.profile,
                    },
                    {
                        title: "Log",
                        path: PATH_PAYMENTEZ.advisor.log,
                        icon: ICONS.log,
                    },
                    {
                        title: "Codigos",
                        path: PATH_PAYMENTEZ.advisor.listCode,
                        icon: ICONS.codes,
                    }
                ]
            },


        ];


        if (userRol === "SUPERVISOR" || userRol === "ADMINISTRATOR") {

            elementData[2]?.items?.push(
                {
                    title: "Asesores",
                    path: PATH_PAYMENTEZ.general.listAdvisor,
                    icon: ICONS.asesores,
                },
                {
                    title: "Crear Usuario",
                    path: PATH_PAYMENTEZ.general.createUser,
                    icon: ICONS.createUser,
                }

            );

        }


        setSidebarCustomPaymentez(elementData);

    }

    const sidebarMoneyGuard = () => {

        var elementData = [
            {
                subheader: "PROTECCIÓN DE DINERO",
                items: [
                    {
                        title: "Inicio",
                        path: PATH_PAYMENTEZ.moneyGuard.home,
                        icon: ICONS.analytics,
                    },
                    {
                        title: "Mis comercios",
                        path: PATH_PAYMENTEZ.moneyGuard.myCommerces,
                        icon: ICONS.business,
                    },
                    {
                        title: "Documentación por aprobar",
                        path: PATH_PAYMENTEZ.moneyGuard.approveDocumentation,
                        icon: ICONS.review,
                    },

                    {
                        title: "Notificaciones",
                        path: PATH_PAYMENTEZ.moneyGuard.notification,
                        icon: ICONS.dashboard,
                    }

                ]
            },
            {
                subheader: "Administración",
                items: [
                    {
                        title: "Perfil",
                        path: PATH_PAYMENTEZ.moneyGuard.profile,
                        icon: ICONS.profile,
                    },
                    //{
                    //    title: "Log",
                    //    path: PATH_PAYMENTEZ.advisor.log,
                    //    icon: ICONS.log,
                    //},
                    //{
                    //    title: "Codigos",
                    //    path: PATH_PAYMENTEZ.advisor.listCode,
                    //    icon: ICONS.codes,
                    //}
                ]
            },

        ];


        if (userRol === "MONEYGUARDADMINISTRATOR") {

            elementData[1]?.items?.push(
                {
                    title: "Analistas de riesgo",
                    path: PATH_PAYMENTEZ.general.listAdvisor,
                    icon: ICONS.asesores,
                },
                {
                    title: "Crear Usuario",
                    path: PATH_PAYMENTEZ.general.createUser,
                    icon: ICONS.createUser,
                }

            );

        }
        setSidebarCustomPaymentez(elementData);

    }

    const sidebarTechnician = () => {

        var elementData = [
            {
                subheader: "AREA TÉCNICA",
                items: [
                    {
                        title: "Inicio",
                        path: PATH_PAYMENTEZ.technician.home,
                        icon: ICONS.analytics,
                    },
                    {
                        title: "Mis comercios",
                        path: PATH_PAYMENTEZ.technician.myCommerces,
                        icon: ICONS.business,
                    },
                    {
                        title: "Comercios por revisar",
                        path: PATH_PAYMENTEZ.technician.reviewingBusiness,
                        icon: ICONS.review,
                    },
                   

                    //{
                    //    title: "Crear usuarios",
                    //    path: PATH_PAYMENTEZ.technician.listUser,
                    //    icon: ICONS.register,
                    //},

                ]
            },
            {
                subheader: "Administración",
                items: [
                    {
                        title: "Perfil",
                        path: PATH_PAYMENTEZ.technician.profile,
                        icon: ICONS.profile,
                    },
                    {
                        title: "Log",
                        path: PATH_PAYMENTEZ.technician.log,
                        icon: ICONS.log,
                    },
                    //{
                    //    title: "Codigos",
                    //    path: PATH_PAYMENTEZ.advisor.listCode,
                    //    icon: ICONS.codes,
                    //}
                ]
            },
        ];


        if (userRol === "TECHNICIANADMINISTRATOR") {

            elementData[0]?.items?.push(
                {
                    title: "Asignar Técnico",
                    path: PATH_PAYMENTEZ.technician.assignTechnician,
                    icon: ICONS.assign,
                },
            );

            elementData[1]?.items?.push(
                {
                    title: "Asesores",
                    path: PATH_PAYMENTEZ.general.listAdvisor,
                    icon: ICONS.asesores,
                },
                {
                    title: "Crear Usuario",
                    path: PATH_PAYMENTEZ.general.createUser,
                    icon: ICONS.createUser,
                }

            );

        }

        elementData[0]?.items?.push(
            {
                title: "Notificaciones",
                path: PATH_PAYMENTEZ.technician.notification,
                icon: ICONS.dashboard,
            },
        );


        setSidebarCustomPaymentez(elementData);

    }


    const sidebarFinancial = () => {

        var elementData = [
            {
                subheader: "AREA FINANCIERA",
                items: [
                    {
                        title: "Inicio",
                        path: PATH_PAYMENTEZ.financial.home,
                        icon: ICONS.analytics,
                    },
                    {
                        title: "Mis comercios",
                        path: PATH_PAYMENTEZ.financial.myCommerces,
                        icon: ICONS.business,
                    },
                    {
                        title: "Comercios por revisar",
                        path: PATH_PAYMENTEZ.financial.reviewingBusiness,
                        icon: ICONS.review,
                    },
                    {
                        title: "Notificaciones",
                        path: PATH_PAYMENTEZ.financial.notification,
                        icon: ICONS.dashboard,
                    },
                    //{
                    //    title: "Crear usuarios",
                    //    path: PATH_PAYMENTEZ.financial.listUser,
                    //    icon: ICONS.register,
                    //},

                ]
            },
            {
                subheader: "Administración",
                items: [
                    {
                        title: "Perfil",
                        path: PATH_PAYMENTEZ.financial.profile,
                        icon: ICONS.profile,
                    },

                    //{
                    //    title: "Codigos",
                    //    path: PATH_PAYMENTEZ.advisor.listCode,
                    //    icon: ICONS.codes,
                    //}
                ]
            },

        ];


        setSidebarCustomPaymentez(elementData);

    }





    useEffect(() => {
        if (isOpenSidebar) {
            onCloseSidebar();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);



    useEffect(() => {

        if (userRol === "AFFILIATE") {
            sidebarAffiliate();
        }

    }, [businessData?.affiliationData, pathname]);




    useEffect(() => {


        if (userRol === "ADVISOR" || userRol === "SUPERVISOR" || userRol === "ADMINISTRATOR") {
            sidebarAdvisor();
            //sidebarFinancial();
        }
       
        if (userRol === "MONEYGUARD" || userRol === "MONEYGUARDADMINISTRATOR") {
            sidebarMoneyGuard();
        }

        if (userRol === "TECHNICIAN" || userRol === "TECHNICIANADMINISTRATOR") {
            sidebarTechnician();
        }

        if (userRol === "FINANCIAL") {
            sidebarFinancial();
        }




    }, []);


    return {
        sidebarCustomPaymentez,
        businessData,
        userRol

    };
};