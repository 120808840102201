import HttpClient from '../services/HttpClient';
import axios from 'axios';

const instancia = axios.create();
instancia.CancelToken = axios.CancelToken;
instancia.isCancel = axios.isCancel;



export const NotificationAffiliationNewPaymentSave = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/notification/NotificationAffiliationNewPaymentSave", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}
export const CheckIfEstablishmentNotifiedNewPayment = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/notification/CheckIfEstablishmentNotifiedNewPayment", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}


export const CheckIfExistBanksNotified = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/notification/CheckIfExistBanksNotified", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}

export const NotificationAffiliationActiveBanksSave = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/notification/NotificationAffiliationActiveBanksSave", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}






export const NotificationNewAffiliationSave = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/notification/NotificationNewAffiliationSave", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}


export const CheckIfEstablishmentNotifiedNewAffiliation = async (bussiness) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/notification/CheckIfEstablishmentNotifiedNewAffiliation", bussiness).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}


export const DeleteNotification = async (affiliation) => {

    return new Promise((resolve, reject) => {

        HttpClient.post("/notification/DeleteNotification", affiliation).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error.response);
        })

    });
}


export const DeleteNotificationByAffiliation = async (affiliation) => {

    return new Promise((resolve, reject) => {

        HttpClient.post("/notification/DeleteNotificationByAffiliation", affiliation).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error.response);
        })

    });
}



export const MarkReadNotificationAdvisor = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/notification/MarkReadNotificationAdvisor", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}
export const MarkReadNotificationByAffiliation = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/notification/MarkReadNotificationByAffiliation", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}





export const GetNotificationsBusiness = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/notification/GetNotificationsBusiness", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}


export const GetCountNotificationsBusiness = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/notification/GetCountNotificationsBusiness", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}

export const GetNotificationsByAffiliation = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/notification/GetNotificationsByAffiliation", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}
export const GetNotificationsAdvisor = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/notification/GetNotificationsAdvisor", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}


export const GetExampleNotificationsByAffiliation = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/notification/GetExampleNotificationsByAffiliation", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}

export const GetExampleNotificationsAdvisor = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/notification/GetExampleNotificationsAdvisor", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}


export const CheckIfThereisRequestAndNotification = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/notification/CheckIfThereisRequestAndNotification", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}

export const MarkAllReadNotificationOrRequestAdvisor = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/notification/MarkAllReadNotificationOrRequestAdvisor", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}


export const MarkAllReadNotificationOrRequestAffiliate = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/notification/MarkAllReadNotificationOrRequestAffiliate", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}








