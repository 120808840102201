import { useState, useEffect } from "react";


export const useCustomModalSave = () => {


    const [openModalSave, setOpenModalSave] = useState(false);


    const handleOpenCloseModalSave = () => {
        setOpenModalSave(!openModalSave);
    };

    const handleOpenModalSave = () => {
        setOpenModalSave(true);
    };



    return {

        openModalSave,
        handleOpenCloseModalSave,
        handleOpenModalSave
        

    };

};