const base_API = "https://comerciosafiliados.nuvei.com.ec";
const Global = {
    base_API_URL: `${base_API}/api`,
    base_URL_HUB: {
        NOTIFICATION: `${base_API}/notificationHub`,
    },
    allRoles: ['ADMINISTRATOR', 'AFFILIATE', 'ADVISOR', 'SUPERVISOR', 'MONEYGUARD', 'MONEYGUARDADMINISTRATOR', 'FINANCIAL', 'TECHNICIANADMINISTRATOR', 'TECHNICIAN'],
    DaysToApproved: 7,
    GeneralMessageFooterBanks: "Al finalizar esta etapa, los documentos serán enviados a tu asesor para ser revisados. Te notificaremos cuando estén aprobados para que los firmes y sean enviados al banco.",
    GeneralMessageForBanksCertificates: 'Enviar por email a su asesor, todos los certificados bancarios correspondientes a las cuentas que declare en los formularios de afiliación',
    GeneralMessageForAdvisorBanksCertificates: 'Confirmar que el comercio haya enviado los certificados bancarios a su correo electrónico',
    MessageToProtectionData: 'He leído y acepto las políticas de privacidad de datos personales.',
    MessageInLinkToProtectionData: 'Aviso de privacidad de datos'

}
export default Global;




