import { Suspense } from "react";
import { useLocation } from "react-router-dom";
import LoadingScreen from "../components/LoadingScreen";

const Loadable = (Component) => {
  const LoadableComponent = (props) => {
    const { pathname } = useLocation();
    const isDashboard = pathname.includes("/dashboard");

    return (
      <Suspense
        fallback={
          <LoadingScreen
            sx={{
              ...(!isDashboard && {
                top: 0,
                left: 0,
                width: 1,
                zIndex: 9999,
                position: "fixed",
              }),
            }}
          />
        }
      >
        <Component {...props} />
      </Suspense>
    );
  };

  return LoadableComponent;
};

export default Loadable;
