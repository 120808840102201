//import { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import menu2Fill from "@iconify/icons-eva/menu-2-fill";
// material
import {  styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, IconButton, Button } from "@mui/material";
// hooks
import useCollapseDrawer from "../../hooks/useCollapseDrawer";
// components
import { MHidden } from "../../components/@material-extend";
//import Searchbar from "./Searchbar//
//import AccountPopover from "./AccountPopover//
//import LanguagePopover from "./LanguagePopover//
import NotificationsPopover from "./NotificationsPopover";
import AccountLogOut from "./paymentez/AccountLogOut";
import useAuth from "src/hooks/useAuth";
import NotificationNewAffiliation from "./paymentez/NotificationNewAffiliation";
import { Link as RouterLink } from 'react-router-dom';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;
const APPBAR_Paymentez = 62;

const RootStyle = styled(AppBar)(({ theme }) => ({
    heigth: "60px",
    boxShadow: "none",
    backdropFilter: "blur(6px)",
    WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
    backgroundColor: "#454F5B",
    /*    backgroundColor: alpha(theme.palette.background.default, 0.72),*/
    [theme.breakpoints.up("lg")]: {
        width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
    },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up("lg")]: {
        minHeight: APPBAR_Paymentez,
        padding: theme.spacing(0, 5),
    },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
    onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
    const { isCollapse } = useCollapseDrawer();
    const { businessData,user } = useAuth();

  
 

    return (
        <RootStyle
            sx={{
                ...(isCollapse && {
                    width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` },
                }),
            }}
        >
            <ToolbarStyle>
                <MHidden width="lgUp">
                    <IconButton
                        onClick={onOpenSidebar}
                        sx={{ mr: 1, color: "text.primary" }}
                    >
                        <Icon icon={menu2Fill} />
                    </IconButton>
                </MHidden>

                <span
                    style={{
                        color: "#FFFFFF",
                        fontSize: "20px",
                        fontWeight: "bold",
                        letterSpacing: 0,
                        lineHeight: "30px",
                    }}
                >
                    {user?.rol?.toUpperCase() === "AFFILIATE" &&
                        businessData?.businessName 
                    }

                    {user?.rol?.toUpperCase() !== "AFFILIATE" &&
                        <>HOLA {user?.fullName}</>
                    }

                </span>
                <Box sx={{ flexGrow: 1 }} />

                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={{ xs: 0.5, sm: 1.5 }}
                >
                    {user?.rol?.toUpperCase() === "AFFILIATE" && <NotificationNewAffiliation />}
                    {(user?.rol?.toUpperCase() === "ADVISOR" || user?.rol?.toUpperCase() === "SUPERVISOR") &&

                        <Button to="/nuvei/registrar-comercio" size="small" variant="contained" component={RouterLink}>
                           Registra comercio
                        </Button>

                    }
                   
                        <NotificationsPopover />
                   
                    <AccountLogOut  />
                    
                </Stack>
            </ToolbarStyle>
        </RootStyle>
    );
}
