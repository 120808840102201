import PropTypes from 'prop-types';
// material
//import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------
import logo from '../assets/images/icons/logo-nuvei.png';
import { styled } from '@mui/material/styles';


Logo.propTypes = {
  sx: PropTypes.object
};



const LogoStyle = styled('div')(({ theme }) => ({

    //[theme.breakpoints.down('md')]: {
    //    '& .img': {
    //        width: '100%',
    //        height:'100%'
    //    }
    //}
    
}));


export default function Logo({ sx }) {
 /* const theme = useTheme();*/
  //const PRIMARY_LIGHT = theme.palette.primary.light;
  //const PRIMARY_MAIN = theme.palette.primary.main;
  //const PRIMARY_DARK = theme.palette.primary.dark;

  return (
      <Box>
          <LogoStyle><img src={logo} alt="logo" style={{ height: '35px', width: '100%' }}  /></LogoStyle>
    </Box>
  );
}
