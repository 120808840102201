import { useState, useEffect } from "react";
import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState } from 'draft-js';

const commentsContent = (value) => {

    if (value === undefined || value === null || value === "") {
        return "";
    }

    const blocksFromHtml = htmlToDraft(value);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);

    return editorState;
};


const textCustomError = "Corregir este dato";
export const useFormStatusFields = ({ status, setError, clearErrors, setValue }) => {
    
 
    const [fieldsError, setFieldsError] = useState([]);
    const [fieldsSuccess, setFieldsSuccess] = useState([]);
    const [fieldsSuccessModal, setFieldsSuccessModal] = useState([]);
    const [fieldsErrorModal, setFieldsErrorModal] = useState([]);


    const cleanDataFormByValidation = (fields = [], shouldValidate = false) => {
        fields.map((field) => {
          
            if (status === 4) {
                const fieldIsOk = fieldsSuccess.some(e => e.fieldCode === field && e.isDataOnModal === false);
                if (!fieldIsOk) {
                    setValue(field, '', shouldValidate ? { shouldValidate: true } : {});
                }
                clearErrors(field);
            } else {
                
                setValue(field, '', shouldValidate ? { shouldValidate: true } : {});
            }
        });
    }




    useEffect(() => {
      
        if (status === 4) {
            if (fieldsError?.length > 0) {
                fieldsError?.map(function (value) {
              
                    setError(value.fieldCode, { message: textCustomError });
                });

              

            }
        }
    }, [fieldsError]);





    return {
        commentsContent,
        textCustomError,
        fieldsError,
        setFieldsError,
        fieldsSuccess,
        setFieldsSuccess,
        fieldsSuccessModal,
        setFieldsSuccessModal,
        fieldsErrorModal,
        setFieldsErrorModal,

        //Metodos
        cleanDataFormByValidation,

    };

};