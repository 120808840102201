import { useState, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { MIconButton } from "../../@material-extend";
import closeFill from "@iconify/icons-eva/close-fill";
import { Icon } from '@iconify/react';
import { PDFDocument } from 'pdf-lib';



const readFile = (file) => {

    return new Promise((resolve, reject) => {

        const reader = new FileReader();

        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);

        reader.readAsArrayBuffer(file);
    });
}


const NumberPagesPdf = async (fileInfo) => {
    const arrayBuffer = await readFile(fileInfo);
    const pdf = await PDFDocument.load(arrayBuffer);
    return pdf.getPages()?.length;
}


const fileToBase64 = (file, cb) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
        cb(null, reader.result)
    }
    reader.onerror = function (error) {
        cb(error, null)
    }
}


export const useUtils = () => {
    

  
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  

    const ErrorNotification = (text) => {
        enqueueSnackbar(text, {
            variant: 'error',
            action: (key) => (
                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                    <Icon icon={closeFill} />
                </MIconButton>
            )
        });
    };



    return {
        NumberPagesPdf,
        //metodos
        ErrorNotification,
        readFile,
        fileToBase64,
       

    };

};