import HttpClient from '../services/HttpClient';
import axios from 'axios';

const instancia = axios.create();
instancia.CancelToken = axios.CancelToken;
instancia.isCancel = axios.isCancel;




export const SaveFileEnablingDocument = async (document) => {

    return new Promise((resolve, eject) => {
        
        HttpClient.post("/documents/enablingDocumentsUpload", document).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });

}


export const SaveFileEnablingDocumentCollect = async (document) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/documents/enablingDocumentsUploadCollect", document).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });

}



export const GetEnablingDocuments = async (affiliation) => {
    return new Promise((resolve, eject) => {

        HttpClient.post("/documents/enablingDocumentsList", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}



export const SaveFileBankDocument = async (document) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/documents/bankDocumentsUpload", document).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });



}




export const GetBanksDocumentsByForm = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/documents/bankDocumentsGetByForm", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });


}



export const GetEnablingDocumentsByName = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/documents/GetEnablingDocumentsByName", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });

}


export const SaveEnablingDocumentsReviewByName = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/documents/SaveEnablingDocumentsReviewByName", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });

}




export const GetBanksDocumentsById = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/documents/GetBanksDocumentsById", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });


}

export const GetNuveiDocumentsById = async (affiliation) => {
    return new Promise((resolve, eject) => {

        HttpClient.post("/documents/GetNuveiDocumentsById", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}


export const GetBanksDocumentsDifferentCurrentBank = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/documents/GetBanksDocumentsDifferentCurrentBank", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });


}

//Agregador

export const GetEnablingDocumentsCollect = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/documents/GetEnablingDocumentsCollect", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });

}

export const GetDocumentToUploadCollect = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/documents/GetDocumentToUploadCollect", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}



export const GetEnablingDocumentsByNameCollect = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/documents/GetEnablingDocumentsByNameCollect", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });

}


export const SaveEnablingDocumentsReviewByNameCollect = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/documents/SaveEnablingDocumentsReviewByNameCollect", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });



}
export const SaveEnablingDocumentsReviewByNameCollectMoneyGuard = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/documents/SaveEnablingDocumentsReviewByNameCollectMoneyGuard", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });



}





export const GetCollectDocumentsByForm = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/documents/NuveiCollectDocumentsGetByForm", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });


}


export const SaveCollectFileNuveiDocument = async (document) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/documents/NuveiCollectDocumentsUpload", document).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });



}


export const GetNuveiDocumentByAffiliationCollect = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/documents/GetNuveiDocumentByAffiliationCollect", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}












