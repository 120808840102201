// ----------------------------------------------------------------------

function path(root, sublink) {
    return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_PAYMENTEZ = '/app';
const ROOTS_GATEWAY = '/app/gateway';
const ROOTS_COLLECT = '/app/agregador';
const ROOTS_PAYMENTEZADVISOR = '/nuvei';
const ROOTS_PAYMENTEZGENERAL = '/general';
const ROOTS_PAYMENTEZMONEYGUARD = '/proteccion-dinero';
const ROOTS_PAYMENTEZTECHNICIAN = '/tecnico';
const ROOTS_PAYMENTEZFINANCIAL = '/financiero';
//const ROOTS_PAYMENTEZADMINISTRATOR = '/administrador';
// ----------------------------------------------------------------------
export const PATH_AUTH = {
    root: ROOTS_AUTH,
    login: path(ROOTS_AUTH, '/login'),
    loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
    register: path(ROOTS_AUTH, '/register'),
    registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
    resetPassword: path(ROOTS_AUTH, '/reset-password'),
    verify: path(ROOTS_AUTH, '/verify')
};


export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,

    general: {
        pageAllAffiliations: path(ROOTS_PAYMENTEZ, '/mis-afiliaciones'),
        monitoringPanel: path(ROOTS_PAYMENTEZ, '/panel-seguimiento'),
        enablingDocuments: path(ROOTS_PAYMENTEZ, '/documentos-habilitantes'),
        pageTwo: path(ROOTS_PAYMENTEZ, '/two'),
        pageThree: path(ROOTS_PAYMENTEZ, '/three')
    },
    app: {
        root: path(ROOTS_DASHBOARD, '/app'),
        pageFour: path(ROOTS_DASHBOARD, '/app/four'),
        pageFive: path(ROOTS_DASHBOARD, '/app/five'),
        pageSix: path(ROOTS_DASHBOARD, '/app/six')
    }
};


export const PATH_PAYMENTEZ = {
    root: ROOTS_PAYMENTEZ,
    rootAdvisor: ROOTS_PAYMENTEZADVISOR,
    rootMoneyGuard: ROOTS_PAYMENTEZMONEYGUARD,
    rootFinancial: ROOTS_PAYMENTEZFINANCIAL,
    rootTechnician: ROOTS_PAYMENTEZTECHNICIAN,
    advisor: {
        home: path(ROOTS_PAYMENTEZADVISOR, '/inicio'),
        registerCommerce: path(ROOTS_PAYMENTEZADVISOR, '/registrar-comercio'),
        myCommerces: path(ROOTS_PAYMENTEZADVISOR, '/mis-comercios/'),
        //allAffiliationsByCommerce: path(ROOTS_PAYMENTEZADVISOR, '/mis-comercios/'),
        commerce: path(ROOTS_PAYMENTEZADVISOR, '/comercio/'),
        approveDocumentation: path(ROOTS_PAYMENTEZADVISOR, '/aprobar-documentacion/'),
        approveDocumentationCollect: path(ROOTS_PAYMENTEZADVISOR, '/aprobar-documentacion/agregador/'),
        viewRequests: path(ROOTS_PAYMENTEZADVISOR, '/solicitudes'),
        profile: path(ROOTS_PAYMENTEZADVISOR, '/perfil/'),
        log: path(ROOTS_PAYMENTEZADVISOR, '/log'),
       
        listAdvisor: path(ROOTS_PAYMENTEZADVISOR, '/asesores'),
        listCode: path(ROOTS_PAYMENTEZADVISOR, '/codigos'),
        notification: path(ROOTS_PAYMENTEZADVISOR, '/notificaciones'),

        assignMoneyGuard : path(ROOTS_PAYMENTEZADVISOR, '/asignar-analista-riesgo'),

    },
    general: {
        listAdvisor: path(ROOTS_PAYMENTEZGENERAL, '/asesores'),
        createUser: path(ROOTS_PAYMENTEZGENERAL, '/nuevo-usuario/'),
        profile: path(ROOTS_PAYMENTEZGENERAL, '/perfil/'),
    },
    app: {
        pageAllAffiliations: path(ROOTS_PAYMENTEZ, '/mis-afiliaciones'),
        frequentQuestion: path(ROOTS_PAYMENTEZ, '/preguntas-frecuentes'),
        adicionalDocumentation: path(ROOTS_PAYMENTEZ, '/documentacion-adicional-bancos/'),
        gateway: {
            monitoringPanel: path(ROOTS_GATEWAY, '/panel-seguimiento/'),
            monitoringStateDocument: path(ROOTS_GATEWAY, '/detalle-documentacion/'),
            establishmentData: path(ROOTS_GATEWAY, '/datos-establecimiento/'),
            enablingDocuments: path(ROOTS_GATEWAY, '/documentos-habilitantes/'),
            paymentezDocuments: path(ROOTS_GATEWAY, '/documentos-nuvei/'),
            banksDocuments: path(ROOTS_GATEWAY, '/documentos-bancos/'),
            adicionalDocumentation: path(ROOTS_GATEWAY, '/documentacion-adicional-bancos/'),
            notification: path(ROOTS_GATEWAY, '/notificaciones/'),
            resume: path(ROOTS_GATEWAY, '/resume/'),
        }, 
        collect: {
            monitoringPanel: path(ROOTS_COLLECT, '/panel-seguimiento/'),
            monitoringStateDocument: path(ROOTS_COLLECT, '/detalle-documentacion/'),
            establishmentData: path(ROOTS_COLLECT, '/datos-establecimiento/'),
            enablingDocuments: path(ROOTS_COLLECT, '/documentos-habilitantes/'),
            paymentezDocuments: path(ROOTS_COLLECT, '/documentos-nuvei/'),
            notification: path(ROOTS_COLLECT, '/notificaciones/'),
            resume: path(ROOTS_COLLECT, '/resume/'),
        }
    },
    moneyGuard: {
        home: path(ROOTS_PAYMENTEZMONEYGUARD, '/inicio'),
        approveDocumentation: path(ROOTS_PAYMENTEZMONEYGUARD, '/aprobar-documentacion/'),
        myCommerces: path(ROOTS_PAYMENTEZMONEYGUARD, '/mis-comercios/'),
        commerce: path(ROOTS_PAYMENTEZMONEYGUARD, '/comercio/'),
        listUser: path(ROOTS_PAYMENTEZMONEYGUARD, '/usuarios'),
        notification: path(ROOTS_PAYMENTEZMONEYGUARD, '/notificaciones/'),
        profile: path(ROOTS_PAYMENTEZMONEYGUARD, '/perfil/'),
    },
    technician: {
        home: path(ROOTS_PAYMENTEZTECHNICIAN, '/inicio'),
        myCommerces: path(ROOTS_PAYMENTEZTECHNICIAN, '/mis-comercios/'),
        reviewingBusiness: path(ROOTS_PAYMENTEZTECHNICIAN, '/comercios-por-revisar/'),
        listUser: path(ROOTS_PAYMENTEZTECHNICIAN, '/usuarios'),
        log: path(ROOTS_PAYMENTEZTECHNICIAN, '/log'),
        assignTechnician: path(ROOTS_PAYMENTEZTECHNICIAN, '/asignar-tecnico'),
        notification: path(ROOTS_PAYMENTEZTECHNICIAN, '/notificaciones/'),
        profile: path(ROOTS_PAYMENTEZTECHNICIAN, '/perfil/'),
    },
    financial: {
        home: path(ROOTS_PAYMENTEZFINANCIAL, '/inicio'),
        myCommerces: path(ROOTS_PAYMENTEZFINANCIAL, '/mis-comercios/'),
        reviewingBusiness: path(ROOTS_PAYMENTEZFINANCIAL, '/comercios-por-revisar/'),
        listUser: path(ROOTS_PAYMENTEZFINANCIAL, '/usuarios'),
        //log: path(ROOTS_PAYMENTEZFINANCIAL, '/log'),
        profile: path(ROOTS_PAYMENTEZFINANCIAL, '/perfil/'),
        notification: path(ROOTS_PAYMENTEZFINANCIAL, '/notificaciones/'),
     
    }
   
};
