import { lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import useAuth from '../hooks/useAuth';
import routeBusiness from './routeByRol/routeBusiness';
import routeAdvisor from './routeByRol/routeAdvisor';
import routeNuveiGeneral from './routeByRol/routeNuveiGeneral';
import routeMoneyGuard from './routeByRol/routeMoneyGuard';
import routeTechnician from './routeByRol/routeTechnician';
import routeFinancial from './routeByRol/routeFinancial';
import Loadable from './Loadable';
import { LayoutChoosingGuard } from '../guards/LayoutChoosingGuard';


export default function Router() {

    const { pathname } = useLocation();
    const { gateway, collect } = useAuth();

   
    return useRoutes([
        {
            path: '/',
            children: [
                { element: <Navigate to="auth/login" replace /> },
                {
                    path: 'auth/login',
                    element: (
                        <GuestGuard>
                            <Login />
                        </GuestGuard>
                    )
                },

            ]
        },
        {
            path: 'app',
            element:
                <AuthGuard rol="AFFILIATE">
                        <LayoutChoosingGuard />
                </AuthGuard>
            ,
            children: routeBusiness({ pathname, gateway, collect })
        },
        {
            path: 'nuvei',
            element:
                <AuthGuard rol="ADVISOR,SUPERVISOR,ADMINISTRATOR">
                    <DashboardLayout />
                </AuthGuard>,
            children: routeAdvisor()
        },
        {
            path: 'general',
            element:
                <AuthGuard rol="SUPERVISOR,ADMINISTRATOR,MONEYGUARDADMINISTRATOR,TECHNICIANADMINISTRATOR">
                    <DashboardLayout />
                </AuthGuard>,
            children: routeNuveiGeneral()
        },
        {
            path: 'proteccion-dinero',
            element:
                <AuthGuard rol="MONEYGUARD,MONEYGUARDADMINISTRATOR,ADMINISTRATOR">
                    <DashboardLayout />
                </AuthGuard>,
            children: routeMoneyGuard()
        },
        {
            path: 'tecnico',
            element:
                <AuthGuard rol="TECHNICIAN,TECHNICIANADMINISTRATOR,ADMINISTRATOR">
                    <DashboardLayout />
                </AuthGuard>,
            children: routeTechnician()
        },
        {
            path: 'financiero',
            element:
                <AuthGuard rol="FINANCIAL,ADMINISTRATOR">
                    <DashboardLayout />
                </AuthGuard>,
            children: routeFinancial()
        },
        {
            path: '*',
            element: <LogoOnlyLayout />,
            children: [
                { path: '404', element: <NotFound /> },
                { path: '*', element: <Navigate to="/404" replace /> }
            ]
        },
        { path: '*', element: <Navigate to="/404" replace /> }
    ]);
}

// IMPORT COMPONENTS

const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));


