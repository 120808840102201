import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "../Loadable";

const routeMoneyGuard = (props) => [
    {
        element: <Navigate to="/proteccion-dinero/inicio" replace />,
    },
    { path: "inicio", element: <Home /> },
    { path: "aprobar-documentacion", element: <ApproveDocumentation /> },
    {
        path: "aprobar-documentacion/:business/:affiliation",
        element: <ApproveDocumentationDetail />,
    },
    { path: "mis-comercios", element: <AllCommerce /> },
    { path: "mis-comercios/:business", element: <AllAffiliationByAdvisor /> },
    { path: "usuarios", element: <ListUser /> },
    { path: "perfil", element: <Profile /> },
    { path: "notificaciones/", element: <Notification /> },
    {
        path: "notificaciones/:affiliation/:id",
        element: <Notification key={Math.random()} />,
    },
];

const Home = Loadable(lazy(() => import("../../pages/money-guard/Home")));
const ApproveDocumentation = Loadable(
    lazy(() => import("../../pages/money-guard/ApproveDocumentation"))
);
const ApproveDocumentationDetail = Loadable(
    lazy(() => import("../../pages/money-guard/ApproveDocumentationDetail"))
);
const AllCommerce = Loadable(
    lazy(() => import("../../pages/money-guard/HomeAllCommerceMoneyGuard"))
);
const AllAffiliationByAdvisor = Loadable(
    lazy(() => import("../../pages/money-guard/AllAffiliationByCommerce"))
);
const ListUser = Loadable(
    lazy(() => import("../../pages/money-guard/ListUser"))
);
const Profile = Loadable(lazy(() => import("../../pages/advisor/Profile")));
const Notification = Loadable(
    lazy(() => import("../../pages/advisor/Notifications"))
);


export { Loadable };

export default routeMoneyGuard;
