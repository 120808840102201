import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "../Loadable";
import Log from "../../pages/advisor/Log";
import AuthGuard from "src/guards/AuthGuard";

const routeTechnician = () => [
  {
    element: <Navigate to="/tecnico/inicio" replace />,
  },
  { path: "inicio", element: <Home /> },
  { path: "mis-comercios", element: <MyBusinessesTechnician /> },
  { path: "mis-comercios/:business", element: <AllAffiliationByBusiness /> },
  { path: "comercios-por-revisar", element: <AffiliationsToReview /> },
  { path: "usuarios", element: <ListUser /> },
  {
    path: "log", element: <Log />
  },
  {
    path: "pruebas/:affiliation",
    element: <AffiliationTestingReviewDetail />,
  },
  {
    path: "asignar-tecnico", element:
      <AuthGuard rol="TECHNICIANADMINISTRATOR">
        <AssignTechnician />
      </AuthGuard>
  },
    { path: "perfil", element: <Profile /> },
    { path: "notificaciones/", element: <Notification /> },
    {
        path: "notificaciones/:affiliation/:id",
        element: <Notification key={Math.random()} />,
    },
];

const Home = Loadable(lazy(() => import("../../pages/technician/Home")));
const MyBusinessesTechnician = Loadable(
  lazy(() => import("../../pages/technician/MyBusinessesTechnician"))
);
const AllAffiliationByBusiness = Loadable(
  lazy(() => import("../../pages/technician/AllAffiliationByBusiness"))
);
const AffiliationsToReview = Loadable(
  lazy(() => import("../../pages/technician/AffiliationsToReview"))
);
const ListUser = Loadable(
  lazy(() => import("../../pages/technician/ListUser"))
);

const AssignTechnician = Loadable(
  lazy(() => import("../../pages/technician/AssignTechnician"))
);

const AffiliationTestingReviewDetail = Loadable(
  lazy(() => import("../../pages/technician/AffiliationTestingReviewDetail"))
);
const Profile = Loadable(lazy(() => import("../../pages/advisor/Profile")));
const Notification = Loadable(
    lazy(() => import("../../pages/advisor/Notifications"))
);

export { Loadable };

export default routeTechnician;
