import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import { useRef, useState } from 'react';
import { format } from 'date-fns';
import { Icon } from '@iconify/react';
import bellFill from '@iconify/icons-eva/bell-fill';
import clockFill from '@iconify/icons-eva/clock-fill';
// material
import { alpha } from '@mui/material/styles';
import {
    Box,
    List,
    Badge,
    Avatar,
    Divider,
    Typography,
    ListItemText,
    ListItemAvatar,
    ListItemButton
} from '@mui/material';
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import { MIconButton } from '../../components/@material-extend';
import imgUnread from '../../assets/images/icons/mailunread.png';
import imgRead from '../../assets/images/icons/mailread.png';
import { useNotifications } from '../../hooks/useNotifications';
import CircularProgress from '@mui/material/CircularProgress';
import { PATH_PAYMENTEZ } from "../../routes/paths";
import JsxParser from 'react-jsx-parser';
import { ActionSeeAllNotificationAffiliate, ActionSeeAllNotificationAdvisorAndSupervisor, MarkAllasReadByRol } from './partials';
import useAuth from '../../hooks/useAuth';


function renderContent(notification) {
    let resume = notification?.resume <= 70 ? notification?.resume : notification?.resume?.substr(0, 70) + "...";


    const title = (
        <Typography variant="subtitle2">
            {notification?.step} <br />
            <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
                <JsxParser jsx={resume} />
            </Typography>
        </Typography>
    );

    //if (notification.type === 'order_placed') {
    //  return {
    //    avatar: <img alt={notification.title} src="/static/icons/ic_notification_package.svg" />,
    //    title
    //  };
    //}
    //if (notification.type === 'order_shipped') {
    //  return {
    //    avatar: <img alt={notification.title} src="/static/icons/ic_notification_shipping.svg" />,
    //    title
    //  };
    //}
    if (!notification.read) {
        return {
            avatar: <img alt={notification.title} src={imgUnread} />,
            title
        };
    }
    if (notification.read) {
        return {
            avatar: <img alt={notification.title} src={imgRead} />,
            title
        };
    }
    //if (notification.type === 'chat_message') {
    //  return {
    //    avatar: <img alt={notification.title} src="/static/icons/ic_notification_chat.svg" />,
    //    title
    //  };
    //}
    return {
        avatar: <img alt={notification.title} src={notification.avatar} />,
        title
    };
}

NotificationItem.propTypes = {
    notification: PropTypes.object.isRequired
};

function NotificationItem({ notification, handleClose, rol }) {
    const navigate = useNavigate();

    const { avatar, title } = renderContent(notification);
    const { businessData } = useAuth();
    const handleReviewNotificaticon = () => {

        handleClose();
        if (rol === "affiliate") {

            const findAffiliation = businessData?.affiliationData.find(e => e.affiliationDataId === notification?.affiliationDataId);
            let type = "";
            if (findAffiliation !== undefined) {
                if (findAffiliation?.typeAffiliation === 1) {
                    type = PATH_PAYMENTEZ.app.gateway.notification;
                }
                if (findAffiliation?.typeAffiliation === 2) {
                    type = PATH_PAYMENTEZ.app.collect.notification;
                }

            }



            navigate(type + notification?.affiliationDataId + "/" + notification?.notificationBusinessGeneralId);
        }
        if (rol === "advisor" || rol === "supervisor" || rol === "moneyguard" || rol === "moneyguardadministrator" || rol === "technicianadministrator" || rol === "technician" || rol === "financial") {


            let userRolLink = "";
            if (rol === "advisor" || rol === "supervisor") {
                userRolLink = PATH_PAYMENTEZ.advisor.notification;
            }
            if (rol === "moneyguard" || rol === "moneyguardadministrator") {
                userRolLink = PATH_PAYMENTEZ.moneyGuard.notification;
            }
            if (rol === "technicianadministrator" || rol === "technician") {
                userRolLink = PATH_PAYMENTEZ.technician.notification;
            }

            if (rol === "financial") {
                userRolLink = PATH_PAYMENTEZ.financial.notification;
            }

           

            if (notification?.typeNotification === 4) {
                navigate(userRolLink + "/" + notification?.affiliationDataId + "/" + notification?.notificationGeneralId);
            }
            if (notification?.typeNotification !== 4) {
                navigate(PATH_PAYMENTEZ.advisor.viewRequests + "/" + notification?.affiliationDataId + "/" + notification?.notificationGeneralId);
            }

        }



    }

    return (
        <ListItemButton
            onClick={handleReviewNotificaticon}
            //to={PATH_PAYMENTEZ.gateway.notification + notification?.affiliationDataId}
            //component={RouterLink}
            sx={{
                py: 1.5,
                px: 2.5,
                mt: '1px',
                ...(!notification.read && {
                    bgcolor: 'action.selected'
                })
            }}
        >
            <ListItemAvatar>
                <Avatar sx={{ bgcolor: 'background.neutral', height: '24px', width: '24px' }}>{avatar}</Avatar>
            </ListItemAvatar>
            <ListItemText

                primary={title}
                secondary={
                    <Typography
                        variant="caption"
                        sx={{
                            mt: 0.5,
                            display: 'flex',
                            alignItems: 'center',
                            color: 'text.disabled'
                        }}
                    >
                        <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 16, height: 16 }} />
                        {format(new Date(notification?.createdOn), 'dd/MM/yyyy')}

                    </Typography>
                }
            />
        </ListItemButton>
    );
}

export default function NotificationsPopover() {

    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const { countNotifications, notifications, loading, onClickLoadNotifications, affiliationId, rol, loadingAllMarket, onClickMarkAllAsReadAffiliate, onClickMarkAllAsReadAdvisor } = useNotifications();


    const totalUnRead = countNotifications;

    const handleOpen = () => {
        setOpen(true);
        onClickLoadNotifications();
    };

    const handleClose = () => {
        setOpen(false);
    };



    return (
        <>
            <MIconButton
                ref={anchorRef}
                size="large"
                color={open ? 'primary' : 'default'}
                onClick={handleOpen}
                sx={{
                    ...(open && {
                        bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
                    })
                }}
            >
                <Badge badgeContent={totalUnRead} color="error">
                    <Icon icon={bellFill} width={20} height={20} />
                </Badge>
            </MIconButton>

            <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 360 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="subtitle1">Notificaciones
                            {rol === "advisor" || rol === "supervisor" && " - Solicitudes"}
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            Tienes {totalUnRead} sin leer.
                        </Typography>
                    </Box>



                    {totalUnRead > 0 && (

                        <>
                            {loadingAllMarket ?

                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <CircularProgress style={{ width: '30px', height: '30px' }} />
                                </Box>
                                :

                                <MarkAllasReadByRol
                                    onClickMarkAllAsReadAffiliate={onClickMarkAllAsReadAffiliate}
                                    onClickMarkAllAsReadAdvisor={onClickMarkAllAsReadAdvisor}
                                    rol={rol}
                                    loadingAllMarket={loadingAllMarket} />

                            }


                        </>

                    )}



                </Box>

                {notifications?.length > 0 &&
                    <Divider />
                }

                <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>

                    <List disablePadding >

                        {loading ?
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', paddingBottom: '20px' }}>
                                <CircularProgress />
                            </Box>
                            :
                            totalUnRead > 0 && notifications.map((notification) => (
                                <NotificationItem key={notification.notificationBusinessGeneralId} notification={notification} handleClose={handleClose} rol={rol} />
                            ))
                        }

                    </List>


                </Scrollbar>



                {totalUnRead > 0 &&
                    <>
                        <Divider />
                        <Box sx={{ p: 1 }}>

                            {rol === "affiliate" &&
                                <ActionSeeAllNotificationAffiliate affiliationId={affiliationId} />
                            }
                        {(rol === "advisor" || rol === "supervisor" || rol === "moneyguard" || rol === "moneyguardadministrator" || rol === "technicianadministrator" || rol === "technician" || rol === "financial") &&
                                <ActionSeeAllNotificationAdvisorAndSupervisor affiliationId={affiliationId} />
                            }
                        </Box>
                    </>
                }

            </MenuPopover>
        </>
    );
}
