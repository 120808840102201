import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "../Loadable";

const routeAdvisor = (props) => [
  {
    element: <Navigate to="/nuvei/inicio" replace />,
  },
  { path: "inicio", element: <Home /> },
  { path: "registrar-comercio", element: <RegisterCommerce /> },
  { path: "mis-comercios", element: <AllCommerce /> },
  { path: "mis-comercios/:business", element: <AllAffiliationByAdvisor /> },
  { path: "mis-comercios/:business/editar", element: <EditCommerce /> },
  { path: "aprobar-documentacion", element: <ApproveDocumentation /> },
  {
    path: "aprobar-documentacion/:business/:affiliation",
    element: <ApproveDocumentationDetail />,
  },
  {
    path: "aprobar-documentacion/agregador/:business/:affiliation",
    element: <ApproveDocumentationDetailCollect />,
  },
  { path: "solicitudes", element: <Request /> },
  { path: "log", element: <Log /> },
  { path: "perfil", element: <Profile /> },
  { path: "codigos", element: <ListLoginCode /> },
  { path: "notificaciones/", element: <Notification /> },
  {
    path: "notificaciones/:affiliation/:id",
    element: <Notification key={Math.random()} />,
  },
  { path: "asignar-analista-riesgo", element: <AssignMoneyGuard /> },
];

const Home = Loadable(lazy(() => import("../../pages/advisor/Home")));
const RegisterCommerce = Loadable(
  lazy(() => import("../../pages/advisor/CreateCommerce"))
);
const EditCommerce = Loadable(
  lazy(() => import("../../pages/advisor/EditCommerce"))
);
const ApproveDocumentation = Loadable(
  lazy(() => import("../../pages/advisor/ApproveDocumentation"))
);
const ApproveDocumentationDetail = Loadable(
  lazy(() => import("../../pages/advisor/ApproveDocumentationDetail"))
);
const ApproveDocumentationDetailCollect = Loadable(
  lazy(() =>
    import("../../pages/advisor/collect/ApproveDocumentationDetailCollect")
  )
);
const AllCommerce = Loadable(
  lazy(() => import("../../pages/advisor/HomeAllCommerce"))
);
const AllAffiliationByAdvisor = Loadable(
  lazy(() => import("../../pages/advisor/AllAffiliationByCommerce"))
);
const Request = Loadable(lazy(() => import("../../pages/advisor/Request")));
const Profile = Loadable(lazy(() => import("../../pages/advisor/Profile")));
const Log = Loadable(lazy(() => import("../../pages/advisor/Log")));
const ListLoginCode = Loadable(
  lazy(() => import("../../pages/advisor/ListLoginCode"))
);
const Notification = Loadable(
  lazy(() => import("../../pages/advisor/Notifications"))
);

const AssignMoneyGuard = Loadable(
  lazy(() => import("../../pages/advisor/collect/AssignMoneyGuard"))
);

export { Loadable };

export default routeAdvisor;
