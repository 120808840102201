import { Link as RouterLink } from 'react-router-dom';
import { Button } from '@mui/material';
import useAuth from '../../../hooks/useAuth';

export const ActionSeeAllNotificationAffiliate = ({ affiliationId }) => {

    const { businessData } = useAuth();

    const findAffiliation = businessData?.affiliationData.find(e => e.affiliationDataId === affiliationId);
    let type = "";
    if (findAffiliation !== undefined) {
        if (findAffiliation?.typeAffiliation === 1) {
            type = "gateway";
        }
        if (findAffiliation?.typeAffiliation === 2) {
            type = "agregador";
        }

    }

    return (

        <Button fullWidth disableRipple component={RouterLink} to={`/app/${type}/notificaciones/${affiliationId}`}>
            Ver todas
        </Button>

    )
}

