import { Fragment, lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "../Loadable";
import Navbar from "../../layouts/utils/Navbar";
import {
    EndFirstStep,
    EndAllStep,
} from "../../components/paymentez/collect/utils";

const routeBusinessCollect = (props) => [
    { element: <Navigate to="/app/mis-afiliaciones" replace /> },
    {
        path: "panel-seguimiento/:affiliation",
        element: <MonitoringPanel key={props.pathname.split("/")[4]} />,
    },
    {
        path: "detalle-documentacion/:affiliation",
        element: <MonitoringStatusDetail key={props.pathname.split("/")[4]} />,
    },
    {
        path: "datos-establecimiento/:affiliation",
        element: <EstablishmentData key={props.pathname.split("/")[4]} />,
    },

    //{ path: 'documentos-habilitantes/:affiliation', element: <EnablingDocuments key={props.pathname.split("/")[4]} /> },
    {
        path: "documentos-habilitantes/:affiliation",
        element: (
            <Fragment>
                {props?.collect?.showViewEndFistStep ? (
                    <>
                        <Navbar />
                        <EndFirstStep />{" "}
                    </>
                ) : (
                    <EnablingDocuments key={props.pathname.split("/")[4]} />
                )}
            </Fragment>
        ),
    },
    {
        path: "documentos-nuvei/:affiliation",
        element: (
            <Fragment>
                {props?.collect?.showViewEndLastStep ? (
                    <>
                        <Navbar />
                        <EndAllStep />{" "}
                    </>
                ) : (
                    <NuveiDocuments key={props.pathname.split("/")[4]} />
                )}
            </Fragment>
        ),
    },
    {
        path: "documentos-nuvei/:affiliation/:form",
        element: (
            <Fragment>
                {props?.collect?.showViewEndLastStep ? (
                    <>
                        <Navbar />
                        <EndAllStep />{" "}
                    </>
                ) : (
                    <NuveiDocuments key={props.pathname.split("/")[4]} />
                )}
            </Fragment>
        ),
    },
    {
        path: "notificaciones/:affiliation",
        element: <Notification key={props.pathname.split("/")[4]} />,
    },
    {
        path: "notificaciones/:affiliation/:id",
        element: (
            <Notification key={props.pathname.split("/")[4] + Math.random()} />
        ),
    },
    {
        path: "resume/:affiliation",
        element: <ResumeAffiliation key={props.pathname.split("/")[4]} />,
    },
];

const EstablishmentData = Loadable(
    lazy(() => import("../../pages/collect_business/EstablishmentData"))
);
const EnablingDocuments = Loadable(
    lazy(() => import("../../pages/collect_business/EnablingDocuments"))
);
const NuveiDocuments = Loadable(
    lazy(() => import("../../pages/collect_business/NuveiDocuments"))
);
const MonitoringPanel = Loadable(
    lazy(() => import("../../pages/collect_business/MonitoringPanel"))
);
const MonitoringStatusDetail = Loadable(
    lazy(() => import("../../pages/collect_business/MonitoringStatusDetail"))
);

const ResumeAffiliation = Loadable(
    lazy(() => import("../../pages/collect_business/ResumeAffiliation"))
);


const Notification = Loadable(lazy(() => import("../../pages/Notifications")));

export { Loadable };

export default routeBusinessCollect;
