import { Grid, Card, CardHeader, RadioGroup, Checkbox, Typography, FormControlLabel, Radio, CardContent, FormLabel, Avatar, TextField, InputLabel, FormControl, Select, MenuItem } from "@mui/material";
import { Controller } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import LoadingScreen from '../../../../../components/LoadingScreen';
import representativeImage from '../../../../../assets/images/icons/user.png';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

const CardHeaderCustom = styled('div')(({ theme, width }) => ({
    width: width,
    [theme.breakpoints.down('md')]: {
        width: '100%',
    }
}));



export const LegalRepresentativeOrOwner = ({ loading, fieldsSuccess, errors, businessData, control, cleanDataFormByValidation, watch, setValue }) => {



    return (


        <Card sx={{ position: 'static' }}>
            <CardHeaderCustom width="425px"><CardHeader title={businessData.typePerson === 2 ? "Completa los datos del representante legal" : "Completa los datos del propietario"} avatar={<Avatar alt="establecimiento" src={representativeImage} variant="square" sx={{ width: '72px', height: '72px' }} />} /></CardHeaderCustom>
            <CardContent>





                {loading.initial

                    ?

                    <Grid container mt={12} mb={12}>
                        <Grid item xs={12} md={12}>
                            <LoadingScreen />
                        </Grid>
                    </Grid>

                    :
                    <Grid container spacing={2}>


                        {businessData.typePerson === 2 &&
                            <>

                                <Grid item xs={12} md={watch("LegalRepresentativeIsACompany") === true ? 4 : 12}>
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                name="LegalRepresentativeIsACompany"
                                                control={control}
                                                render={({ field, fieldState: { error } }) => (
                                                    <>
                                                        <Checkbox
                                                            {...field}
                                                            checked={field.value !== undefined && field.value}
                                                            onChange={(e) => {
                                                                field.onChange(e.target.checked);
                                                                if (e.target.checked === false) {
                                                                    setValue("LegalRepresentativeCompanyBusinessName", "", { shouldValidate: true });
                                                                }

                                                            }}
                                                            disabled={fieldsSuccess?.some(e => e.fieldCode === "LegalRepresentativeIsACompany")}
                                                        />
                                                    </>
                                                )}
                                            />
                                        }
                                        label={
                                            <>
                                                <span className={errors["LegalRepresentativeIsACompany"] && "TextColorError"} style={{ fontSize: '16px' }}>
                                                    El Representante legal es una empresa
                                                </span>
                                                {errors["LegalRepresentativeIsACompany"] &&
                                                    <>
                                                        <span style={{ marginLeft: '14px', color: '#FF4842' }}> -  </span>
                                                        <span className="TextCustomError">  Corregir este dato </span>
                                                    </>
                                                }


                                            </>
                                        }
                                    />


                                </Grid>


                                {(watch("LegalRepresentativeIsACompany") === true) &&

                                    <>
                                        <Grid item xs={12} md={8}>

                                            <Controller
                                                name="LegalRepresentativeCompanyBusinessName"
                                                control={control}
                                                render={({ field, fieldState: { error } }) => (
                                                    <TextField {...field} label="Razón social" error={Boolean(error)} helperText={error?.message} fullWidth inputProps={{ style: { textTransform: "uppercase" } }}
                                                        disabled={fieldsSuccess?.some(e => e.fieldCode === "LegalRepresentativeCompanyBusinessName")} />
                                                )}
                                            />

                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Typography variant="body1" mt={2} mb={2}>
                                        <strong>Completar la información solicitada con los datos de la persona que firma.</strong>
                                            </Typography>
                                        </Grid>
                                    </>
                                }




                            </>
                        }


                        <Grid item xs={12} md={6}>

                            <Controller
                                name="LegalRepresentativeName"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField {...field} label="Nombres completos" error={Boolean(error)} helperText={error?.message} fullWidth inputProps={{ style: { textTransform: "uppercase" } }}
                                        disabled={fieldsSuccess?.some(e => e.fieldCode === "LegalRepresentativeName")} />
                                )}
                            />

                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Controller
                                name="LegalRepresentativeLastName"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField {...field} label="Apellidos completos" error={Boolean(error)} helperText={error?.message} fullWidth inputProps={{ style: { textTransform: "uppercase" } }}
                                        disabled={fieldsSuccess?.some(e => e.fieldCode === "LegalRepresentativeLastName")} />
                                )}
                            />

                        </Grid>

                        <Grid item xs={12} md={6}>


                            <Controller
                                name="LegalRepresentativeIdentification"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField {...field} label="Cédula de identidad, pasaporte o documento de identificación" error={Boolean(error)} helperText={error?.message} fullWidth
                                        disabled={fieldsSuccess?.some(e => e.fieldCode === "LegalRepresentativeIdentification")} />
                                )}
                            />

                        </Grid>

                        <Grid item xs={12} md={6}>

                            <Controller
                                name="LegalRepresentativePosition"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField {...field} label="Ingresar Cargo" error={Boolean(error)} helperText={error?.message} fullWidth inputProps={{ style: { textTransform: "uppercase" } }}
                                        disabled={fieldsSuccess?.some(e => e.fieldCode === "LegalRepresentativePosition")} />
                                )}
                            />


                        </Grid>





                        <Grid item xs={12} md={6} >
                            <Controller
                                name="LegalRepresentativeBirthday"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            {...field}
                                            label="Fecha de nacimiento"
                                            renderInput={(params) => <TextField fullWidth {...params} error={Boolean(error)} />}
                                            inputFormat="dd/MM/yyyy"
                                            disabled={fieldsSuccess?.some(e => e.fieldCode === "LegalRepresentativeBirthday")}
                                        />
                                    </LocalizationProvider>
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>

                            <Controller
                                name="LegalRepresentativeNationality"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField {...field} label="Nacionalidad" error={Boolean(error)} helperText={error?.message} fullWidth
                                        disabled={fieldsSuccess?.some(e => e.fieldCode === "LegalRepresentativeNationality")} />
                                )}
                            />


                        </Grid>


                        <Grid item xs={12} md={6} >
                            <Controller
                                render={({ field }) => (
                                    <div className={errors["LegalRepresentativeIsPEP"] ? "ErrorCustomStyle" : ''}>
                                        <FormControl component="fieldset" sx={{ marginBottom: '10px' }}
                                            disabled={fieldsSuccess?.some(e => e.fieldCode === "LegalRepresentativeIsPEP")} >
                                            <FormLabel component="legend">Declaro que soy una Persona Políticamente Expuesta.</FormLabel>
                                            <RadioGroup aria-label="LegalRepresentativeIsPEP" {...field} row
                                                onChange={(e) => {
                                                    field.onChange(e.target.value);
                                                }}>
                                                <FormControlLabel value="true" control={<Radio />} label="Sí" />
                                                <FormControlLabel value="false" control={<Radio />} label="No" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                )}
                                name="LegalRepresentativeIsPEP"
                                control={control}
                            />

                        </Grid>


                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel>Estado Civil</InputLabel>
                                <Controller
                                    name="LegalRepresentativeCivilStatus"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <Select
                                            label="Estado Civil"
                                            value={value}
                                            disabled={fieldsSuccess?.some(e => e.fieldCode === "LegalRepresentativeCivilStatus")}
                                            onChange={(e, options) => {
                                                onChange(parseInt(options?.props?.value));
                                                if (options.value !== 2 && options.value !== 5) {
                                                    cleanDataFormByValidation(['LegalRepresentativeSpouseName', 'LegalRepresentativeSpouseLastName', 'LegalRepresentativeSpouseIdentification']);
                                                }
                                            }}
                                        >
                                            <MenuItem value="1" >SOLTERO</MenuItem>
                                            <MenuItem value="2">CASADO</MenuItem>
                                            <MenuItem value="3" >DIVORCIADO</MenuItem>
                                            <MenuItem value="4">VIUDO</MenuItem>
                                            <MenuItem value="5">UNION LIBRE</MenuItem>
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        </Grid>


                        {(watch("LegalRepresentativeCivilStatus") === 2 || watch("LegalRepresentativeCivilStatus") === 5) &&

                            <>

                                <Grid item xs={12} md={6}>

                                    <Controller
                                        name="LegalRepresentativeSpouseName"
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <TextField {...field} label="Nombres del cónyuge: Ingresar los 2 nombres" error={Boolean(error)} helperText={error?.message} fullWidth inputProps={{ style: { textTransform: "uppercase" } }}
                                                disabled={fieldsSuccess?.some(e => e.fieldCode === "LegalRepresentativeSpouseName")} />
                                        )}
                                    />


                                </Grid>
                                <Grid item xs={12} md={6}>

                                    <Controller
                                        name="LegalRepresentativeSpouseLastName"
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <TextField {...field} label="Apellidos del cónyuge: Ingresar los 2 apellidos" error={Boolean(error)} helperText={error?.message} fullWidth inputProps={{ style: { textTransform: "uppercase" } }}
                                                disabled={fieldsSuccess?.some(e => e.fieldCode === "LegalRepresentativeSpouseLastName")} />
                                        )}
                                    />


                                </Grid>
                                <Grid item xs={12} md={6} >

                                    <Controller
                                        name="LegalRepresentativeSpouseIdentification"
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <TextField {...field} label="Cédula de identidad, pasaporte o documento de identificación del cónyuge" error={Boolean(error)} helperText={error?.message} fullWidth
                                                disabled={fieldsSuccess?.some(e => e.fieldCode === "LegalRepresentativeSpouseIdentification")} />
                                        )}
                                    />



                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <Controller
                                        name="LegalRepresentativeSpouseBirthday"
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    {...field}
                                                    label="Fecha de nacimiento del cónyuge"
                                                    renderInput={(params) => <TextField fullWidth {...params} error={Boolean(error)} />}
                                                    inputFormat="dd/MM/yyyy"
                                                    disabled={fieldsSuccess?.some(e => e.fieldCode === "LegalRepresentativeSpouseBirthday")}
                                                />
                                            </LocalizationProvider>
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>

                                    <Controller
                                        name="LegalRepresentativeSpouseNationality"
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <TextField {...field} label="Nacionalidad del cónyuge" error={Boolean(error)} helperText={error?.message} fullWidth
                                                disabled={fieldsSuccess?.some(e => e.fieldCode === "LegalRepresentativeSpouseNationality")} />
                                        )}
                                    />


                                </Grid>

                            </>

                        }


                    </Grid>

                }




            </CardContent>
        </Card>

    );
}
