import { Fragment, useState, useEffect } from "react";
import {  useNavigate,  useParams, useLocation} from "react-router-dom";
// material
import {  Typography ,Button } from "@mui/material";
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import { styled } from '@mui/material/styles';
import exito2 from '../../../assets/images/icons/exito2.png';
import { Steps } from '../steps';
import { PATH_PAYMENTEZ } from "../../../routes/paths";

const ContentStyle = styled('div')(({ theme }) => ({
    marginTop: "56px",
    paddingBottom:"50px",
    textAlign: "center",
    "& img": {
        width: '291px',
        margin: "auto"
    },
    "&  .title": {
        marginTop: '41px'
    },
    "&  .btnContinue": {
        marginTop: '35px'
    }


}));


//const css = `
//    header {
//         width : 100% !important
//    }
//.sliderPaymentez{
//display:none;
//}
//`



//export default function EndAllStep(props) {
export const EndAllStep = () => {

    const navigate = useNavigate();
    let params = useParams();
    let location = useLocation();
    const { dispatch} = useAuth();

  

    const isMountedRef = useIsMountedRef();
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(true);




    const handle = () => {

        navigate(PATH_PAYMENTEZ.app.gateway.monitoringPanel + params?.affiliation);
        dispatch({
            type: 'VIEWENDLASTSTEP',
            payload: {
                show: false
            }
        });


    }



    useEffect(() => {

        setLoading(true);

    }, []);




    return (loading &&

        <Fragment>

        <div style={{ marginTop: "50px" }}>
            <Steps />
        </div>

            <ContentStyle>

                {/*    <style>{css}</style>*/}

            <img src={exito2} />
                <Typography variant="h4" mt={2} className="title">
                ¡Completaste todas las etapas del llenado de documentos!
                </Typography>

                <Typography variant="body1" mt={2}>
                   ¡Felicitaciones! Hemos enviado una notificación a tu asesor para que los revise. <br />
                Te notificaremos cuando los documentos estén listos para firmarlos. Puede tomar hasta 48 horas laborables.
                </Typography>

            <Button variant="contained" className="btnContinue" size="large" onClick={handle}>Ir a mi panel</Button>

            </ContentStyle>
        </Fragment>

    );
}
