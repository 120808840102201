import React from 'react';
//import { useParams } from "react-router-dom";
// material
import { Container, Typography} from '@mui/material';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import LogData from '../../components/paymentez-advisor/principal/LogData';


// ----------------------------------------------------------------------


export default function Log() {
    const { themeStretch } = useSettings();

    //const params = useParams();
   


    return (
        <Page title="Log | Nuvei">

            <Container maxWidth={themeStretch ? false : 'xl'}>

                <Typography  variant="h3">
                    Log
                </Typography>

                {/*<Typography mt={1}>*/}
                {/*    Ingresa los datos del comercio. Una vez creado, se le enviará un email al comercio para que inicie su proceso de afiliación.*/}
                {/*</Typography>*/}


                <LogData />

            </Container>

        </Page>
    );
}
