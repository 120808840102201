import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Container, Alert, AlertTitle } from '@mui/material';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import { PATH_AUTH } from "../routes/paths";

// ----------------------------------------------------------------------
import Global from '../Global';

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default function AuthGuard({ children, rol }) {
    const { isAuthenticated, user, isInitialized } = useAuth();
    const { pathname } = useLocation();



    const [requestedLocation, setRequestedLocation] = useState(null);


    const isUserRolIn = () => {

        var listRol = rol.split(',');
        if (listRol?.length > 0) {
            var checkRol = listRol?.some(e => e === user.rol?.toUpperCase());
            return checkRol;
        }


        return false;
    }


    if (!isAuthenticated) {
     
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname);
        }
        /* return <Login />;*/
        return <Navigate to={PATH_AUTH.login} />;
     
    }


    
    if (rol && rol !== "") {

        const allRoles = Global.allRoles;

        if (!allRoles.includes(user.rol?.toUpperCase()) || !isUserRolIn()) {
            return (
                <Container sx={{ m: 3 }}> 
                    <Alert severity="error">
                        <AlertTitle>Permission Denied</AlertTitle>
                        You do not have permission to access this page
                    </Alert>
                </Container>
            );

        }


    }
 

    if (requestedLocation && pathname !== requestedLocation) {
    
        setRequestedLocation(null);
        return <Navigate to={requestedLocation} />;
    }






    return <>{children}</>;
}
