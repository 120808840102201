import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "../Loadable";

const routeNuveiGeneral = (props) => [
  {
    element: <Navigate to="/nuvei/inicio" replace />,
  },

  { path: "asesores", element: <AllAdvisors /> },
  { path: "perfil/:user", element: <Profile /> },
  { path: "nuevo-usuario", element: <CreateUser /> },
];

const Profile = Loadable(lazy(() => import("../../pages/advisor/Profile")));

//Administrator
const AllAdvisors = Loadable(
  lazy(() => import("../../pages/supervisor/AllAdvisors"))
);
const CreateUser = Loadable(
  lazy(() => import("../../pages/supervisor/CreateUser"))
);

export { Loadable };

export default routeNuveiGeneral;
